import React, { useState, useEffect } from 'react';
import { TextOnly } from '../../../components/Text';
import ReactTable from 'react-table';
import RemoveVehicle from './RemoveVehicle';
import { formatDateTime2Lines } from '../../../libs/utils';
import { useScreenSize } from '../../../context/ScreenSize';
import { VehiclesSubComponent } from '../../../components/subComponents';
import AddEditVehicleForm from './AddEditVehicleForm';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../../../components/LoaderButton';
import HelpTooltip from '../../../components/HelpTooltip';


const VehicleTable: React.FC<any> = (props) => {
  const {
      handleVehicleChange,
      vehicles,
      currentShop,
      allCustomers,
    } = props;
  const shopID = currentShop?.shopID;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');
  const [displayVehicles, setDisplayVehicles] = useState<any[]>([]);
  const [editingVehicle, setEditingVehicle] =  useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hiddenColumns, setHiddenColumns] = useState<any[]>([]);

  const { isMobile, windowWidth } = useScreenSize();
  const isSmallScreen = isMobile || (windowWidth < 660);
  const isMediumScreen = windowWidth >= 660 && windowWidth < 1200;


  const handleCloseModal = () => {
    document.querySelector('.c-modal-slider.vehicle-table-form')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
      setEditingVehicle(null);
    }, 350);
  };

  const getTechName = (techID: string) => {
    const tech = currentShop?.shopUsers.find((user: any) => user.userID === techID);
    if (tech) {
      return `${tech.firstName} ${tech.lastName}`;
    } else {
      return `<${TextOnly('removedUser')}>`;
    }
  }

  const getCustomerName = (custID: string) => {
    const customer = allCustomers?.find((cust: any) => cust.customerID === custID);
    if (customer) {
      return `${customer.firstName} ${customer.lastName}`;
    }
  }

  useEffect(() => {
    const displayVehiclesList = vehicles?.length ?
      vehicles?.map((vehicle: any) =>{
        vehicle.displayCreatedOn = formatDateTime2Lines(vehicle.createdOn);
        vehicle.displayCustomer = getCustomerName(vehicle.customerID);
        // build filter here with all visible information
        vehicle.filterValue = [
          vehicle.color,
          vehicle.year,
          vehicle.make,
          vehicle.model,
          vehicle.displayCreatedOn.date,
          vehicle.displayCreatedOn.time,
          vehicle.displayCustomer,
          vehicle.vin,
          vehicle.licensePlate,
          vehicle.odometer,
        ].join(' ').toLocaleLowerCase();
        return vehicle;
      }) : [];

    setDisplayVehicles(displayVehiclesList);
  }, [vehicles, allCustomers]);

  useEffect(() => {
    const hiddenColumnsList = [...VehicleCol?.filter((col) => col.hide)];
    setHiddenColumns(hiddenColumnsList);
  }, [isSmallScreen, isMediumScreen]);

  const VehicleCol: any[] = [
    {
      Header: TextOnly('vehicle'),
      accessor: 'make',
      Cell: (row: any) => `${row.original.year} ${row.original.make} ${row.original.model}`,
    },
    {
      Header: TextOnly('owner'),
      accessor: 'displayCustomer',
      Cell: (row: any) => {
        const fullName = row.original.displayCustomer;
        const firstInitial = fullName.substring(0, 1);
        const lastName = fullName?.slice(fullName.indexOf(' ') + 1);
        return isSmallScreen ? `${firstInitial}. ${lastName}` : fullName;
      }
    },
    {
      Header: TextOnly('color'),
      accessor: 'color',
      maxWidth: 100,
      hide: isSmallScreen,
    },
    {
      Header: TextOnly('licensePlate'),
      accessor: 'licensePlate',
      maxWidth: 120,
      hide: isSmallScreen,
    },
    {
      Header: TextOnly('createdOn'),
      accessor: 'createdOn',
      id: 'createdOn',
      maxWidth: 120,
      hide: isSmallScreen || isMediumScreen,
      sortMethod: (a: any, b: any, desc: boolean) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row: any) => {
        const dateObj = formatDateTime2Lines(row.original.createdOn);
        return (
          <div>
            {dateObj.date}
            <br />
            {dateObj.time}
          </div>
        );
      }
    },
    {
      Header: TextOnly('actions'),
      id: 'actions',
      maxWidth: 90,
      className: 'parent-center',
      Cell: (row: any) => {
        return (
          <div className={isSmallScreen ? 'l-flex-column': "l-flex-gap-1"}>
            {/* Edit Vehicle */}
            <HelpTooltip label={TextOnly('edit')}>
              <button
                className="c-btn-icon-compact"
                onClick={() => {
                  setEditingVehicle(row.original);
                  setShowModal(true);
                }}
              >
                <div className="c-btn__inner">
                  <span className="c-btn__icon fal fa-pen-to-square" />
                </div>
              </button>
            </HelpTooltip>
            {/* Remove Vehicle */}
            <RemoveVehicle
              customer={allCustomers?.find((cust: any) => {
                return cust.customerID === row.original.customerID;
              })}
              shopID={shopID}
              selectedVehicle={row.original}
              onSubmit={handleVehicleChange}
            />
          </div>
        )
      }
    }
  ];

  const containsExpandFields = (row: any) => {
    const expandFields = [
      'vin',
      'odometer',
    ];
    for (const field in row) {
      if (expandFields.includes(field) && !!row[field]) {
        return true;
      }
    }
    return false;
  }


  return (
    <div className={`l-container u-margin-none u-margin-bottom-large u-padding-xs`}>
      <div className={`c-field no-spacing u-width-100-percent u-margin-top-large display-flex ${windowWidth < 440 && 'l-flex-column l-gap-1rem'}`}>
        <div className='u-margin-right'>
          <LoaderButton
            text={TextOnly('addVehicle')}
            icon={<i className="c-btn__icon fal fa-plus" />}
            onClick={() => setShowModal(true)}
            className='textwrap-nowrap'
          />
        </div>
        <div className={'display-flex position-relative u-width-100-percent u-min-width-200 u-margin-bottom' + ((windowWidth < 440) ? '' : ' u-max-width-40-percent')}>
          <input
            id="filterActions"
            type="text"
            maxLength={50}
            className="c-input"
            placeholder={TextOnly('searchVehicle') + '...'}
            onChange={(event) => setFilterString(event.target.value)}
            value={filterString}
          />
          <i className="c-field__input-icon fal fa-search" />
        </div>
      </div>
      <ReactTable
        columns={[
          {
            expander: true,
            Header: '',
            accessor: 'expander',
            Expander: ({ isExpanded, ...row }) => {
              if (containsExpandFields(row.original)) {
                return (
                  <div>
                    <i
                      className={`fa fa-chevron-right ${
                        isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                      }`}
                      aria-hidden="true"
                    ></i>
                  </div>
                );
              } else {
                return (
                  <div className="u-cursor-none"></div>
                )
              }
            },
          },
          ...VehicleCol.filter(col => !col.hide),
        ]}
        data={displayVehicles?.filter((vehicle: any) => {
          return vehicle?.filterValue?.includes(filterString.toLocaleLowerCase());
        })}
        className="-highlight u-margin-top tablelayout"
        previousText={TextOnly('previousPage')}
        nextText={TextOnly('nextPage')}
        pageText={TextOnly('page')}
        ofText={TextOnly('of')}
        rowsText={TextOnly('rows')}
        noDataText={TextOnly('noVehicles')}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'createdOn',
            desc: true,
          },
        ]}
        getTheadThProps={() => {
          return {
            style: {
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              width: '100%',
            },
          };
        }}
        SubComponent={row => {
          if (containsExpandFields(row.original) || hiddenColumns?.length) {
            return (
              <VehiclesSubComponent
                row={row}
                shop={currentShop}
                className="accordion__content"
                hiddenColumns={hiddenColumns}
              />
            );
          } else {
            return null;
          }
        }}
      />
      <Dialog
        isOpen={showModal}
        className="c-modal-slider vehicle-table-form"
        id="addEditVehicle"
        onDismiss={handleCloseModal}
        aria-label={'addEditVehicle'}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCloseModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <div className="c-modal__body">
          <AddEditVehicleForm
            onSubmit={handleVehicleChange}
            currentShop={currentShop}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            handleCloseModal={handleCloseModal}
            vehicle={editingVehicle}
            shopCustomers={allCustomers}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default VehicleTable;
