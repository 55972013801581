import React, { useState, createContext } from 'react';
import { languageOptions, dictionaryList } from './languages';
import { getUIStringTableForLanguage } from './db-lib';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
  userLanguageChange: (newLang) => {},
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState(getDefaultLanguage());
  const [provider, setProvider] = useState(null);

  const handleChangeLanguage = async (newLang) => {
    document.querySelector('.l-container').classList.add('u-blur');
    document.querySelector('.spinner-blur').classList.remove('u-is-hidden');
    const newLanguage = languageOptions[newLang]
      ? newLang
      : getDefaultLanguage();
    getUIStringTableForLanguage(newLang, true).then((dictionary) => {
      window.localStorage.setItem('rcml-lang-dict', JSON.stringify(dictionary));
      window.localStorage.setItem('rcml-lang', newLang);
      const currentTime = (new Date()).getTime();
      window.localStorage.setItem('rcml-lang-dict-age', currentTime.toString());
      setUserLanguage(newLanguage);
      document.querySelector('.spinner-blur').classList.add('u-is-hidden');
      document.querySelector('.l-container').classList.remove('u-blur');
    });
  }

  const fetchAndSetLanguage = async () => {
    let langDict =JSON.parse(window.localStorage.getItem('rcml-lang-dict', userLanguage)) || dictionaryList['en'];
    const langDictAge = window.localStorage.getItem('rcml-lang-dict-age')
                          ? parseInt(window.localStorage.getItem('rcml-lang-dict-age'))
                          : null;
    const currentTime = (new Date()).getTime();
    const lang = window.localStorage.getItem('rcml-lang') || window.navigator.language.substring(0, 2);

    // if the language was fetched over a week ago, refetch
    if (!langDictAge || (currentTime - langDictAge > (3600000 * 24 * 7))) {
      langDict = await getUIStringTableForLanguage(lang, true);
      window.localStorage.setItem('rcml-lang-dict', JSON.stringify(langDict));
      window.localStorage.setItem('rcml-lang', lang);
      window.localStorage.setItem('rcml-lang-dict-age', currentTime.toString());
      setProvider({
        userLanguage: lang,
        dictionary: langDict,
        userLanguageChange: handleChangeLanguage,
      });
    } else {
      setProvider({
        userLanguage: lang,
        dictionary: langDict,
        userLanguageChange: handleChangeLanguage,
      });
    }
  }

  // Set up the language dictionary
  if (!provider) {
    fetchAndSetLanguage();
  }

  if (provider) {
    return  <LanguageContext.Provider value={provider}>
              {children}
            </LanguageContext.Provider>
  } else {
    return null;
  }
}

function getDefaultLanguage() {
  let defaultLanguage = window.localStorage.getItem('rcml-lang');
  if (!defaultLanguage) {
    defaultLanguage = window.navigator.language.substring(0, 2);
    window.localStorage.setItem('rcml-lang', defaultLanguage);
  }
  return defaultLanguage;
}

export function useLanguage() {
  return React.useContext(LanguageContext);
}
