import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from '@fullcalendar/interaction';
import { TextOnly } from '../../../components/Text';
import { Dialog } from '@reach/dialog';
import AddEditEventForm from './AddEditEventForm';
import InlineEventInfo from './InlineEventInfo';

const Calendar: React.FC<any> = (props) => {
  const {
    userLanguage,
    events,
    currentShop,
    getAllShopEvents,
    getAllShopOrders,
    shopUserList,
    shopOrders,
    shopCustomers,
    user,
  } = props;
  const calendarDiv = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<any>(null);

  const [calendarDimensions, setCalendarDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [showModalEditEvent, setShowModalEditEvent] = useState<boolean>(false);
  const [showModalAddApp, setShowModalAddApp] = useState<boolean>(false);
  const [eventData, setEventData] = useState<any>();
  const [isListView, setIsListView] = useState(false);
  const [shopEvents, setShopEvents] = useState<any[]>([]);
  const calendarLang = allLocales.some((obj) => obj.code === userLanguage)
    ? userLanguage
    : 'en';
  const [dateStrObj, setDateStrObj] = useState<any>();

  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      if (showModalEditEvent) setShowModalEditEvent(false);
      else if (showModalAddApp) setShowModalAddApp(false);
    }, 350);
  };

  const handleEventClick = (info: any) => {
    const selectedEvent = events.find((event: any) => event.eventID === info.event.extendedProps.eventID);
    setEventData(selectedEvent);
    setShowModalEditEvent(true);
  };

  const handleDateClick = (dateObj: any) => {
    if (dateObj.view.type === 'dayGridMonth') {
      dateObj.view.calendar.changeView('timeGridDay', dateObj.dateStr);
    } else {
      setDateStrObj({
        startDate: dateObj.dateStr,
        endDate: dateObj.dateStr,
        allDay: dateObj.allDay,
      });
      setShowModalAddApp(true);
    }
  };

  useEffect(() => {
    const getElementDimensions = () => {
      if (calendarDiv.current) {
        const { width, height } =
          calendarDiv.current.getBoundingClientRect();
        setCalendarDimensions({ width, height });
      }
    };

    // Call the function initially
    getElementDimensions();

    // Call the function whenever the window is resized
    window.addEventListener('resize', getElementDimensions);

    return () => {
      window.removeEventListener('resize', getElementDimensions);
    };
  }, []);

  const handleToggleGrid = () => {
    // Access the FullCalendar API object using the ref
    if (isListView) {
      const calendarApi = calendarRef.current.getApi();
      setIsListView(false);
      updateView(calendarApi);
      updateToolbar('grid', calendarApi);
    }
  };

  const handleToggleList = () => {
    // Access the FullCalendar API object using the ref
    if (!isListView) {
      const calendarApi = calendarRef.current.getApi();
      setIsListView(true);
      updateView(calendarApi);
      updateToolbar('list', calendarApi);
    }
  };

  const customButtons = {
    gridToggle: {
      // Important! The space at the beginning is necessary to differentiate these classNames from fc's.
      // This code should be retested and possibly refactored when full calendar is updated.
      icon: ' fa-solid fa-calendar-days',
      click: handleToggleGrid,
    },
    listToggle: {
      // Important! The space at the beginning is necessary to differentiate these classNames from fc's.
      // This code should be retested and possibly refactored when full calendar is updated.
      icon: ' fa fa-list-ul',
      click: handleToggleList,
    }
  }

  // load styling for grid/list view toggle
  useEffect(()=>{
    const gridIcon = document.querySelector('.fa-calendar-days');
    const listIcon = document.querySelector('.fa-list-ul');
    // removing classNames full calendar adds which do not work with our font awesome icons.
    // This code should be retested and possibly refactored when full calendar is updated.
    gridIcon?.classList.remove('fc-icon');
    gridIcon?.classList.remove('fc-icon-');
    listIcon?.classList.remove('fc-icon');
    listIcon?.classList.remove('fc-icon-');
  }, []);

  useEffect(() => {
    const listToggle = document.querySelector('.fc-listToggle-button');
    const gridToggle = document.querySelector('.fc-gridToggle-button');
    // change which toggle is active based off of the current view.
    // This code should be retested and possibly refactored when full calendar is updated.
    if (isListView) {
      gridToggle?.classList.remove('fc-button-active');
      listToggle?.classList.add('fc-button-active');
    } else {
      listToggle?.classList.remove('fc-button-active');
      gridToggle?.classList.add('fc-button-active');
    }
  }, [isListView]);

  useEffect(() => {
    const updatedEvents = events.map((event: any)=> {
      const startDate = event?.startDate?.split('T')[0];
      const endDate = event?.endDate?.split('T')[0];
      const isMultipleDaysEvent = !!endDate && (endDate !== startDate);
      if (isMultipleDaysEvent) {
        // Create a Date object
        const date = new Date(`${endDate}T00:00:00`);
        // Make end date inclusive
        date.setDate(date.getDate() + 1);
        // event.end = date.toISOString().split('T')[0];
        return {
          ...event,
          start: event.startDate,
          end: date.toISOString().split('T')[0],
        }
      }
      return ({
        ...event,
        start: event.startDate,
        end: event.endDate,
      });
    });

    setShopEvents(updatedEvents);

  }, [events]);

  const updateView = (calendarApi: any) => {
    // Get the current view type (dayGridMonth, timeGridWeek, timeGridDay)
    const currentView = calendarApi.view.type;

    // Map view types to their corresponding list views
    const viewMap:any = {
      dayGridMonth: 'listMonth',
      timeGridWeek: 'listWeek',
      timeGridDay: 'listDay',
      listMonth: 'dayGridMonth',
      listWeek: 'timeGridWeek',
      listDay: 'timeGridDay',
    };

    // Change the view to the corresponding list view based on the current view
    const newView = viewMap[currentView];
    if (newView) {
      calendarApi.changeView(newView);
    }
  }

  const updateToolbar = (viewType: string, calendarApi: any) => {
    const toolbarOptions = {
      left: 'prev next today',
      center: 'title',
      right: '',
    };

    if (viewType === 'list') {
      toolbarOptions.right = 'listMonth,listWeek,listDay gridToggle,listToggle';
    } else {
      toolbarOptions.right = 'dayGridMonth,timeGridWeek,timeGridDay gridToggle,listToggle';
    }

    calendarApi.setOption('headerToolbar', toolbarOptions);
  };

  return (
    <div className="c-box event-calendar" ref={calendarDiv}>
      <FullCalendar
        ref={calendarRef}
        plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
        dateClick={handleDateClick}
        slotEventOverlap={false}
        eventContent={({ event, view }: any) => {
          return (
            <InlineEventInfo
              user={user}
              event={event}
              currentShop={currentShop}
              shopCustomers={shopCustomers}
              handleEventClick={handleEventClick}
              viewType={view.type}
            />
          )
        }}
        initialView="timeGridWeek"
        headerToolbar={{
          left: 'prev next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay gridToggle,listToggle'
        }}
        views={{
          listDay: { buttonText: TextOnly('day') }, // Configuration for listDay view
          listWeek: { buttonText: TextOnly('week') }, // Configuration for listWeek view
          listMonth: { buttonText: TextOnly('month') } // Configuration for listMonth view
        }}
        customButtons={customButtons}
        events={shopEvents}
        locales={allLocales}
        locale={calendarLang}
        aspectRatio={
          (calendarDimensions.width + 100) /
          calendarDimensions.height
        }
        slotMinTime={'05:00:00'}
        slotMaxTime={'21:00:00'}
      />
      <Dialog
        isOpen={showModalEditEvent}
        onDismiss={()=>handleCancelModal()}
        className="c-modal-slider"
        id={`editEvent`}
        aria-label={'editEvent'}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={()=>handleCancelModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          {eventData?.eventType === "APPOINTMENT" ? TextOnly('changeAppointment') : TextOnly('changeEmployeeTimeOff')}
        </h1>
        <AddEditEventForm
          currentShop={currentShop}
          onSubmit={async () => {
            await Promise.all([ //wait for all functions to be completed
              getAllShopEvents(),
              getAllShopOrders(),
            ]); // Run functions concurrently
          }}
          isLoading={isLoadingModal}
          setIsLoading={setIsLoadingModal}
          handleCloseModal={() => handleCancelModal()}
          eventData={eventData}
          shopUserList={shopUserList}
          shopOrderList={shopOrders}
          isAppointment={eventData?.eventType === "APPOINTMENT"}
          shopCustomerList={shopCustomers}
          user={user}
        />
      </Dialog>
      <Dialog
        isOpen={showModalAddApp}
        onDismiss={()=>handleCancelModal()}
        className="c-modal-slider"
        id={`AddApp`}
        aria-label={'AddApp'}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={()=>handleCancelModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          {TextOnly('scheduleAppointment')}
        </h1>
        <AddEditEventForm
          currentShop={currentShop}
          onSubmit={async () => {
            await Promise.all([ //wait for all functions to be completed
              getAllShopEvents(),
              getAllShopOrders(),
            ]); // Run functions concurrently
            setShowModalAddApp(false);
          }}
          isLoading={isLoadingModal}
          setIsLoading={setIsLoadingModal}
          handleCloseModal={() => handleCancelModal()}
          eventData={dateStrObj}
          shopUserList={shopUserList}
          shopOrderList={shopOrders}
          shopCustomerList={shopCustomers}
          user={user}
          isAppointment={true}
        />
      </Dialog>
    </div>
  );
};

export default Calendar;
