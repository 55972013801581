import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import { convertOrderNumToDisplay } from '../../../libs/utils';
import { ORDER_STATUS_OPTIONS_OBJ } from '../CrmConstants';
import { TechAvatar } from '../../../components/TechAvatar';
import { Tooltip } from 'react-tooltip';
import KanbanCard from '../ManageOrders/KanbanCard';

const InlineEventInfo: React.FC<any> = ({
  event,
  handleEventClick,
  viewType,
  user,
  currentShop,
  shopCustomers,
}) => {

  const order = event?.extendedProps?.order;
  const technician = order?.technicianID ?
    currentShop.shopUsers.find((tech: any)=> tech.userID === order.technicianID)
    : event?.extendedProps?.technicianID ?
    currentShop.shopUsers.find((tech: any)=> tech.userID === event.extendedProps.technicianID)
    : null;

  const options = {
    hour12: true,
    hour: '2-digit' as any,
    minute: '2-digit' as any,
  };
  const timeString = new Date(event?.start).toLocaleTimeString(
    'en-US',
    options
  );
  const isAllDay = !!event?.allDay;
  const isListView = viewType.includes('list');

  const orderInfo = !order ? null : (
    <div className={isListView ? 'u-text-gray' : 'u-text-white'}>
      <div
        className={`display-flex align-items-center flex-wrap-wrap u-margin-bottom`}
      >
        <div className="white-space-nowrap u-margin-right-large u-padding-right">
          <i className="u-width-20px fa fa-car" aria-hidden="true" />
          &nbsp;
          {order?.vehicle?.year} {order?.vehicle?.make} {order?.vehicle?.model}
        </div>
        <div className="white-space-nowrap u-margin-right-large u-padding-right">
          <i className="u-width-20px fa fa-user" aria-hidden="true" />
          &nbsp;
          {order?.customer?.firstName} {order?.customer?.lastName}
        </div>
        {order?.technicianID ? (
          <div className="white-space-nowrap u-margin-right-large u-padding-right display-flex align-items-center">
            <i className="u-width-20px fa fa-wrench" aria-hidden="true" />
            &nbsp;
            <TechAvatar tech={technician} size={'small'} />
          </div>
        ) : (
          <div className="u-flex-1"></div>
        )}
      </div>
    </div>
  );

  let children;

  if (viewType === 'dayGridMonth') {
    children = (
      <div
        className={
          !isAllDay ? 'fc-text-allday-monthview' : 'u-width-100-percent'
        }
      >
        <div
          onClick={() => handleEventClick({ event })}
          className="event-info-wrapper u-cursor-pointer"
        >
          {!isAllDay && (
            <>
              <div className="fc-daygrid-event-dot" />
              <span className="fc-event-time">{timeString}</span>
            </>
          )}
          <span
            className={
              'fc-event-title overflow-text-ellipsis u-font-weight-bold'
            }
          >
            <span className={isAllDay ? ' u-margin-left-large' : ''}>
              {event.title}
            </span>
          </span>
        </div>
      </div>
    );
  } else if (viewType === 'timeGridDay' || viewType === 'timeGridWeek') {
    children = (
      <div
        onClick={() => handleEventClick({ event })}
        className="fc-gridweek-gridday-wrapper u-cursor-pointer"
      >
        <div>
          {order
            ? `${TextOnly('order') + ' #'}${convertOrderNumToDisplay(
                order.orderNumber
              )} - `
            : null}
          <span className="u-font-weight-bold">{event.title}</span>
        </div>
        {orderInfo && (
          ((viewType === 'timeGridWeek') && event.allDay && <></>)
          || orderInfo
        )}
      </div>
    );
  } else if (
    viewType === 'listMonth' ||
    viewType === 'listWeek' ||
    viewType === 'listDay'
  ) {
    children = (
      <div
        className="u-cursor-pointer"
        onClick={() => handleEventClick({ event })}
      >
        {!order ? (
          <span className="u-font-weight-bold">{event.title}</span>
        ) : (
          <div className="u-width-100-percent">
            <div className="display-flex flex-wrap-wrap">
              <div className="u-margin-right">
                <span className="textwrap-nowrap">
                  {TextOnly('order') + ' #'}
                  {convertOrderNumToDisplay(order.orderNumber)}
                </span>
                {` - ${ORDER_STATUS_OPTIONS_OBJ[order.status]}`}.
              </div>
              <div className="u-margin-right u-font-weight-bold">
                {event.title}
              </div>
            </div>
            {orderInfo}
          </div>
        )}
      </div>
    );
  } else {
    children = null;
  }

  return (
    <>
      <div
        className="u-width-100-percent u-height-100-percent"
        data-tooltip-id={event.extendedProps.eventID}
        data-tooltip-delay-show={700}
      >
        {children}
      </div>
      <div className="tooltip-wrapper">
        <Tooltip
          id={event.extendedProps.eventID}
          place="top"
          className={`normal-tooltip u-padding-small u-cursor-none`}
          positionStrategy={event.allDay ? 'fixed' : 'absolute'}
          children={
            viewType === 'timeGridDay' || isListView ? null : order ? (
              <KanbanCard
                key={order.orderID}
                data={order}
                currentShop={currentShop}
                shopCustomers={shopCustomers}
                onChange={() => null}
                user={user}
                isEmbedded={true}
              />
            ) : (
              <span>{event.extendedProps?.description || event.title}</span>
            )
          }
        />
      </div>
    </>
  );
};

export default InlineEventInfo;
