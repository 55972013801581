import React, { useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useUser } from "../context/User";
import { ChildProps } from "../types";
import { Loading } from "./Loading";
import { TextOnly } from "./Text";
import AlertModal from "./AlertModal";

export type ProtectedRouteProps = {
  component: React.Component | any;
  props: ChildProps;
  permissions?: {
    roles?: string[];
    shopTypes?: string[];
    shopUserState?: string;
    crmState?: string;
  };
  permissionPath: string;
} & RouteProps;

let userHasAccess = true;

export default function AuthenticatedRoute({
  component: C,
  props: cProps,
  permissions,
  permissionPath,
  ...routeProps
}: ProtectedRouteProps) {
  const location = useLocation();
  const { user, currentShop, error, fetchUser } = useUser();

  const hasRole =
    permissions?.roles?.length ?
      cProps?.currentShop?.shopUserRole ?
        permissions?.roles.includes(cProps.currentShop.shopUserRole)
      : false
    : true;
  const isShopType =
    permissions?.shopTypes?.length && cProps?.currentShop?.shopType
      ? permissions?.shopTypes.includes(cProps.currentShop.shopType)
      : true;
  const isShopUserState =
    permissions?.shopUserState
      ? permissions.shopUserState === cProps?.user?.shopUserState
      : true;
  const isCrmStateActive =
    permissions?.crmState
      ? permissions.crmState === cProps?.currentShop?.crmState
      : true;

  useEffect(() => {
    if (!error && !user && cProps.isAuthenticated && userHasAccess) {
      fetchUser();
    }
  }, [user, error, cProps]);

  function performLogout() {
    cProps!.logout();
  }

  if (!cProps.isAuthenticated) {
    return (
      <Redirect to={`/?redirect=${location.pathname}${location.search}`} />
    );
  } else if (error) {
    userHasAccess = false;

    // toast.error(error?.dataRetrieveError, {
    //   containerId: "standard",
    //   autoClose: false,
    // });

    return (
      <AlertModal
        title={TextOnly('loginError')}
        message={TextOnly('tokenExpired')}
        showModal={true}
        handleCancel={performLogout}
        BtnText={"OK"}
      />
    );
  } else if(!user || (user.userShops.length && !cProps.currentShop)) {
    return(
      <Loading />
    )
  } else if (!hasRole || !isShopUserState || !isCrmStateActive) {
    toast.info(`${TextOnly("dontHavePermission")}: ${location.pathname}`, {
      containerId: "standard",
      autoClose: false,
    });
    return <Redirect to={`/myActivity`} />;
  } else if (!isShopType) {
    toast.info(`${TextOnly("dontHavePermission")}: ${location.pathname}`, {
      containerId: "standard",
      autoClose: false,
    });
    return <Redirect to={`/myActivity`} />;
  } else if (!user) {
    return <Loading />;
  } else if (user.shops.length === 0) {
    // show view if /myActivity or /userProfile
    const isDashOrUserProfileOrServiceCenterSignup = location.pathname.match(
      /userProfile|myActivity|messageBoard|shopSignup/
    );
    if (isDashOrUserProfileOrServiceCenterSignup) {
      return (
        <Route
          {...routeProps}
          render={(props) => <C {...props} {...cProps} />}
        />
      );
    }
    // else redirect to myActivity
    return <Redirect to={`/myActivity`} />;
  } else if (!currentShop) {
    return <Loading />;
  } else {
    return (
      <Route {...routeProps} render={(props) => <C {...props} {...cProps} />} />
    );
  }
}
