import React, { FC } from 'react';
import calendarWeekGridPromo from "../../../assets/images/calendarWeekGridPromo.png";
import scheduleApptPromo from "../../../assets/images/scheduleApptPromo.png";
import scheduleTimeOffPromo from "../../../assets/images/scheduleTimeOffPromo.png";
import calendarMonthGridPromo from "../../../assets/images/calendarMonthGridPromo.png";
import calendarDayGridPromo from "../../../assets/images/calendarDayGridPromo.png";
import calendarWeekListPromo from "../../../assets/images/calendarWeekListPromo.png";
import calendarDayListPromo from "../../../assets/images/calendarDayListPromo.png";
import ordersKanbanPromo from "../../../assets/images/ordersKanbanPromo.png";
import ordersAddOrderPromo from "../../../assets/images/ordersAddOrderPromo.png";
import ordersTablePromo from "../../../assets/images/ordersTablePromo.png";
import ordersEditOrderPromo from "../../../assets/images/ordersEditOrderPromo.png";
import customersTablePromo from "../../../assets/images/customersTablePromo.png";
import addCustomerPromo from "../../../assets/images/addCustomerPromo.png";
import customerOrdersPromo from "../../../assets/images/customerOrdersPromo.png";
import editCustomerPromo from "../../../assets/images/editCustomerPromo.png";
import customerAddVehiclePromo from "../../../assets/images/customerAddVehiclePromo.png";
import addVehiclePromo from "../../../assets/images/addVehiclePromo.png";
import vehicleTablePromo from "../../../assets/images/vehicleTablePromo.png";
import editVehiclePromo from "../../../assets/images/editVehiclePromo.png";
import PhotoGallery from '../../../components/PhotoGallery';
import PromoCard from '../../../components/PromoCard';
import { CrmPromoType } from '../../../types';

interface CrmPromoContentProps {
  promoType: CrmPromoType,
}

const CrmPromoContent: FC<CrmPromoContentProps> = (props) => {
  const promoType = props.promoType;
  const promoImages =
    promoType === 'calendar' ? [
      scheduleApptPromo,
      scheduleTimeOffPromo,
      calendarWeekGridPromo,
      calendarMonthGridPromo,
      calendarDayGridPromo,
      calendarWeekListPromo,
      calendarDayListPromo
    ] : promoType === 'manageOrders' ? [
      ordersKanbanPromo,
      ordersAddOrderPromo,
      ordersTablePromo,
      ordersEditOrderPromo
    ] : promoType === 'manageCustomers' ? [
      customersTablePromo,
      addCustomerPromo,
      customerOrdersPromo,
      editCustomerPromo,
      customerAddVehiclePromo
    ] : promoType === 'manageVehicles' ? [
      addVehiclePromo,
      vehicleTablePromo,
      editVehiclePromo
    ] : [];

  return (
    <div className="l-view-layout__crm-promo u-margin-top-xlarge">
      <PromoCard promoType={promoType} />
      <PhotoGallery images={promoImages} scrollInterval={5000} />
    </div>
  );
};

export default CrmPromoContent;