import React, { Component } from 'react';
import CustomDatePicker from '../components/CustomDatePicker';
import { Text, TextOnly } from '../components/Text';
import moment from 'moment';
import _ from 'underscore';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ReactTable from 'react-table';

import {
  generateFilterRegex,
  formatDateTime,
  formatDateTime2Lines,
  sortUsers,
  pipe,
  currentShopUsersFromProps,
  containsExpandFields,
} from '../libs/utils';
import { getSavedColumns } from '../libs/utils-ts';
import { getShopAuditLog, getOEMRegionList } from '../libs/db-lib';
import 'react-table/react-table.css';
import AlertModal from '../components/AlertModal';
import { Loading } from '../components/Loading';
import { Header } from '../components/Header';
import { expandRows, SHOP_EVENTS, SHOP_TYPES } from '../CONSTANTS';
import { Dialog } from '@reach/dialog';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CreateMenu } from '../components/CreateMenu';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FilterPills } from '../components/FilterPills';
import { LogSubComponent } from '../components/subComponents';

const initialFilters = {
  roles: [],
  users: [],
  actionCodes: [],
};

export class ShopActions extends Component {
  constructor(props) {
    super(props);

    const endDate = moment();
    const startDate = moment().subtract(1, 'week');

    this.state = {
      alertMessage: '',
      showModal: false,
      shopFilterActions: '',
      actions: [],
      actionsFiltered: [],
      shopUsers: [],
      startDate: startDate,
      endDate: endDate,
      showFilterSlideIn: false,
      filters: initialFilters,
      tempFilters: initialFilters,
      isLoading: true,
      loadingActions: true,
      oemRegions: [],
    };
  }

  async componentDidMount() {
    if (this.props.currentShop.shopType === SHOP_TYPES.STANDARD) {
      this.props.history.push('/myActivity');
    }
    try {
      const currentShop = this.props.currentShop;
      const shopUsers = currentShopUsersFromProps(
        currentShop.shopUsers,
        [
          'firstName',
          'lastName',
          'userID',
          'userName',
          'shopUserRole',
        ],
      );
      const oemRegionsList = await getOEMRegionList();
      this.setState({
        shopID: currentShop.shopID,
        shopUsers: shopUsers,
        isLoading: false,
        oemRegions: oemRegionsList,
      });
      this.handleSearch('shop');

    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  async handleSearch(actionType) {
    const startDate = this.state['startDate'];
    const endDate = this.state['endDate'];

    this.getAuditLog(actionType, startDate, endDate);
  }

  async getAuditLog(actionType, startDate, endDate) {
    const endDateStr = endDate.utc().toISOString();
    const startDateStr = startDate.utc().toISOString();
    this.setState({
      loadingActions: actionType,
    });
    const results = await getShopAuditLog(
      this.props.currentShop.shopID,
      startDateStr,
      endDateStr
    );
    const shopActions = results?.logEntries?.map((entry) => {
      const user = this.state.shopUsers?.filter(
        (user) => user.userID === entry.userID
      )[0];
      let firstName = user?.firstName;
      let lastName = user?.lastName;
      let fullName = `${firstName} ${lastName}`;
      let shopRole = user?.shopUserRole;
      let userName = user?.userName;

      if (!user) {
        fullName = `<${TextOnly('removedUser')}>`;
      }

      return {
        ...entry,
        name: fullName,
        shopRole: shopRole,
        userName: userName
      };
    });

    const filteredShopActions = shopActions?.filter((action) =>
      SHOP_EVENTS.includes(action.actionCode)
    );

    this.setState({
      loadingActions: null,
      actions: filteredShopActions || [],
      actionsFiltered: filteredShopActions || [],
    });
  }

  updateActionFilteredList = (filter) => {
    const actionTypeField = 'actions';
    const filteredActionTypeField = 'actionsFiltered';
    const actionTypeFilterField = 'filterActions';
    let stateObj = {};

    if (filter.length > 0) {
      const regexStr = generateFilterRegex(filter);
      const actionTypeList = this.state[actionTypeField];
      const actionList = actionTypeList?.filter((a) => {
        const addedOnStr = formatDateTime(a.actionDate);
        return (
          regexStr.test(a.actionMessage) ||
          regexStr.test(a.actionCode) ||
          regexStr.test(addedOnStr) ||
          regexStr.test(a.name) ||
          regexStr.test(a.shopRole)
        );
      });
      stateObj[filteredActionTypeField] = actionList;
      stateObj[actionTypeFilterField] = filter;
    } else {
      stateObj[filteredActionTypeField] = this.state[actionTypeField];
      stateObj[actionTypeFilterField] = '';
    }
    this.setState(stateObj);
  };

  handleRangeChange = (value, actionType) => {
    let setStateObj = {};
    setStateObj['startDate'] = value.start;
    setStateObj['endDate'] = value.end;
    this.setState(setStateObj);
    this.getAuditLog(actionType, value.start, value.end);
  };

  handleChange = (field, event) => {
    this.setState({
      tempFilters: { ...this.state.tempFilters, [field]: event },
    });
  };

  updateActionFilter = _.debounce((f, actionType) => {
    this.updateActionFilteredList(f, actionType);
  }, 300);

  handleChangeFilter = (actionType, event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.updateActionFilter(event.target.value, actionType);
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleApplyFilters = () => {
    this.setState({
      showFilterSlideIn: false,
      filters: this.state.tempFilters,
    });
  };

  handleResetFilters = () => {
    this.setState({
      filters: initialFilters,
      tempFilters: initialFilters,
    });
  };

  handleOpenFilterSlideIn = () => {
    this.setState({ showFilterSlideIn: true, tempFilters: this.state.filters });
  };

  handleCloseFilterSlideIn = () => {
    const self = this;
    document.querySelector('.c-modal-slider').classList.add('closed');
    setTimeout(() => {
      self.setState({ showFilterSlideIn: false });
    }, 350);
  };

  handleRemoveFilter = ({ field, value }) => {
    const updatedFiltersField = this.state.filters[field].filter(
      (filterItem) => filterItem.key !== value
    );
    this.setState({
      filters: { ...this.state.filters, [field]: updatedFiltersField },
    });
  };

  render() {
    const savedColumnSizes = getSavedColumns('shopActionsColumns');
    const actionsColumnDefs = [];

    actionsColumnDefs.push({
      Header: <Text tid="date" />,
      id: 'actionDate',
      accessor: 'actionDate',
      headerClassName: 'hide-second',
      className: 'hide-second',
      sortMethod: (a, b, desc) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row) => {
        const dateObj = formatDateTime2Lines(row.original.actionDate);
        return (
          <div>
            {dateObj.date}
            <br />
            {dateObj.time}
          </div>
        );
      },
      minWidth: savedColumnSizes.actionDate || 200,
    });
    actionsColumnDefs.push({
      Header: <Text tid="role" />,
      accessor: 'shopRole',
      minWidth: savedColumnSizes.shopRole || 200,
    });
    actionsColumnDefs.push({
      Header: <Text tid="user" />,
      accessor: 'name',
      minWidth: savedColumnSizes.name || 200,
      Cell: (row) => {
        const { name, userName } = row.original;
        return (
          <span>
            <span>
              {name}
            </span>
            <br />
            <span>{userName}</span>
          </span>
        );
      },
    });
    actionsColumnDefs.push({
      Header: <Text tid="action" />,
      accessor: 'actionMessage',
      minWidth: savedColumnSizes.actionMessage || 200,
    });

    let actions = this.state.actionsFiltered;

    // filter table from state.filters
    const { roles, users, actionCodes } = this.state.filters;
    const hasFilters = !!roles.length || !!users.length || !!actionCodes.length;

    const filterUsers = (actions) => {
      return actions.filter(
        (action) =>
          !users?.length ||
          users.filter((user) => user.key === action.userID).length > 0
      );
    };
    const filterActionCodes = (actions) =>
      actions.filter(
        (action) =>
          !actionCodes?.length ||
          actionCodes.filter(
            (actionCode) => actionCode.key === action.actionCode
          ).length > 0
      );
    const filterRoles = (actions) =>
      actions.filter(
        (action) =>
          !roles?.length ||
          roles.filter((role) => role.key === action.shopRole).length > 0
      );

    const filteredShopActions = actions.length
      ? pipe(filterRoles, filterUsers, filterActionCodes)(actions)
      : [];

    // For user select
    const usersFromActions = [
      ...new Set(actions.map((action) => action.userID)),
    ];
    const shopUsers = this.state.shopUsers
      .filter((user) => usersFromActions.includes(user.userID))
      .sort(sortUsers);
    const userOptions = shopUsers.length
      ? shopUsers.map((user) => ({
          key: user.userID,
          value: user.userID,
          label: `${user.firstName} ${user.lastName}`,
          pillLabel: `${user.firstName} ${user.lastName}`,
        }))
      : [];

    // For action select
    const actionOptions = actions?.length
      ? [...new Set(actions?.map((action) => action.actionCode))].map(
          (actionCode) => ({
            key: actionCode,
            value: actionCode,
            label: actionCode,
            pillLabel: actionCode,
          })
        )
      : [];

    // For role select
    const roleOptions = actions?.length
      ? [
          ...new Set(
            actions?.map((action) => action.shopRole)?.filter((role) => !!role)
          ),
        ].map((role) => ({
          key: role,
          value: role,
          label: role,
          pillLabel: role,
        }))
      : [];

    // Creates and returns a MenuItem to display selection menus with section headers
    const roleProps = {};
    roleProps.renderMenu = (results, menuProps) => {
      return CreateMenu(
        results,
        menuProps,
        'label',
        ['', 'label'],
        false,
        '',
        false
      );
    };

    const userProps = {};
    userProps.renderMenu = (results, menuProps) => {
      return CreateMenu(
        results,
        menuProps,
        'label',
        ['', 'label'],
        true,
        '',
        true
      );
    };

    const actionProps = {};
    actionProps.renderMenu = (results, menuProps) => {
      return CreateMenu(
        results,
        menuProps,
        'label',
        ['', 'label'],
        false,
        '',
        false
      );
    };

    if (this.state.isLoading) return <Loading />;

    return (
      <>
        <Header
          context={TextOnly('viewReports')}
          title={TextOnly('shopActions')}
        />
        <div className="l-flex-wrap">
          <div className="l-flex-wrap">
            <div className="u-margin-right">
              <CustomDatePicker
                lang={this.props.lang}
                idPrefix="shop"
                value={{
                  start: this.state.startDate,
                  end: this.state.endDate,
                  name: 'Last Week',
                }}
                onChange={(value) => this.handleRangeChange(value, 'shop')}
              />
            </div>
            <div>
              <button
                className="c-btn-outline"
                onClick={this.handleOpenFilterSlideIn}
                disabled={this.state.loadingActions}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-filter" />
                  <Text tid="filters" />
                </div>
              </button>
            </div>
          </div>

          <div className="c-field">
            <label htmlFor="filter" className="c-field__label u-is-vishidden">
              <Text tid="filter" />
            </label>
            <input
              maxLength="50"
              className="c-input"
              id="shopFilterActions"
              placeholder={TextOnly('filter')}
              type="text"
              value={this.state.shopFilterActions}
              onChange={this.handleChangeFilter.bind(this, 'shop')}
            />
            <i className="c-field__input-icon fal fa-search" />
          </div>
        </div>

        {hasFilters ? (
          <div className="l-flex-wrap u-margin-bottom-large">
            <div className="l-flex-wrap">
              <button
                className="c-btn-link u-margin-right"
                onClick={this.handleResetFilters}
              >
                <div className="c-btn__inner">
                  <Text tid="resetFilters" />
                </div>
              </button>
              <FilterPills
                filters={this.state.filters}
                onFilterClick={this.handleRemoveFilter}
              />
            </div>
          </div>
        ) : null}

        {this.state.loadingActions ? (
          <>
            <i className="fal fa-spinner-third spinning u-margin-right-small" />
            <Text tid="loading" /> <Text tid="shopActions" />
            ...
          </>
        ) : (
          <ReactTable
            columns={[
              {
                expander: true,
                Header: '',
                accessor: 'expander',
                Expander: ({ isExpanded, ...row }) => {
                  if (expandRows.includes(row.original.actionCode)
                      && containsExpandFields(row.original)) {
                    return (
                      <div>
                        <i
                          className={`fa fa-chevron-right ${
                            isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                          }`}
                          aria-hidden="true"
                        ></i>
                      </div>
                    );
                  } else {
                    return (
                      <div className="u-cursor-none"></div>
                    );
                  }
                },
              },
              ...actionsColumnDefs
            ]}
            data={filteredShopActions}
            className="-highlight"
            showPaginationTop={true}
            previousText={TextOnly('previousPage')}
            nextText={TextOnly('nextPage')}
            pageText={TextOnly('page')}
            ofText={TextOnly('of')}
            rowsText={TextOnly('rows')}
            noDataText={TextOnly('noShopActions')}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: 'actionDate',
                desc: true,
              },
            ]}
            onResizedChange={(a) =>
              localStorage.setItem('shopActionsColumns', JSON.stringify(a))
            }
            SubComponent={row => {
              if (expandRows.includes(row.original.actionCode)
                  && containsExpandFields(row.original)) {
                return (
                  <LogSubComponent
                    oemRegions={this.state.oemRegions}
                    row={row}
                    shop={this.props.currentShop}
                    currentUser={this.props.user}
                  />
                );
              } else {
                return null;
              }
            }}
          />
        )}

        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size={this.state.alertSize}
          handleCancel={this.handleCancel.bind(this)}
        />
        <Dialog
          isOpen={this.state.showFilterSlideIn}
          onDismiss={this.handleCloseFilterSlideIn}
          className="c-modal-slider"
          aria-label={TextOnly('filters')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCloseFilterSlideIn}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="filters" />
          </h1>
          <div className="c-modal__body">
            <div className="c-field">
              <label
                htmlFor="pickUser"
                className="c-field__label u-is-vishidden"
              >
                <Text tid="pickUser" />
              </label>
              <Typeahead
                {...userProps}
                id="selectedUsers"
                clearButton
                flip={true}
                labelKey={(option) => option.label}
                maxResults={userOptions.length}
                onChange={this.handleChange.bind(this, 'users')}
                options={userOptions}
                paginate={false}
                placeholder={TextOnly('selectAUser')}
                selected={this.state.tempFilters.users}
              />
            </div>
            <div className="c-field">
              <label className="c-field__label u-is-vishidden">
                <Text tid="pickRole" />
              </label>
              <Typeahead
                {...roleProps}
                id="selectedRoles"
                clearButton
                flip={true}
                labelKey={(option) => option.label}
                maxResults={roleOptions.length}
                onChange={this.handleChange.bind(this, 'roles')}
                options={roleOptions}
                paginate={false}
                placeholder={TextOnly('selectARole')}
                selected={this.state.tempFilters.roles}
              />
            </div>
            <div className="c-field">
              <label className="c-field__label u-is-vishidden">
                <Text tid="pickAction" />
              </label>
              <Typeahead
                {...actionProps}
                id="selectedActions"
                clearButton
                flip={true}
                labelKey={(option) => option.label}
                maxResults={actionOptions.length}
                onChange={this.handleChange.bind(this, 'actionCodes')}
                options={actionOptions}
                paginate={false}
                placeholder={TextOnly('selectAAction')}
                selected={this.state.tempFilters.actionCodes}
              />
            </div>

            <button
              className="c-btn-outline u-margin-right"
              onClick={this.handleResetFilters}
            >
              <Text tid="resetFilters" />
            </button>
            <button className="c-btn" onClick={this.handleApplyFilters}>
              <Text tid="applyFilters" />
            </button>
          </div>
        </Dialog>
      </>
    );
  }
}
