import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter, Redirect, NavLink } from 'react-router-dom';
import { Loading } from './components/Loading';
import AlertModal from './components/AlertModal';
import Routes from './Routes';
import { Text } from './components/Text';
import { Sidebar } from './containers/Sidebar';
import { DeveloperConsole } from './components/DeveloperConsole';
import Banner from './containers/Banner';
import { MobileSidebar } from './containers/MobileSidebar';
import { standardizeShopType } from './libs/utils';
import { useLanguage } from './libs/Language';
import { useUser } from './context/User';
import { PAYMENT_STATES, SHOP_STATES } from './CONSTANTS';
import { TextOnly } from './components/Text';
import { toast } from 'react-toastify';
import logo from './assets/images/aa.svg';
import { getSystemConfig } from './libs/db-lib'

class App extends Component {
  constructor(props) {
    super(props);

    window.addEventListener(
      'orientationchange',
      this.handleChangeOrientation.bind(this)
    );

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      alertMessage: '',
      showModal: false,
      revision: 0,
      bannerInfo: [{},{}],
      bannerNum: 0,
      showUnauthModal: false,
      mainClass: '',
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if(prevProps.userContext.currentShop !== this.props.userContext.currentShop){
      this.setState({
        bannerInfo: [this.state.bannerInfo[0], this.props.userContext.currentShop ? this.props.userContext.currentShop.bannerInfo : {}],
      })
    }

    if(prevState !== this.state && prevState.bannerInfo !== this.state.bannerInfo){
      let num = 0;
      if (this.state.bannerInfo[0]?.message && this.state.bannerInfo[1]?.message){
        num = 2;
      } else if (this.state.bannerInfo[0]?.message || this.state.bannerInfo[1]?.message){
        num = 1;
      }
      this.setState({ bannerNum: num});
    }
  }

  async componentDidMount() {
    const config = await getSystemConfig();
    this.setState({
      config: config,
      bannerInfo: [config.bannerInfo, this.state.bannerInfo[1]],
    });
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      if (
        e !== 'No current user' &&
        e.message !== 'Refresh Token has expired'
      ) {
        document.dispatchEvent(
          new CustomEvent('unauthUser', { bubbles: true })
        );
      }
    }

    window.addEventListener('unauthUser', () => {
      if (this.state.isAuthenticated) {
        this.setState({
          showUnauthModal: true,
        });
      }
    });

    if (window.outerWidth < 1000) {
      this.setState({ mainClass: "mobile" });
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated, newUser) => {
    if (newUser) {
      this.setState({ creatingUser: true });
    } else {
      this.setState({ creatingUser: false });
    }
    this.setState({ isAuthenticated: authenticated });
    if (!authenticated) {
      this.props.userContext.updateUser(null);
      this.props.history.push('/login');
    }
  };

  handleCancel = () => {
    this.setState({ showModal: false, showUnauthModal: false });
  };

  // TODO: move to context - Chance Smith 9/30/21
  updateUserInfo = (userInfo) => {
    const isLanguageDifferent =
      userInfo.language !== this.props.languageContext.userLanguage;
    if (isLanguageDifferent) {
      this.props.languageContext.userLanguageChange(userInfo.language);
    }
    const shopType = standardizeShopType(this.props.userContext.currentShop.shopType);
    this.setState({
      isLoading: false,
    });
    this.props.userContext.updateUser({ ...userInfo, shopType: shopType });
  };

  addEventListenerOnce(target, type, listener, addOptions, removeOptions) {
    target.addEventListener(
      type,
      function fn(event) {
        target.removeEventListener(type, fn, removeOptions);
        listener.apply(this, arguments);
      },
      addOptions
    );
  }

  handleChangeOrientation = () => {
    this.addEventListenerOnce(window, 'resize', this.handleResize.bind(this));
  };

  handleResize = () => {
    this.setState({
      revision: this.state.revision < 100 ? this.state.revision + 1 : 0,
    });
  };

  handleLogout = async () => {
    await Auth.signOut();

    window.localStorage.setItem('load-lang', 'true');
    window.localStorage.removeItem('CURRENT_SHOP');
    this.userHasAuthenticated(false);
    window.location.reload();
  };

  render() {

    const numBanners = this.state.bannerNum ? this.state.bannerNum : 0;

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      updateUserInfo: this.updateUserInfo,
      fetchUser: this.props.userContext.fetchUser,
      pathname: this.props.location.pathname,
      user: this.props.userContext.user,
      currentShop: this.props.userContext.currentShop,
      revision: this.state.revision,
      logout: this.handleLogout,
      config: this.state.config,
      userVotes: {},
    };

    if (this.props.history.location.search !== '') {
      // Parse the url for values
      let searchStr = this.props.history.location.search;
      let values = searchStr.substring(searchStr.indexOf('?') + 1);
      values.split('&').forEach((elem) => {
        let nameValue = elem.split('=');
        childProps[nameValue[0]] = nameValue[1];
      });
    }

    if (this.state.creatingUser) {
      childProps.creatingUser = true;
    }

    const isDeveloperWithAuth =
      process.env.REACT_APP_IS_DEVELOPER && this.state.isAuthenticated;

    // REDIRECTS
    const isLocationWarningRoute = this.props.history.location.pathname.match(
      /manageShopTools|manageUsers|myActivity|upgradeToPlus|shopProfile|userProfile|reports|messageBoard/
    );

    const isRedirectRoute = this.props.history.location.pathname.match(
      /upgradeToPlus|reports|messageBoard/
    );

    const { isOwner } = this.props.userContext;
    const paymentState = this.props.userContext.currentShop?.shopSubscriptionState;
    const shopState = this.props.userContext.currentShop?.shopState;
    const isPaymentPastDue = paymentState === PAYMENT_STATES.PAST_DUE;
    const isPaymentTerminated = paymentState === PAYMENT_STATES.TERMINATED;
    const isShopSuspended = shopState === SHOP_STATES.SUSPENDED;
    const isActiveChargeback =
      (shopState === SHOP_STATES.CHARGEBACKRECEIVED) ||
      (shopState === SHOP_STATES.SUSPENDED && this.props.userContext.currentShop?.suspendReason === "DISPUTE_LOST");
    const isChargebackLost = this.props.userContext.currentShop?.suspendReason === "DISPUTE_LOST";
    const toastID = 'NoDuplicates';

    if (
      isOwner &&
      isLocationWarningRoute &&
      (isPaymentPastDue || isPaymentTerminated || isShopSuspended)
    ) {
      let toastText = TextOnly('shopSuspendedMessage');
      if (isPaymentPastDue && !isShopSuspended) {
        toastText = TextOnly('shopAccountPastDue');
      } else if (isChargebackLost) {
        toastText = TextOnly('chargebackLost', {
          state: shopState,
        });
      }
      if (isPaymentPastDue || isPaymentTerminated || isChargebackLost) {
        if (isRedirectRoute) {
          return redirectToProfile(toastText);
        } else {
          toast.error(toastText, {
            toastId: toastID,
            containerId: 'standard',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      } else if (isActiveChargeback) {
        let toastText = TextOnly('chargebackLost', {
          state: shopState,
        });
        toast.error(toastText, {
          toastId: toastID,
          containerId: 'wide',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error(toastText, {
          toastId: toastID,
          onClick: ()=>this.props.history.push('/shopProfile'),
          containerId: 'wide',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } else {
      const currentShop = window.localStorage.getItem('CURRENT_SHOP');
      if (isOwner && isActiveChargeback && currentShop && isLocationWarningRoute) {
        let toastText = TextOnly('chargebackDisputed', {
          link: 'https://info.autoauth.com/contact',
        });
        toast.error(toastText, {
          toastId: toastID,
          containerId: 'wide',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }

    // END REDIRECTS
    return (
      !this.state.isAuthenticating && (
        <>
          <Banner bannerInfo={this.state.bannerInfo} {...this.props}/>
          <div className={"l-container"+ (numBanners ? " with-"+numBanners+"-banner" : "")}>
            {isDeveloperWithAuth ? (
              <DeveloperConsole
                updateUserInfo={this.updateUserInfo}
                user={this.props.userContext.user}
              />
            ) : null}
            <div
              className={`l-view-layout l-view-layout--sidebar ${
                childProps.pathname === '/' || childProps.pathname === '/login'
                  ? 'l-view--login'
                  : ''
              }`}
            >
              <aside className="l-view-layout__sidebar">
                <Sidebar
                  user={this.props.userContext.user}
                  mbRole={this.props.userContext.user?.application?.MESSAGE_BOARD?.mbRole}
                  shopName={this.props.userContext.currentShop?.shopName}
                  shopRole={this.props.userContext.currentShop?.shopUserRole}
                  shopType={this.props.userContext.currentShop?.shopType}
                  crmState={this.props.userContext.currentShop?.crmState}
                  isAuthenticated={this.state.isAuthenticated}
                  logout={this.handleLogout}
                  gotBanner={this.state.bannerNum}
                  certifiedTech={this.props.userContext.user?.certifiedTech}
                />
              </aside>
              <div className={`l-view-layout__main l-view-layout--two-column ${this.state.mainClass}`}>
                <NavLink
                  className="c-primary-nav__link u-margin-top-small u-sidebar-hidden"
                  to="/"
                >
                  <div className="c-logo">
                    <img src={logo} className="c-logo__image" alt="logo" />
                  </div>
                </NavLink>

                <Routes childProps={childProps} />
              </div>
              <AlertModal
                message={<Text tid={this.state.alertMessage} />}
                showModal={this.state.showModal}
                size="small"
                handleCancel={this.handleCancel.bind(this)}
              />
              <AlertModal
                title={TextOnly('error')}
                message={TextOnly("tokenExpired")}
                showModal={this.state.showUnauthModal}
                size="small"
                BtnText={TextOnly("logout")}
                handleCancel={() => {
                  this.handleCancel();
                  this.handleLogout();
                }}
              />

            </div>

            <MobileSidebar
              user={this.props.userContext.user}
              mbRole={this.props.userContext.user?.application?.MESSAGE_BOARD?.mbRole}
              shopName={this.props.userContext.currentShop?.shopName}
              shopRole={this.props.userContext.currentShop?.shopUserRole}
              shopType={this.props.userContext.currentShop?.shopType}
              isAuthenticated={this.state.isAuthenticated}
              logout={this.handleLogout}
              certifiedTech={this.props.userContext.user?.certifiedTech}
              crmState={this.props.userContext.currentShop?.crmState}
            />
          </div>
          <Loading className="spinner-blur u-is-hidden" />
        </>
      )
    );
  }
}

const AppWithRouter = withRouter(App);

export default (props) => {
  const languageContext = useLanguage();
  const userContext = useUser();
  return (
    <AppWithRouter
      languageContext={languageContext}
      userContext={userContext}
      {...props}
    />
  );
};

function redirectToProfile(message) {
  return <Redirect to={`/shopProfile`} />;
}
