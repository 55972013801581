import React, { Component } from 'react';
import ToolPicker from '../components/ToolPicker';
import FileUpload from '../components/FileUpload';
import CustomDatePicker from '../components/CustomDatePicker';
import moment, { Moment } from 'moment';
import _ from 'underscore';
import heic2any from 'heic2any';
import {
  toolSerialNoValidate,
  fieldChangeCase,
  generateFilterRegex,
  formatDateTime,
  formatDateTime2Lines,
} from '../libs/utils';
import {
  addToolForShop,
  removeToolFromShop,
  importToolsForShop,
  removeToolsForShop,
  getShopToolAuditLog,
  getSystemConfig,
  editToolForShop,
  requestToolRecovery,
  getToolTypes,
  getRegionOemToolCompatibility,
  getOEMRegionList,
} from '../libs/db-lib';
import { Text, TextOnly, LangDictKey } from '../components/Text';
import LoaderButton from '../components/LoaderButton';
import AlertModal from '../components/AlertModal';
import { EmptyCard } from '../components/EmptyCard';
import { Dialog } from '@reach/dialog';
import { PAYMENT_STATES } from '../CONSTANTS';

import ReactTable, { CellInfo, Column } from 'react-table';
import 'react-table/react-table.css';
import { Loading } from '../components/Loading';
import { Header } from '../components/Header';
import { toast } from 'react-toastify';
import { SHOP_TYPES } from '../CONSTANTS';
import { UserContextInterface, useUser } from '../context/User';
import { ChildProps, Action, Tool, User } from '../types';
import GenericPopover from '../components/GenericPopover';
import HelpTooltip from '../components/HelpTooltip';
import config from '../config';
import { Link } from 'react-router-dom';
import CompatibleToolsModal from '../components/CompatibleTools/CompatibleToolsModal';
import ToolInformationPromoImage from '../assets/images/toolInformationPromo.png';
import ToolNotesPromoImage from '../assets/images/toolNotesPromo.png';
import ToolNamePromoImage from '../assets/images/toolNamePromo.png';
import { NavLink } from "react-router-dom";

interface MU_Tool extends Partial<Tool> {
}

interface Max_Values {
  standardMaxTools: number;
  plusMaxTools: number;
}

interface RawToolRecoveryPic {
  data: Blob;
}

interface ToolRecoveryPic {
  data: string;
  preview: string;
}

interface ManageToolsProps extends ChildProps {
  history: any;
  userContext: UserContextInterface;
}

interface ToolManufacturer {
  toolManufacturer?: string;
  toolManufacturerUUID?: string;
}

interface ToolModel {
  toolModel?: string;
  toolModelUUID?: string;
}
interface Object {
  [key: string]: any;
}

interface ManageToolsState {
  name: string;
  serialNumber: string;
  manufacturer: ToolManufacturer;
  multiManufacturer: ToolManufacturer;
  multiModel: ToolModel;
  model: ToolModel;
  currentNotes: string[];
  note: string;
  currentToolId?: string;
  showModal: boolean;
  showDeleteModal: boolean;
  showSingleToolModal: boolean;
  showMultiToolModal: boolean;
  showMultiToolDeleteModal: boolean;
  showInfoModal: boolean;
  showNotesModal: boolean;
  showRecoveryModal: boolean;
  showToolNamePromoModal: boolean;
  rawToolRecoveryPic?: RawToolRecoveryPic;
  toolRecoveryPic?: ToolRecoveryPic;
  isLoadingPic?: boolean;
  verifySerialNumber: boolean;
  toolPreviewList: MU_Tool[];
  filter: string;
  shopTools?: Tool[];
  shopToolsFiltered?: Tool[];
  toolActions: Action[];
  rowsText: string;
  shopUsers?: User[];
  isLoading: boolean;
  isLoadingTools: boolean;
  windowWidth: number;
  toolToDelete?: string;
  loadingActions?: string | null;
  toolActionsFiltered?: Action[];
  filterActions?: string;
  startDate?: Moment;
  endDate?: Moment;
  actionTool?: Tool;
  activeKey?: number;
  alertSize?: 'large' | 'small';
  alertMessage: string | JSX.Element;
  alertTitle: string;
  shopMaxValues: Max_Values;
  selectedColumns: string[];
  busDaysToRespond: number;
  toolTypes: any;
  regionCode: string;
  uploading: boolean;
  chartData: object[];
  oemRegions: Object[];
}

class ManageShopTools extends Component<ManageToolsProps, ManageToolsState> {
  constructor(props: ManageToolsProps) {
    super(props);

    this.state = {
      name: '',
      serialNumber: '',
      manufacturer: {},
      multiManufacturer: {},
      multiModel: {},
      model: {},
      currentNotes: [],
      note: '',
      currentToolId: '',
      alertMessage: '',
      alertTitle: '',
      showModal: false,
      showDeleteModal: false,
      showSingleToolModal: false,
      showMultiToolModal: false,
      showMultiToolDeleteModal: false,
      showInfoModal: false,
      showNotesModal: false,
      showRecoveryModal: false,
      showToolNamePromoModal: false,
      toolRecoveryPic: undefined,
      isLoadingPic: false,
      verifySerialNumber: false,
      toolPreviewList: [],
      filter: '',
      shopToolsFiltered: [],
      toolActions: [],
      rowsText: TextOnly('rows'),
      windowWidth: 0,
      isLoading: false,
      isLoadingTools: true,
      shopMaxValues: {
        standardMaxTools: 100,
        plusMaxTools: 200,
      },
      busDaysToRespond: 1,
      selectedColumns: JSON.parse(
        window.localStorage.getItem('ManageShopToolsSelectedColumns') as string
      ) || [
        'name',
        'manufacturer',
        'model',
        'serialNo',
        'addedOn',
        'addedBy',
        'actions',
      ],
      toolTypes: null,
      regionCode: this.props.currentShop?.regionCode || '',
      uploading: false,
      chartData: [],
      oemRegions: [],
    };
  }

  componentDidUpdate(prevProps: ChildProps) {
    if (this.props.lang !== prevProps.lang) {
      this.setState({ rowsText: TextOnly('rows') });
    }
  }

  async componentDidMount() {
    // let toolTypes = await getToolTypes();
    // this.setState({
    //   toolTypes: toolTypes?.data?.toolManufacturerList,
    // });
    let config = this.props.config;
    if (config) {
      if (config.shopMaxValues) {
        this.setState({
          shopMaxValues: {
            plusMaxTools: config.shopMaxValues.plusMaxTools ? config.shopMaxValues.plusMaxTools : 200,
            standardMaxTools: config.shopMaxValues.standardMaxTools ? config.shopMaxValues.standardMaxTools : 100,
          }
        });
      }
      if (config.busDaysToRespond) {
        this.setState({
          busDaysToRespond: config.busDaysToRespond
        });
      }
    }

    const self = this;
    // Set timeout to get the compatibility data in the background
    setTimeout(async () => {
      const response = await getRegionOemToolCompatibility();
      if (!response.error)
      self.setState({
        chartData: response.data
      });
      const oemRegions = await getOEMRegionList();
      self.setState({
        oemRegions: oemRegions
      });
      const toolManufacturerList = await getToolTypes();
      self.setState({
        toolTypes: toolManufacturerList?.data?.toolManufacturerList,
        isLoadingTools: false,
      });

    }, 350);

    try {
      this.setState({
        shopUsers: this.props.currentShop.shopUsers,
        shopTools: this.props.currentShop.userShopTools,
        shopToolsFiltered: this.props.currentShop.userShopTools,
        isLoadingTools: false,
      });
    } catch (e) {
      this.setState({
        // @ts-ignore - not checking type of error
        alertMessage: e.message,
        showModal: true,
        isLoadingTools: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    let serialNumberValid = toolSerialNoValidate(this.state.serialNumber);

    return (
      this.state.serialNumber.length > 0 &&
      (this.state.manufacturer?.toolManufacturer && this.state.manufacturer.toolManufacturer.length > 0) &&
      (this.state.model?.toolModel && this.state.model.toolModel.length > 0) &&
      serialNumberValid
    );
  }

  validateBulkForm() {
    let serialNums = this.state.toolPreviewList;
    let serialNumsValid = true;

    for (let i = 0; i < serialNums.length; i++) {
      if (!toolSerialNoValidate(serialNums[i].toolSerial)) {
        serialNumsValid = false;
        break;
      }
    }

    return (
      (this.state.multiManufacturer?.toolManufacturer && this.state.multiManufacturer.toolManufacturer.length > 0) &&
      (this.state.multiModel?.toolModel && this.state.multiModel.toolModel.length > 0) &&
      serialNums.length > 0 &&
      serialNumsValid
    );
  }

  updateFilter = _.debounce((f: string) => {
    this.updateFilteredList(f);
  }, 300);

  updateFilteredList = (filter: string) => {
    if(filter.trim().length > 0){
      const regexStr = generateFilterRegex(filter);
      const toolList = this.state?.shopTools?.filter((t) => {
        let addedByUser = this.state?.shopUsers?.filter(
          (user) => user.userID === t.addedBy
        );
        let addedByUserStr = addedByUser?.length
          ? addedByUser[0].firstName + ' ' + addedByUser[0].lastName
          : '<' + TextOnly('unknown') + '>';
        let addedOnStr: string = formatDateTime(t.addedOn);
        let allToolNotes = t.toolNotes ? t.toolNotes.join('') : ''
        return (
          (t.toolName && regexStr.test(t.toolName)) ||
          regexStr.test(allToolNotes) ||
          regexStr.test(t.toolManufacturer) ||
          regexStr.test(t.toolModel) ||
          regexStr.test(t.toolSerial) ||
          regexStr.test(addedOnStr) ||
          regexStr.test(addedByUserStr)
        );
      });
      this.setState({
        shopToolsFiltered: toolList,
      });
    } else {
      this.setState({
        shopToolsFiltered: this.state.shopTools,
      });
    }
  };

  updateActionFilter = _.debounce((f: string) => {
    this.updateActionFilteredList(f);
  }, 300);

  updateActionFilteredList = (filter: string) => {
    let actionList = this.state.toolActions;
    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      actionList = this.state.toolActions.filter((a) => {
        const addedOnStr = formatDateTime(a.actionDate);
        return (
          regexStr.test(a.actionMessage) ||
          regexStr.test(a.actionCode) ||
          regexStr.test(addedOnStr)
        );
      });
    }
    this.setState({
      toolActionsFiltered: actionList || [],
    });
  };

  userDisplayName = (userID: string) => {
    let thisUser = this.state?.shopUsers?.filter(
      (user) => user.userID === userID
    );
    if (thisUser?.length) {
      return thisUser[0].firstName + ' ' + thisUser[0].lastName;
    } else {
      return '<' + TextOnly('unknown') + '>';
    }
  };

  activateSerialNumberVerify() {
    this.setState({ verifySerialNumber: true });
  }

  async handleConfirmModal() {
    const self = this;

    if (!this.state.toolToDelete) {
      return;
    }
    // Handle delete of tool
    this.setState({ isLoading: true });
    const result = await removeToolFromShop(
      this.props.currentShop.shopID,
      this.state.toolToDelete
    );
    if(result?.error){
      this.setState({
          // @ts-ignore - not checking type of error
          alertTitle: TextOnly('errorRemovingTool',{
            serialNo: this.state.serialNumber
          }),
          alertMessage: result.error,
          showModal: true,
          isLoading: false,
        });
    } else {
      this.handleRemoveTool(this.state.toolToDelete);
      this.props.fetchUser();
      toast.success(TextOnly('toolRemovedConfirmation'), {
        containerId: 'standard',
      });
      this.setState({ isLoading: false });
    }

    document.querySelector('#confirmToolFromShop')?.classList.add('closed');
    setTimeout(() => {
      self.setState({ showDeleteModal: false, toolToDelete: '' });
    }, 350);
  }

  handleCancelModal = (e?: React.SyntheticEvent, bulk?: boolean, myself?: any) => {
    e?.preventDefault();

    let self = this;
    if (myself) {
      self = myself;
    }
    if(!self.state.isLoading){
      document.querySelector('.c-modal-slider')?.classList.add('closed');
      setTimeout(() => {
        self.setState({
          showInfoModal: false,
          showSingleToolModal: false,
          showMultiToolModal: false,
          showMultiToolDeleteModal: false,
          serialNumber: '',
          manufacturer: {},
          multiManufacturer: {},
          showDeleteModal: false,
          toolToDelete: '',
          showToolNamePromoModal: false,
        });
        if(bulk) self.setState({toolPreviewList: []});
      }, 350);
    }
  };

  handleCancelNotesModal = (e?: React.SyntheticEvent) => {
    e?.preventDefault();

    let shopType = this.props.currentShop.shopType;
    const self = this;

    if (['ENTERPRISE', 'PLUS'].indexOf(shopType) === -1) {
      self.setState({
        showNotesModal: false,
        currentToolId: '',
        note: '',
        currentNotes: [],
      });
      return;
    }

    const { shopTools, shopToolsFiltered, currentToolId, currentNotes } =
      this.state;

    let currentTool = this.state.shopTools?.find(
      (tool) => tool.toolID === currentToolId
    );

    if (!_.isEqual(currentTool?.toolNotes, currentNotes)) {
      editToolForShop(
        this.props.currentShop.shopID,
        currentToolId,
        undefined,
        currentNotes
      );
      let newShopTools = shopTools?.map((tool) => {
        if (tool.toolID === currentToolId) {
          let newTool = tool;
          newTool.toolNotes = Array.from(currentNotes);
          return newTool;
        }
        return tool;
      });
      let newShopToolsFiltered = shopToolsFiltered?.map((tool) => {
        if (tool.toolID === currentToolId) {
          let newTool = tool;
          newTool.toolNotes = Array.from(currentNotes);
          return newTool;
        }
        return tool;
      });

      this.setState({
        shopTools: newShopTools || shopTools,
        shopToolsFiltered: newShopToolsFiltered,
      });
    }

    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showNotesModal: false,
        currentToolId: '',
        note: '',
        currentNotes: [],
      });
    }, 350);
  };

  handleCancelRecoveryModal = () => {
    document.querySelector('.c-modal.recovery')?.classList.add('closed');
    let self = this;
    URL.revokeObjectURL(this.state.toolRecoveryPic?.preview as string);
    setTimeout(() => {
      self.setState({
        showRecoveryModal: false,
        toolRecoveryPic: undefined,
      });
    }, 350);
  };

  handleDeleteButton(toolId: string, serialNo: string) {
    this.setState({ showDeleteModal: true, toolToDelete: toolId, serialNumber:  serialNo});
  }

  handleRemoveButton(sn: string) {
    let toolList = this.state.toolPreviewList;
    let newToolList = toolList.filter(tool => !(tool.toolSerial===sn));
    this.setState({ toolPreviewList: newToolList });
  }

  handleToolChange(insertType: string, manufacturer: ToolManufacturer, model: ToolModel) {
    if (insertType === 'multiTool') {
      this.setState({ multiManufacturer: manufacturer, multiModel: model });
    } else {
      this.setState({ manufacturer: manufacturer, model: model });
    }
  }

  handleInfoButton(toolRec: Tool, isPlusOrEnterprise: Boolean) {
    const endDate = moment();
    const startDate = moment().subtract(1, 'month');

    this.getToolAuditLog(toolRec, isPlusOrEnterprise, startDate, endDate);
  }

  async getToolAuditLog(toolRec: Tool, isPlusOrEnterprise: Boolean, startDate: Moment, endDate: Moment) {
    const endDateStr = endDate.utc().endOf('day').toISOString();
    const startDateStr = startDate.utc().startOf('day').toISOString();
    this.setState({
      loadingActions: toolRec.toolID,
    });
    let results = isPlusOrEnterprise ?
      await getShopToolAuditLog(
        toolRec.toolID,
        this.props.currentShop.shopID,
        startDateStr,
        endDateStr
      )
      : [];
    this.setState({
      toolActions: results.logEntries,
      toolActionsFiltered: results.logEntries as Action[],
      filterActions: '',
      startDate: startDate,
      endDate: endDate,
      actionTool: toolRec,
      activeKey: 4,
      loadingActions: null,
      showInfoModal: true,
    });
  }

  handleEditName = async () => {
    let shopType = this.props.currentShop.shopType;
    if (['ENTERPRISE', 'PLUS'].indexOf(shopType) === -1) {
      return;
    }
    const { currentToolId, name } = this.state;

    let self = this;

    this.setState({
      isLoading: true,
    });
    let result = await editToolForShop(
      this.props.currentShop.shopID,
      currentToolId,
      name
    );
    if (result?.error) {
      const stateObj: any = {
        showModal: true,
        isLoading: false,
        currentToolId: '',
        name: '',
        alertMessage: <Text tid={"errorEditingTools"} />
      }
      if (result.error === 'Tool record does not exist') {

        // Update the tool table
        const { shopTools, shopToolsFiltered } = this.state;
        let newShopTools = shopTools?.filter((tool) => {
          return tool.toolID !== currentToolId;
        });
        let newShopToolsFiltered = shopToolsFiltered?.filter((tool) => {
          return tool.toolID !== currentToolId;
        });
        stateObj.shopTools = newShopTools || shopTools;
        stateObj.shopToolsFiltered = newShopToolsFiltered;
        stateObj.alertMessage = <Text tid={"toolNoLongerOwnedByShop"} />;
      }
      this.setState(stateObj);
    } else {
      const { shopTools, shopToolsFiltered } = self.state;
      let newShopTools = shopTools?.map((tool) => {
        if (tool.toolID === currentToolId) {
          let newTool = tool;
          newTool.toolName = name;
          return newTool;
        }
        return tool;
      });
      let newShopToolsFiltered = shopToolsFiltered?.map((tool) => {
        if (tool.toolID === currentToolId) {
          let newTool = tool;
          newTool.toolName = name;
          return newTool;
        }
        return tool;
      });
      this.setState({
        shopTools: newShopTools || shopTools,
        shopToolsFiltered: newShopToolsFiltered,
        isLoading: false,
        currentToolId: '',
        name: '',
      });
    }
  };

  handleRangeChange = (value: { start: Moment; end: Moment }) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
    // @ts-ignore - actionTool is not defined
    this.getToolAuditLog(this.state.actionTool, true, value.start, value.end);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'serialNumber') {
      fieldChangeCase(this, event.target, 'upper');
      this.activateSerialNumberVerify();
    } else {
      if (event.target.id === 'filter') {
        this.updateFilter(event.target.value);
        this.setState({
          filter: event.target.value,
        });
      } else if (event.target.id === 'filterActions') {
        this.updateActionFilter(event.target.value);
        this.setState({
          filterActions: event.target.value,
        });
      }
      // @ts-ignore - not checking possible fields
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleRemoveTool(toolId: string) {
    // Update the tool table
    const { shopTools, shopToolsFiltered } = this.state;
    let newShopTools = shopTools?.filter((tool) => {
      return tool.toolID !== toolId;
    });
    let newShopToolsFiltered = shopToolsFiltered?.filter((tool) => {
      return tool.toolID !== toolId;
    });
    this.setState({
      shopTools: newShopTools || shopTools,
      shopToolsFiltered: newShopToolsFiltered,
    });
  }

  handleCancel = () => {
    const self = this;
    setTimeout(() => {
      self.setState({ showModal: false });
    }, 350);
  };

  handleSync = async (myself?: any) => {

    let self = this;

    if (myself) {
      self = myself;
    }
    self.setState({ isLoading: true });

    await self.props.fetchUser(self.props.currentShop.shopID)
    self.setState({
      shopTools: self.props.currentShop.userShopTools,
      shopToolsFiltered: self.props.currentShop.userShopTools,
      isLoading: false,
    });
    toast.success(TextOnly('refSuccess'), { containerId: 'standard', toastId: 'NoDuplicates'  });
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const self = this;

    let data = this.state;

    const ownedTool = data?.shopTools?.find((t) => {
      return (
        t.toolModelUUID === data.model.toolModelUUID &&
        t.toolSerial === data.serialNumber.trim() &&
        t.toolManufacturerUUID === data.manufacturer.toolManufacturerUUID
      );
    });
    if (ownedTool) {
      this.setState({
        alertMessage: <Text tid="toolOwnedByYourShop" />,
        showModal: true,
      });
    } else {
      this.setState({ isLoading: true });

      let shopRec = this.props.currentShop;
      let toolID = await addToolForShop(
        shopRec.shopID,
        data.name,
        data.serialNumber,
        data.manufacturer.toolManufacturer,
        data.model.toolModel
      );
      if (toolID?.error) {
        let errorMsg = toolID.error;
        let modalType = 'alert';
        if (toolID.error === 'Tool is already assigned to another shop') {
          errorMsg = <Text tid="toolAssignedToAnotherShop"/>;
          modalType = 'recovery';
        }
        this.setState({
          alertMessage: errorMsg,
          alertTitle: TextOnly('error'),
          isLoading: false,
        });
        if (modalType === 'recovery') {
          this.setState({showRecoveryModal: true});
        } else {
          this.setState({showModal: true});
        }
      } else {
        let shopTools = this.state.shopTools;
        let newTool = {
          toolManufacturer: data.manufacturer.toolManufacturer,
          toolManufacturerUUID: data.manufacturer.toolManufacturerUUID,
          toolModel: data.model.toolModel,
          toolModelUUID: data.model.toolModelUUID,
          toolSerial: data.serialNumber,
          toolName: data.name,
          toolID: toolID,
          addedOn: new Date().toISOString(),
          addedBy: this.props.user.userID,
        };
        // @ts-ignore - tool is a partial type
        shopTools.push(newTool);
        this.props.fetchUser();
        toast.success(TextOnly('toolAdded'), { containerId: 'standard' });

        document.querySelector('#addTool')?.classList.add('closed');
        setTimeout(() => {
          self.setState({
            name: '',
            serialNumber: '',
            manufacturer: {},
            multiManufacturer: {},
            verifySerialNumber: false,
            shopTools: shopTools,
            // @ts-ignore - each tool is a partial type
            shopToolsFiltered: shopTools,
            showSingleToolModal: false,
            isLoading: false,
          });
        }, 350);
      }
    }
  };

  handleBulkSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const self = this;

    let data = this.state;

    self.setState({ isLoading: true });

    const shopRec = this.props.currentShop;
    let toolList = data.toolPreviewList;
    let manufacturer = data.multiManufacturer;
    let model = data.multiModel;

    if (toolList.length > 400) {
      self.setState({
        // @ts-ignore - not in dictionary
        alertMessage: TextOnly('toolLimitMessage'),
        showModal: true,
        isLoading: false,
      });
      return;
    }

    let addToolList = toolList.map((t) => {
      t.toolManufacturer = manufacturer.toolManufacturer;
      t.toolModel = model.toolModel;
      return t;
    });

    let tools = await importToolsForShop(shopRec.shopID, addToolList);
    if (tools?.error || !tools.toolList) {
      alert(tools?.error);
      self.setState({
        // @ts-ignore - not in dictionary
        alertMessage: tools?.error ? tools.error : TextOnly('errorAddingTools'),
        showModal: true,
        isLoading: false,
      });
    } else {
      let shopTools = this.state?.shopTools || [];
      let errors: unknown[] = [];
      let errorArr: any;
      let succCount = 0;
      tools.toolList.forEach((t: Tool) => {
        // @ts-ignore - not on Tool and only used once
        if (t.creationStatus === 'FAILED') {
          errors.push(t);
        } else {
          succCount++;
        }
      });

      if (errors.length > 0) {
        errorArr = (
          <div>
            {errors.map((e: any, index: number) => {
              let statusMsg: string | JSX.Element = e.statusMsg;
              if (e.statusMsg === 'Tool is already assigned to another shop') {
                let tool = data?.shopTools?.filter((t) => {
                  return (
                    t.toolModel === e.toolModel &&
                    t.toolManufacturer === e.toolManufacturer &&
                    t.toolSerial === e.toolSerial
                  );
                });
                if (tool?.length) {
                  statusMsg = TextOnly('toolOwnedByYourShop');
                } else {
                  statusMsg = TextOnly('toolAssignedToAnotherShop');
                }
              }
              let str =
                e.toolManufacturer +
                ' ' +
                e.toolModel +
                ' ' +
                e.toolSerial +
                ': ' +
                statusMsg +
                '.';
              return <h2 key={index}>{str}</h2>;
            })}
          </div>
        );
      }

      let statusMsg = (
        <>
          <h1 className="c-modal__heading" style={{display:'inline'}}>
            <Text tid="toolsAdded" />:
          </h1>
          <h2 style={{display:'inline'}}>{succCount}</h2>
          {errors.length > 0 &&
            <>
              <br/>
              <h1 className="c-modal__heading">
                <Text tid="toolsFailed" />:&emsp;<div style={{display:'inline'}}><h2 style={{display:'inline'}}>{errors.length}</h2></div>
                {errorArr}
              </h1>
            </>
          }
        </>
      );

      self.setState({
        shopTools: shopTools,
        alertMessage: statusMsg,
        alertSize: errors?.length ? 'large' : 'small',
        showModal: true,
        isLoading: false,
        toolPreviewList: [],
      });
      // self.handleCancelModal(undefined, true, self);
      // self.handleSync(self);
    }
  };

  handleBulkDeleteSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const self = this;

    let data = this.state;

    this.setState({ isLoading: true });

    const shopRec = this.props.currentShop;
    let toolList = data.toolPreviewList;
    let manufacturer = data.multiManufacturer;
    let model = data.multiModel;

    if (toolList.length > 400) {
      this.setState({
        // @ts-ignore - not in dictionary
        alertMessage: TextOnly('toolLimitMessage'),
        showModal: true,
        isLoading: false,
      });
      return;
    }

    let deleteToolList = toolList.map((t) => {
      t.toolManufacturer = manufacturer.toolManufacturer;
      t.toolModel = model.toolModel;
      return t;
    });

    let tools = await removeToolsForShop(shopRec.shopID, deleteToolList);
    if (tools?.error || !tools.toolList) {
      alert(tools?.error);
      this.setState({
        // @ts-ignore - not in dictionary
        alertMessage: tools?.error ? tools.error : TextOnly('errorRemovingTools'),
        showModal: true,
        isLoading: false,
      });
    } else {
      let shopTools = this.state?.shopTools || [];
      let errors: unknown[] = [];
      let errorArr: any;
      let succCount = 0;
      tools.toolList.forEach((t: Tool) => {
        // @ts-ignore - not on Tool and only used once
        if (t.removeStatus === 'FAILED') {
          errors.push(t);
        }else {
          succCount++;
        }
      });

      if (errors.length > 0) {
        errorArr = (
          <div>
            {errors.map((e: any, index: number) => {
              let str =
                e.toolManufacturer +
                ' ' +
                e.toolModel +
                ' ' +
                e.toolSerial +
                ': ' +
                e.statusMsg;
              return <h2 key={index}>{str}</h2>;
            })}
          </div>
        );
      }

      let statusMsg = (
        <>
          <h1 className="c-modal__heading" style={{display:'inline'}}>
            <Text tid="toolsRemoved" />:
          </h1>
          <h2 style={{display:'inline'}}>{succCount}</h2>
          {errors.length > 0 &&
            <>
              <br/>
              <h1 className="c-modal__heading">
                <Text tid="toolsFailed" />:&emsp;<div style={{display:'inline'}}><h2 style={{display:'inline'}}>{errors.length}</h2></div>
                {errorArr}
              </h1>
            </>
          }
        </>
      );

      self.setState({
        shopTools: shopTools,
        alertMessage: statusMsg,
        alertSize: errors?.length ? 'large' : 'small',
        showModal: true,
        isLoading: false,
        toolPreviewList: [],
      });
      self.handleCancelModal(undefined, true);
      self.handleSync(self);
    }
  };

  handleShowSingleToolModal = () => {
    this.setState({
      showSingleToolModal: true,
      name: '',
      currentToolId: '',
    });
  };

  handleShowMultiToolModal = () => {
    this.setState({ showMultiToolModal: true });
  };

  handleShowMultiToolDeleteModal = () => {
    this.setState({ showMultiToolDeleteModal: true });
  };

  constructToolPreview(serialArr: string[]) {
    let toolList = this.state.toolPreviewList;

    serialArr.forEach((sn, index) => {
      const serialNo = sn.replace(/,\s*$/, "");
      toolList.push({
        toolSerial: serialNo,
      });
    });

    this.setState({ toolPreviewList: toolList });
  }

  onDrop(files: Blob[]) {
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsText = reader.result as string;

      let serialNumbers = fileAsText
        .split(/\s+/)
        .filter((element) => element.length > 0);

      this.constructToolPreview(serialNumbers);
    };

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsText(files[0]);
  }

  onRecoveryDrop = async (files: Blob[]) => {
    let self = this;
    let file = files[0];

    console.log("file: ", file);
    if (file.size > 10e6) {
      // 10MB
      self.setState(
        {
          alertTitle: 'Error',
          alertMessage: TextOnly('fileTooBig'),
          showModal: true,
        },
        () => {
          console.log(this.state.showRecoveryModal);
        }
      );
      return;
    }

    // First store raw image for api submission
    const rawReader = new FileReader();
    rawReader.readAsDataURL(file);
    rawReader.onloadend = async () => {
      // If .heic then convert to png
      if (file.type === 'image/heic') {
        this.setState({ uploading: true });
        file = await heic2any({
          blob: file,
          toType: 'image/jpeg'
        }) as Blob;
        this.setState({ uploading: false });
      }
      self.setState(
        {
          rawToolRecoveryPic: {
            data: file
          }
        }
      );
      let recoveryPicUrl = URL.createObjectURL(file);
      const img = new Image();
      img.src = recoveryPicUrl;

      img.onerror = function () {
        URL.revokeObjectURL(recoveryPicUrl);
        // Handle the failure properly
        console.log('Cannot load image');
        self.setState({
          alertTitle: 'Error',
          alertMessage: TextOnly('fileTypeNotSupported'),
          showModal: true,
          isLoadingPic: false,
        });
      };

      this.setState({
        toolRecoveryPic: undefined,
        isLoadingPic: true,
      });

      // Image compression
      img.onload = function () {

        const reader = new FileReader();

        const [newWidth, newHeight] = calculateSize(img, 640, 480);
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob(
          (blob) => {
            // Handle the compressed image. es. upload or save in local state
            if (blob) {
              reader.readAsDataURL(blob);

              reader.onloadend = function () {
                let base64data = reader.result;
                self.setState(
                  {
                    toolRecoveryPic: {
                      data: base64data as string,
                      preview: recoveryPicUrl,
                    },
                  },
                  () => {
                    self.setState({
                      isLoadingPic: false,
                    });
                  }
                );
              };
            }
          },
          'image/jpeg',
          0.9
        );
      };
    }

    function calculateSize(
      img: HTMLImageElement,
      maxWidth: number,
      maxHeight: number
    ) {
      let width = img.width;
      let height = img.height;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      return [width, height];
    }
  }

  downloadCSV = () => {
    let lines:any = []
    let csvData = 'Manufacturer,Model,Serial'
    const toolsAux = this.props.currentShop.userShopTools;
    toolsAux.forEach(tool=>{
      lines.push(`${tool.toolManufacturer}, ${tool.toolModel}, ${tool.toolSerial}`)
    });
    csvData = csvData + '\n' + lines.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    link.setAttribute('download',`AutoAuthToolExport${year}-${month}-${day}.csv`);
    link.click();
    toast.success(TextOnly('toolsCSVExported'), {
      containerId: 'standard',
    });
  };

  render() {
    const isEnterprise =
      this.props?.currentShop.shopType === SHOP_TYPES.ENTERPRISE;
    const isPlus = this.props?.currentShop.shopType === SHOP_TYPES.PLUS;

    const actionTool = this.state?.actionTool;
    const currentToolId = this.state.currentToolId;
    const currentTool = this.state.shopTools?.find(
      (tool) => tool.toolID === currentToolId
    ) as Tool;
    const windowWidth = this.state.windowWidth;

    let columnDefs: Column[] = [];

    // Name
    let nameCol: Column = {
      Header: <Text tid="name" />,
      id: 'name',
      accessor: 'name',
      minWidth: 120,
      Cell: (row) => {
        return this.state.showNotesModal !== true
          && currentToolId === row.original.toolID
          && (isEnterprise || isPlus) ? (
          <div className="l-inline-flex">
            {this.state.isLoading ? (
              <button className="c-btn-icon">
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-spinner-third spinning" />
                </div>
              </button>
            ) : (
              <button className="c-btn-icon" onClick={this.handleEditName}>
                <div className="c-btn__inner">
                  <span className="c-btn__icon fal fa-check" />
                </div>
              </button>
            )}
            <input
              autoFocus={true}
              disabled={this.state.isLoading}
              type="text"
              maxLength={50}
              value={this.state.name}
              onChange={this.handleChange.bind(this)}
              id="name"
              className="c-input"
              placeholder={TextOnly('name')}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  this.handleEditName();
                }
              }}
            />
          </div>
        ) : (
          <div className="l-flex-align-center">
            <button
              className="c-btn-icon"
              onClick={() => {
                !isEnterprise && !isPlus
                ? this.setState({showToolNamePromoModal: true})
                : this.setState({
                  currentToolId: row.original.toolID,
                  name: row.original.name || '',
                })
              }}
            >
              <div className="c-btn__inner">
                <span
                  title={TextOnly('editToolName')}
                  className="c-btn__icon fal fa-pencil"
                />
              </div>
            </button>{' '}
            {isEnterprise || isPlus
            ? row.original.name || ''
            : ''}
          </div>
        );
      },
    };
    columnDefs.push(nameCol);

    let manCol: Column = {
      Header: <Text tid="manufacturer" />,
      id: 'manufacturer',
      accessor: 'manufacturer',
      Cell: (row) => {
        let manufacturer = row.original.manufacturer;
        let manRegionRec = this.state.toolTypes?.find((toolMan: any) => { return toolMan.toolManufacturer === manufacturer });
        let regionRec = manRegionRec?.regions?.find((region: any) => { return region.regionCode === this.state.regionCode });
        let displayName = row.original.manufacturer;
        if (regionRec?.displayName) {
          displayName = regionRec.displayName;
        }
        return <div>{displayName}</div>
      },
      minWidth: 120,
    };
    columnDefs.push(manCol);

    if (this.state?.windowWidth > 500) {
      columnDefs.push({
        Header: <Text tid="model" />,
        id: 'model',
        accessor: 'model',
        minWidth: 100,
      });
    }
    let serialNoCol: Column = {
      Header: <Text tid="serialNo" />,
      id: 'serialNo',
      accessor: 'serialNo',
      className: 'u-font-mono',
      minWidth: this.state?.windowWidth > 600 ? 150 : 120,
    };
    if (windowWidth < 1200) {
      serialNoCol.maxWidth = 100;
    }
    columnDefs.push(serialNoCol);

    const paymentState = this.props?.userContext?.user?.paymentState;
    const isPastDueOrTerminated =
      paymentState === PAYMENT_STATES.PAST_DUE ||
      paymentState === PAYMENT_STATES.TERMINATED;
    if (isEnterprise || isPlus) {
      if (windowWidth > 1200) {
        columnDefs.push({
          Header: <Text tid="addedOn" />,
          id: 'addedOn',
          accessor: 'addedOn',
          headerClassName: 'hide-second',
          className: 'hide-second',
          sortMethod: (a, b, desc) => {
            const externalAStr = new Date(a);
            const externalBStr = new Date(b);
            if (desc) {
              if (externalAStr > externalBStr) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (externalAStr < externalBStr) {
                return -1;
              } else {
                return 1;
              }
            }
          },
          Cell: (row) => {
            const dateObj = formatDateTime2Lines(row.original.addedOn);
            return (
              <div>
                {dateObj.date}
                <br />
                {dateObj.time}
              </div>
            );
          },
          minWidth: 100,
        });
        columnDefs.push({
          Header: <Text tid="addedBy" />,
          id: 'addedBy',
          accessor: (u) => {
            return this.userDisplayName(u.addedBy);
          },
          headerClassName: 'hide-second',
          className: 'hide-second',
          minWidth: 120,
        });
      }
    }

    columnDefs.push({
      Header: this.state.windowWidth > 500 ? <Text tid="actions" /> : '',
      id: 'actions',
      accessor: 'toolID',
      minWidth: this.state.windowWidth > 500 ? 90 : 50,
      Cell: (row: CellInfo) => {
        return (
          <div className="l-flex-gap-1">
            {this.state.loadingActions !== row.original.toolID ? (
              <button
                className="c-btn-icon-compact"
                onClick={this.handleInfoButton.bind(this, row.original, (isPlus || isEnterprise))}
              >
                  <div className="c-btn__inner">
                    <span
                      title={TextOnly('viewToolActions')}
                      className="c-btn__icon fal fa-info-circle"
                    />
                  </div>
                </button>
            ) : (
              <button className="c-btn-icon-compact">
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-spinner-third spinning" />
                </div>
              </button>
            )}
            <button
              className="c-btn-icon-compact"
              onClick={() => {
                this.setState({
                  currentToolId: row.original.toolID,
                  currentNotes: Array.from(row.original.notes) || [],
                  showNotesModal: true,
                });
              }}
            >
              <div className="c-btn__inner">
                <span
                  className="c-btn__icon fal fa-sticky-note"
                  title={TextOnly('notes')}
                />
              </div>
            </button>
            <button
              className="c-btn-icon-compact"
              onClick={this.handleDeleteButton.bind(this, row.original.toolID, row.original.serialNo)}
            >
              <div className="c-btn__inner">
                <span
                  className="c-btn__icon fal fa-trash-alt"
                  title={TextOnly('removeFromShop')}
                />
              </div>
            </button>
          </div>
        );
      },
      // suppressSorting: true,
      // suppressFilter: true,
    });

    const newToolsColumnDefs = [];

    newToolsColumnDefs.push({
      Header: <Text tid="serialNo" />,
      id: 'toolSerial',
      accessor: 'toolSerial',
      className: 'u-font-mono',
      minWidth: this.state.windowWidth > 600 ? 200 : 100,
    });
    newToolsColumnDefs.push({
      Header: this.state.windowWidth > 500 ? <Text tid="actions" /> : '',
      id: 'index',
      accessor: 'index',
      minWidth: this.state.windowWidth > 500 ? 90 : 50,
      Cell: (row: CellInfo) => {
        return (
          <button
            className="c-btn-icon"
            onClick={this.handleRemoveButton.bind(this, row.original.toolSerial)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
        );
      },
      // suppressSorting: true,
      // suppressFilter: true,
    });

    let toolActionsColumnDefs: Column[] = [];

    toolActionsColumnDefs.push({
      Header: <Text tid="actionCode" />,
      accessor: 'actionCode',
      minWidth: 100,
    });
    toolActionsColumnDefs.push({
      Header: <Text tid="description" />,
      accessor: 'actionMessage',
      minWidth: 200,
    });
    toolActionsColumnDefs.push({
      Header: <Text tid="vin" />,
      accessor: 'vin',
      minWidth: 100,
    });
    toolActionsColumnDefs.push({
      Header: <Text tid="performedBy" />,
      id: 'userID',
      accessor: (u: User) => {
        let thisUser = this.state?.shopUsers?.filter(
          (user) => user.userID === u.userID
        );
        if (thisUser?.length) {
          return thisUser[0].firstName + ' ' + thisUser[0].lastName;
        } else {
          return '<' + TextOnly('unknown') + '>';
        }
      },
      headerClassName: 'hide-second',
      className: 'hide-second',
      minWidth: 150,
    });
    toolActionsColumnDefs.push({
      Header: <Text tid="date" />,
      id: 'actionDate',
      accessor: 'actionDate',
      headerClassName: 'hide-second',
      className: 'hide-second',
      sortMethod: (a, b, desc) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row) => {
        return formatDateTime(row.original.actionDate);
      },
      minWidth: 200,
    });

    let toolActions = this.state.toolActionsFiltered
      ? this.state.toolActionsFiltered
      : this.state.toolActions;

    let shopTools = this.state.shopToolsFiltered
      ? this.state.shopToolsFiltered.map((tool) => {
          return {
            name: tool.toolName || '',
            toolID: tool.toolID,
            manufacturer: tool.toolManufacturer,
            model: tool.toolModel,
            serialNo: tool.toolSerial,
            addedOn: tool.addedOn,
            addedBy: tool.addedBy,
            notes: tool.toolNotes || [],
          };
        })
      : [];

    let maxShopTools = this.props.currentShop.shopToolCap
      ? this.props.currentShop.shopToolCap
      : isPlus
      ? this.state.shopMaxValues.plusMaxTools
      : this.state.shopMaxValues.standardMaxTools;

    const numToolsOfTools = TextOnly('toolsOfMax', {
      tools: this.props.currentShop.userShopTools?.length.toString(),
      maxTools: isEnterprise ?
          maxShopTools ?
            maxShopTools.toString()
            : ''
        : this.props.currentShop.shopMaxTools ?
            this.props.currentShop.shopMaxTools.toString()
            : this.props.currentShop.shopMaxUsers.toString()
    });

    const oemRegion = this.state.oemRegions.find((oemRegion) => {
      return oemRegion.regionCode === this.props.currentShop.regionCode;
    });
    const regionName = oemRegion ? oemRegion.regionName : null;

    if (this.state.isLoadingTools || this.state.shopTools === null)
      return <Loading />;

    columnDefs = columnDefs.filter(
      (columnDef) =>
        this.state.selectedColumns.indexOf(columnDef.id as string) !== -1
    );

    return (
      <>
        { this.props.currentShop.shopType === 'STANDARD' &&
         (this.props.currentShop.userShopTools.length >= this.props.currentShop.shopToolCap) &&
          <div className='temp-banner awsWarning'>
            <Text tid='maximumShopToolsReached'/>{' '}
            <Text
              tid="upgradeToPlusToIncreaseCapacity"
              sub={{
                upgradePlus: (
                  <a
                    href="/upgradeToPlus/comparePlans"
                    rel="noopener noreferrer"
                  >
                    {TextOnly('upgradeToStandardPlus')}
                  </a>
                ),
              }}
            />
          </div>
        }
        <div className="l-container">
          <Header
            context={TextOnly('manageTools')}
            title={TextOnly('currentTools')}
          />
          {this.state?.shopTools?.length === 0 ? (
            <EmptyCard
              title={TextOnly('getStarted')}
              description={TextOnly('addToolsToYourShop')}
              buttons={[
                {
                  name: TextOnly('addTool'),
                  onClick: this.handleShowSingleToolModal,
                  disabled: isPastDueOrTerminated,
                },
              ]}
            />
          ) : null}

          <div className="l-flex-wrap u-margin-top-large flex-flow-column">
            <div className="display-flex flex-space-between flex-wrap-wrap-reverse">
              <div className='u-margin-right-xlarge'>
                <div className='display-flex'>
                  <button
                    className="c-btn u-margin-top u-margin-right"
                    onClick={this.handleShowSingleToolModal}
                    disabled={isPastDueOrTerminated || !this.state.toolTypes}
                  >
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fal fa-plus" />
                      <Text tid="addTool" />
                    </div>
                  </button>
                  <div className='u-margin-top u-margin-right'>
                    <CompatibleToolsModal
                      openButtonText={TextOnly('vehicleToolCompatibility')}
                      closeButtonText={TextOnly('close')}
                      showOnlyRegion={regionName}
                      whiteButton={true}
                      noCloseButton={true}
                      chartData={this.state.chartData}
                    />
                  </div>
                </div>
                {isEnterprise ? (
                  <>
                    <button
                      className="c-btn u-margin-top u-margin-right"
                      onClick={this.handleShowMultiToolModal}
                      disabled={isPastDueOrTerminated || !this.state.toolTypes}
                    >
                      <Text tid="bulkAddTools" />
                    </button>
                    <button
                      className="c-btn u-margin-top u-margin-right"
                      onClick={this.handleShowMultiToolDeleteModal}
                      disabled={isPastDueOrTerminated || !this.state.toolTypes}
                    >
                      <Text tid="bulkRemoveTools" />
                    </button>
                  </>
                ) : null}
                {(isEnterprise || isPlus) &&
                  <>
                    <button
                      className="c-btn u-margin-top u-margin-right"
                      onClick={this.downloadCSV}
                      disabled={isPastDueOrTerminated}
                    >
                      <Text tid="shopToolsCSV" />
                    </button>
                  </>
                }
                <button
                  className="c-btn u-margin-top u-margin-right"
                  onClick={()=>{
                    this.handleSync()
                  }}
                  disabled={this.state.isLoading}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fa fa-refresh" />
                    <Text tid="refresh" />
                  </div>
                </button>
              </div>
              <div className="display-flex alignitems-baseline">
                <div className='u-margin-top display-flex align-items-center white-space-nowrap flex-wrap-wrap-reverse'>
                  <span className='u-margin-right-large'>
                    {numToolsOfTools}
                  </span>
                  {(this.props.currentShop.shopType !== 'ENTERPRISE') && this.props.currentShop.shopMaxTools < this.props.currentShop.shopToolCap ? (
                    <Link
                      to={{
                        pathname: '/shopProfile',
                        state: {
                          tab: 'shopInfo',
                        },
                      }}
                    >
                      <button className='c-btn white-space-nowrap'>
                        {TextOnly('upgradeCapacity')}
                      </button>
                    </Link>
                  ) : <>{/*<h3>{TextOnly('maximumShopUsersReached')}</h3>*/}</>}
                </div>
              </div>
            </div>
            <div className="l-flex align-items-end u-margin-top">
              <div>
                <GenericPopover
                  anchorElement={
                    <button className="c-btn-outline u-margin-right">
                      <div className="c-btn__inner">
                        <Text tid='columns' />
                        <i className="c-btn__icon-right fa fa-caret-down" />
                      </div>
                    </button>
                  }
                  body={
                    <div>
                      {[
                        {
                          id: 'name',
                          Header: TextOnly('name'),
                        },
                        {
                          id: 'manufacturer',
                          Header: TextOnly('manufacturer'),
                        },
                        {
                          id: 'model',
                          Header: TextOnly('model'),
                        },
                        {
                          id: 'serialNo',
                          Header: TextOnly('serialNo'),
                        },
                        {
                          id: 'addedOn',
                          Header: TextOnly('addedOn'),
                        },
                        {
                          id: 'addedBy',
                          Header: TextOnly('addedBy'),
                        },
                        {
                          id: 'actions',
                          Header: TextOnly('actions'),
                        },
                      ].map((col) => (
                        <div
                          key={col.id}
                          className="l-flex-align-center u-margin-bottom"
                        >
                          <div className="c-checkbox">
                            <input
                              type="checkbox"
                              id={col.id}
                              checked={
                                this.state.selectedColumns.indexOf(
                                  col.id as string
                                ) !== -1
                              }
                              onChange={(e) => {
                                let newSelection = this.state.selectedColumns;
                                if (!e.target.checked) {
                                  newSelection = newSelection.filter(
                                    (col) => col !== e.target.id
                                  );
                                } else {
                                  newSelection.push(e.target.id);
                                }
                                this.setState(
                                  {
                                    selectedColumns: newSelection,
                                  },
                                  () => {
                                    window.localStorage.setItem(
                                      'ManageShopToolsSelectedColumns',
                                      JSON.stringify(newSelection)
                                    );
                                  }
                                );
                              }}
                            ></input>
                          </div>
                          <span>{col.Header}</span>
                        </div>
                      ))}
                    </div>
                  }
                  color="#fff"
                  position="bottom"
                  align="end"
                  arrowSize={5}
                  closeOnClick={false}
                />
              </div>
              {isEnterprise || isPlus ? (
                <div className="c-field u-margin-none u-padding-none">
                  <input
                    type="text"
                    maxLength={50}
                    value={this.state.filter}
                    onChange={this.handleChange.bind(this)}
                    id="filter"
                    className="c-input"
                    placeholder={TextOnly('filter')}
                  />
                  <i className="c-field__input-icon fal fa-search" />
                </div>
              ) : null}
            </div>
          </div>
          <ReactTable
            columns={columnDefs}
            data={shopTools}
            className="-highlight u-margin-top-large"
            showPaginationTop={true}
            previousText={<Text tid="previousPage" />}
            nextText={<Text tid="nextPage" />}
            noDataText={<Text tid="noToolsThisShop" />}
            pageText={<Text tid="page" />}
            ofText={<Text tid="of" />}
            rowsText={this.state.rowsText}
            defaultPageSize={10}
            // @ts-ignore - possibly null
            SubComponent={
              windowWidth < 1200
                ? (row) => {
                    return (
                      <div>
                        <div>
                          <span>
                            <Text tid="manufacturer" />:
                          </span>{' '}
                          {row.original.manufacturer}
                        </div>
                        <div>
                          <span>
                            <Text tid="model" />:
                          </span>{' '}
                          {row.original.model}
                        </div>
                        <div>
                          <span>
                            <Text tid="serialNo" />:
                          </span>{' '}
                          <span className="u-font-mono">
                            {row.original.serialNo}
                          </span>
                        </div>
                        {isEnterprise || isPlus ? (
                          <div>
                            <span>
                              <Text tid="addedOn" />:
                            </span>{' '}
                            {formatDateTime(row.original.addedOn, {
                              windowWidth: windowWidth,
                            })}
                          </div>
                        ) : null}
                        {isEnterprise || isPlus ? (
                          <div>
                            <span>
                              <Text tid="addedBy" />:
                            </span>{' '}
                            {this.userDisplayName(row.original.addedBy)}
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                : null
            }
            defaultSorted={[
              {
                id: 'serialNo',
                desc: false,
              },
            ]}
          />
          <Dialog
            isOpen={this.state.showInfoModal}
            onDismiss={this.handleCancelModal}
            className="c-modal-slider"
            aria-label={TextOnly('toolInformation')}
          >
            <button
              className="c-btn-icon c-modal-slider__close"
              onClick={this.handleCancelModal}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <h1 className="c-modal__heading">
              <Text tid="toolInformation" />
            </h1>
            <div className="c-modal__body">
              {(isEnterprise || isPlus) &&
              (this.state.activeKey === 4 || this.state.toolActions.length) ? (
                <>
                  <div>
                    <label className="c-field__label">
                      <Text tid="actionsPerformedBy" />{' '}
                      {actionTool?.toolName || (
                        <span className="header-text">
                          {
                            // @ts-ignore - possibly null
                            actionTool?.manufacturer +
                              ' ' +
                              // @ts-ignore - possibly null
                              actionTool?.model +
                              ' SN:' +
                              // @ts-ignore - possibly null
                              actionTool?.serialNo
                          }
                        </span>
                      )}{' '}
                      <Text tid="from" />:
                    </label>
                  </div>
                  <CustomDatePicker
                    value={{
                      start: this.state.startDate,
                      end: this.state.endDate,
                      name: 'Last 30 Days',
                    }}
                    onChange={this.handleRangeChange.bind(this)}
                  />
                  <div className="auto-auth-table">
                    <div className="c-field">
                      <label htmlFor="filterActions" className="c-field__label">
                        <Text tid="filter" />
                      </label>
                      <input
                        id="filterActions"
                        type="text"
                        maxLength={50}
                        className="c-input"
                        placeholder={TextOnly('filter')}
                        value={this.state.filterActions}
                        onChange={this.handleChange}
                      />
                      <i className="c-field__input-icon fal fa-search" />
                    </div>
                    <ReactTable
                      columns={toolActionsColumnDefs}
                      data={toolActions}
                      className="-highlight"
                      previousText={<Text tid="previousPage" />}
                      nextText={<Text tid="nextPage" />}
                      pageText={<Text tid="page" />}
                      ofText={<Text tid="of" />}
                      noDataText={<Text tid="noToolActions" />}
                      rowsText={this.state.rowsText}
                      defaultPageSize={10}
                      defaultSorted={[
                        {
                          id: 'actionDate',
                          desc: true,
                        },
                      ]}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="c-box c-box--promo-plus">
                    <h2 className="c-box__title">
                      <Text tid="upgradeToStandardPlus" />
                    </h2>
                    <div className="l-flex-wrap u-margin-none">
                      <p className="u-margin-none u-padding-bottom">
                        <Text tid="upgradeToPlusToolActions" />
                      </p>
                      <NavLink
                        className="c-box__cta-btn c-btn"
                        to="/upgradeToPlus/comparePlans"
                      >
                        <Text tid="upgradeNow" />
                      </NavLink>
                    </div>
                  </div>
                  <div className="c-modal__body l-container l-view-layout__promo u-padding-top-large">
                    <img
                      className="promo-image no-zoom"
                      src={ToolInformationPromoImage}
                      alt={TextOnly("toolActions")}
                    />
                  </div>
                </>
              )}
            </div>
          </Dialog>
        </div>

        <Dialog
          isOpen={this.state.showSingleToolModal}
          onDismiss={this.handleCancelModal}
          className="c-modal-slider"
          id="addTool"
          aria-label={TextOnly('addTool')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="addTool" />
          </h1>

          <div className="c-modal__body l-container-sm">
            <form onSubmit={this.handleSubmit}>
              <h2>
                <Text tid="pickToolType" />
              </h2>

              {isEnterprise || isPlus ? (
                <div className="c-field">
                  <label htmlFor="name" className="c-field__label">
                    <Text tid="name" /> (<Text tid="optional" />)
                  </label>
                  <input
                    id="name"
                    className="c-input"
                    type="text"
                    maxLength={100}
                    placeholder={TextOnly('name')}
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </div>
              ) : (
                <HelpTooltip
                  label={
                    'This feature is only available with a PLUS subscription!'
                  }
                >
                  <div className="c-field">
                    <label htmlFor="name" className="c-field__label">
                      <Text tid="name" /> (<Text tid="optional" />)
                    </label>
                    <input
                      id="name"
                      className="c-input"
                      type="text"
                      maxLength={100}
                      placeholder={TextOnly('name')}
                      value={this.state.name}
                      onChange={this.handleChange}
                      disabled={!isEnterprise && !isPlus}
                    />
                  </div>
                </HelpTooltip>
              )}
              <ToolPicker
                region={this.props.currentShop?.regionCode}
                handleToolChange={this.handleToolChange.bind(
                  this,
                  'singleTool'
                )}
                toolTypes={this.state.toolTypes}
                idLabel="addTool"
              />
              <div className="c-field">
                <label htmlFor="serialNumber" className="c-field__label">
                  <Text tid="serialNumber" />
                </label>
                <input
                  id="serialNumber"
                  className="c-input u-font-mono"
                  type="text"
                  maxLength={100}
                  placeholder={TextOnly('serialNumber')}
                  value={this.state.serialNumber}
                  onChange={this.handleChange}
                />
                {this.state.verifySerialNumber &&
                !toolSerialNoValidate(this.state.serialNumber) ? (
                  <div className="c-field__error">
                    <Text tid="serialNumberHint" />
                  </div>
                ) : null}
              </div>

              <LoaderButton
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state?.isLoading}
                text={TextOnly('addTool')}
                loadingText={TextOnly('addingTool')}
              />
            </form>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showMultiToolModal}
          onDismiss={() => this.handleCancelModal(undefined, true)}
          className="c-modal-slider"
          id="addTools"
          aria-label={TextOnly('addTools')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={() => this.handleCancelModal(undefined, true)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="addTools" />
          </h1>

          <div className="c-modal__body">
            <form onSubmit={this.handleBulkSubmit.bind(this)}>
              <div className="l-container-sm">
                <h2>
                  <Text tid="pickToolType" />
                </h2>
                <ToolPicker
                  region={this.props.currentShop?.regionCode}
                  handleToolChange={this.handleToolChange.bind(
                    this,
                    'multiTool'
                  )}
                  toolTypes={this.state.toolTypes}
                  idLabel="bulkAddTools"
                />

                <div className="u-text-center">
                  <span>
                    <Text tid="uploadFileOfSerialNo" />
                    &nbsp;
                    <i
                      className="fal fa-question-circle"
                      title={TextOnly('uploadFormat')}
                    />
                  </span>

                  <FileUpload
                    handleOnDrop={this.onDrop.bind(this)}
                    description={TextOnly('uploadFileOfSerialNo')}
                  />
                </div>
              </div>

              {this.state.toolPreviewList.length ? (
                <ReactTable
                  columns={newToolsColumnDefs}
                  data={this.state.toolPreviewList}
                  className="-highlight"
                  previousText={<Text tid="previousPage" />}
                  nextText={<Text tid="nextPage" />}
                  showPaginationTop={true}
                  noDataText={<Text tid="noToolsThisShop" />}
                  pageText={<Text tid="page" />}
                  ofText={<Text tid="of" />}
                  rowsText={this.state.rowsText}
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: 'serialNo',
                      desc: false,
                    },
                  ]}
                />
              ) : null}
              <div className="u-text-center u-margin-top-large">
                <LoaderButton
                  type="submit"
                  disabled={!this.validateBulkForm()}
                  isLoading={this.state.isLoading}
                  text={TextOnly('addTools')}
                  loadingText={TextOnly('addingTools')}
                />
              </div>
            </form>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showMultiToolDeleteModal}
          onDismiss={() => this.handleCancelModal(undefined, true)}
          className="c-modal-slider"
          id="deleteTools"
          aria-label={TextOnly('removeTools')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={() => this.handleCancelModal(undefined, true)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="removeTools" />
          </h1>

          <div className="c-modal__body">
            <form onSubmit={this.handleBulkDeleteSubmit.bind(this)}>
              <div className="l-container-sm">
                <h2>
                  <Text tid="pickToolType" />
                </h2>
                <ToolPicker
                  region={this.props.currentShop?.regionCode}
                  handleToolChange={this.handleToolChange.bind(
                    this,
                    'multiTool'
                  )}
                  toolTypes={this.state.toolTypes}
                  idLabel="bulkRemoveTools"
                />

                <div className="u-text-center">
                  <span>
                    <Text tid="uploadFileOfSerialNo" />
                    &nbsp;
                    <i
                      className="fal fa-question-circle"
                      title={TextOnly('uploadFormat')}
                    />
                  </span>

                  <FileUpload
                    handleOnDrop={this.onDrop.bind(this)}
                    description={TextOnly('uploadFileOfSerialNo')}
                  />
                </div>
              </div>

              {this.state.toolPreviewList.length ? (
                <ReactTable
                  columns={newToolsColumnDefs}
                  data={this.state.toolPreviewList}
                  className="-highlight"
                  previousText={<Text tid="previousPage" />}
                  nextText={<Text tid="nextPage" />}
                  noDataText={<Text tid="noToolsThisShop" />}
                  pageText={<Text tid="page" />}
                  ofText={<Text tid="of" />}
                  rowsText={this.state.rowsText}
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: 'serialNo',
                      desc: false,
                    },
                  ]}
                />
              ) : null}
              <div className="u-text-center u-margin-top-large">
                <LoaderButton
                  type="submit"
                  disabled={!this.validateBulkForm()}
                  isLoading={this.state.isLoading}
                  text={TextOnly('removeTools')}
                  loadingText={TextOnly('removingTools')}
                />
              </div>
            </form>
          </div>
        </Dialog>

        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          handleCancel={this.handleCancel}
        />

        <Dialog
          isOpen={this.state.showDeleteModal}
          onDismiss={this.handleCancelModal}
          className="c-modal-slider"
          id="confirmToolFromShop"
          aria-label={TextOnly('confirmToolFromShop')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmToolFromShop" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouRemoveTool" />
            </p>
            <LoaderButton
              type="button"
              onClick={this.handleConfirmModal.bind(this)}
              isLoading={this.state.isLoading}
              text={TextOnly('confirm')}
              loadingText={TextOnly('removing')}
            />{' '}
            <button className="c-btn-outline" onClick={this.handleCancelModal}>
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showToolNamePromoModal}
          onDismiss={this.handleCancelModal}
          className="c-modal"
          id="toolNamePromo"
          aria-label={TextOnly('customizeYourTools')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="customizeYourTools" />
          </h1>
          <div className="c-box c-box--promo-plus">
            <h2 className="c-box__title">
              <Text tid="upgradeToStandardPlus" />
            </h2>
            <div className="l-flex-wrap u-margin-none">
              <p className="u-margin-none u-padding-bottom">
                <Text tid="upgradeToPlusToolNames" />
              </p>
              <NavLink
                className="c-box__cta-btn c-btn"
                to="/upgradeToPlus/comparePlans"
              >
                <Text tid="upgradeNow" />
              </NavLink>
            </div>
          </div>
          <div className="c-modal__body l-container l-view-layout__promo u-padding-top-large">
            <img
              className="promo-image no-zoom"
              src={ToolNamePromoImage}
              alt={TextOnly("customizeYourTools")}
            />
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showNotesModal}
          onDismiss={this.handleCancelNotesModal}
          className="c-modal-slider"
          id="toolNotesModal"
          aria-label={TextOnly('notes')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelNotesModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="notes" /> -{' '}
            {
              // @ts-ignore - possibly null
              currentTool?.toolName ||
                // @ts-ignore - possibly null
                `${currentTool?.toolManufacturer} ${currentTool?.toolModel} SN: ${currentTool?.toolSerial}`
            }
          </h1>

          <div className="c-modal__body">
            {(isEnterprise || isPlus) ? (
              <>
                <ul>
                {this.state.currentNotes.map((note, index) => {
                  return (
                    <li key={index}>
                      <div className="l-flex-between">
                        {note}
                        <button
                          className="c-btn-icon"
                          onClick={() => {
                            let newNotes = Array.from(this.state.currentNotes);
                            newNotes.splice(index, 1);
                            this.setState({
                              currentNotes: newNotes,
                            });
                          }}
                          >
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fa fa-times" />
                          </div>
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div className="l-flex-between u-margin-bottom-large">
                <input
                  id="note"
                  className="c-input"
                  type="text"
                  maxLength={100}
                  placeholder={TextOnly('addNote')}
                  value={this.state.note}
                  onChange={this.handleChange}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.key === 'Enter' && this.state.note !== '') {
                      let newNotes = Array.from(this.state.currentNotes);
                      newNotes.push(this.state.note);
                      this.setState({
                        currentNotes: newNotes,
                        note: '',
                      });
                    }
                  }}
                  />
                <button
                  className="c-btn u-margin-left"
                  disabled={this.state.note === ''}
                  onClick={() => {
                    if (this.state.note !== '') {
                      let newNotes = Array.from(this.state.currentNotes);
                      newNotes.push(this.state.note);
                      this.setState({
                        currentNotes: newNotes,
                        note: '',
                      });
                    }
                  }}
                  >
                  <div className="c-btn__inner">
                    <Text tid="add" />
                    <i className="c-btn__icon-right fa fa-plus" />
                  </div>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="c-box c-box--promo-plus">
                <h2 className="c-box__title">
                  <Text tid="upgradeToStandardPlus" />
                </h2>
                <div className="l-flex-wrap u-margin-none">
                  <p className="u-margin-none u-padding-bottom">
                    <Text tid="upgradeToPlusToolNotes" />
                  </p>
                  <NavLink
                    className="c-box__cta-btn c-btn"
                    to="/upgradeToPlus/comparePlans"
                  >
                    <Text tid="upgradeNow" />
                  </NavLink>
                </div>
              </div>
              <div className="c-modal__body l-container l-view-layout__promo u-padding-top-large">
                <img
                  className="promo-image no-zoom"
                  src={ToolNotesPromoImage}
                  alt={TextOnly("toolActions")}
                />
              </div>
            </>
          )}
          </div>
        </Dialog>

          {/* Recovery Modal */}
        <Dialog
          isOpen={this.state.showRecoveryModal}
          onDismiss={this.handleCancelRecoveryModal}
          className="c-modal recovery"
          aria-label={'tool recovery modal'}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelRecoveryModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="toolRecovery" />
          </h1>

          <div id="recovery" className="c-modal__body">
            <div id="recoveryStep1" className="c-modal__content">
              <p>
                <strong>{TextOnly('toolAssignedToAnotherShop')}</strong>
              </p>

              <p>
                <em>
                  <Text tid="ifSureOwnTool" />
                </em>
              </p>
              <div className="l-flex-between">
                <div />
                <div>
                  <button
                    className="c-btn-outline u-margin-top-large u-margin-right"
                    onClick={() => {
                      const step1 = document.getElementById(
                        'recoveryStep1'
                      ) as HTMLElement;
                      const step2 = document.getElementById(
                        'recoveryStep2'
                      ) as HTMLElement;
                      if (document !== null) {
                        step1?.classList.add('fade');
                        step2.style.display = 'block';
                        setTimeout(() => {
                          step1.style.display = 'none';
                          step2?.classList.remove('fade');
                        }, 500);
                      }
                    }}
                  >
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fa fa-undo" />
                      <Text tid="recoverTool" />
                    </div>
                  </button>
                  <button
                    className="c-btn-outline u-margin-top-large"
                    onClick={this.handleCancelRecoveryModal}
                  >
                    {TextOnly('dismiss')}
                  </button>
                </div>
              </div>
            </div>

            <div
              id="recoveryStep2"
              style={{ display: 'none' }}
              className="c-modal__content fade"
            >
              <section>
                <div className="c-field">
                  <label
                    htmlFor="recoveryManufacturerReadonly"
                    className="c-field__label"
                  >
                    <Text tid="manufacturer" />
                  </label>
                  <input
                    id="recoveryManufacturerReadonly"
                    className="c-input"
                    type="text"
                    maxLength={100}
                    placeholder={TextOnly('manufacturer')}
                    value={this.state.manufacturer.toolManufacturer}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <div className="c-field">
                  <label
                    htmlFor="recoverymodelReadonly"
                    className="c-field__label"
                  >
                    <Text tid="model" />
                  </label>
                  <input
                    id="recoverymodelReadonly"
                    className="c-input"
                    type="text"
                    maxLength={100}
                    placeholder={TextOnly('model')}
                    value={this.state.model.toolModel}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <div className="c-field">
                  <label
                    htmlFor="recoverySerialReadonly"
                    className="c-field__label"
                  >
                    <Text tid="serialNumber" />
                  </label>
                  <input
                    id="recoverySerialReadonly"
                    className="c-input"
                    type="text"
                    maxLength={100}
                    placeholder={TextOnly('serialNumber')}
                    value={this.state.serialNumber}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </section>
              <section className="l-flex-column l-flex-align-center u-margin-top-large u-margin-bottom-large">
                <p
                  className="instruction-box u-margin-bottom-large"
                  dangerouslySetInnerHTML={{
                    __html: TextOnly('uploadRecoveryInstructions'),
                  }}
                />
                <strong>
                  <Text tid="uploadPictureOfSerial" />
                </strong>
                <FileUpload
                  handleOnDrop={this.onRecoveryDrop.bind(this)}
                  description={this.state.uploading ? TextOnly('uploadingFile') : TextOnly('dragAndDropImageHere')}
                />
                {!this.state.isLoadingPic ? (
                  this.state.toolRecoveryPic && (
                    <img
                      src={this.state.toolRecoveryPic.preview}
                      alt="Tool Serial"
                      className="u-margin-top u-margin-bottom"
                      style={{
                        width: '60%',
                        height: 'auto',
                        borderRadius: '10px',
                      }}
                    />
                  )
                ) : (
                  <button className="c-btn-icon">
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fal fa-spinner-third spinning" />
                    </div>
                  </button>
                )}
              </section>

              <div className="l-flex-between">
                <div />
                <div>
                  <button
                    className="c-btn-outline u-margin-top-large u-margin-right"
                    onClick={this.handleCancelRecoveryModal}
                  >
                    {TextOnly('cancel')}
                  </button>

                  <LoaderButton
                    className="c-btn u-margin-top-large"
                    type="button"
                    disabled={!this.state.toolRecoveryPic}
                    isLoading={this.state.isLoading}
                    text={TextOnly('submitRequest')}
                    loadingText={TextOnly('submitting')}
                    icon={<i className="c-btn__icon fa fa-ticket" />}
                    onClick={async () => {
                      const {
                        rawToolRecoveryPic,
                        serialNumber,
                        manufacturer,
                        model,
                      } = this.state;
                      this.setState({
                        isLoading: true,
                      });

                      let result: any = await requestToolRecovery(
                        rawToolRecoveryPic?.data,
                        serialNumber,
                        manufacturer.toolManufacturer,
                        model.toolModel,
                        this.props.currentShop.shopID,
                        config.AUTH_DIAG_ENVIRONMENT
                      );

                      // Clean up to prevent memory
                      URL.revokeObjectURL(
                        this.state.toolRecoveryPic?.preview as string
                      );

                      let alertTitle = `${TextOnly('success')}!`;
                      let alertMessage = (
                        <span>
                          <Text
                            tid="recoveryRequestSubmitted"
                            sub={{
                              email: <strong>{this.props.user.email}</strong>,
                              num: <span>{this.state.busDaysToRespond + ' - ' + (this.state.busDaysToRespond+1)}</span>,
                            }}
                          />
                        </span>
                      ) as JSX.Element | string;

                      if (result?.error) {
                        alertTitle = TextOnly('error');
                        alertMessage = TextOnly('recoveryRequestFailed');
                        if (result.error) {
                          alertMessage = result.error;
                        }
                      }
                      this.handleCancelRecoveryModal();
                      this.setState({
                        alertTitle: alertTitle,
                        alertMessage: alertMessage,
                        isLoading: false,
                        showModal: true,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default (props: ChildProps) => {
  const userContext = useUser();
  return <ManageShopTools userContext={userContext} {...props} />;
};
