import React, { useEffect, useState, useCallback } from 'react';

interface PhotoGalleryProps {
  images: string[];
  scrollInterval?: number;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ images, scrollInterval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  }, [images.length]);

  const handleClick = useCallback((index: number) => {
    setIsAutoScrolling(false);
    setCurrentIndex(index);
  }, []);

  useEffect(() => {
    if (isAutoScrolling) {
      const interval = setInterval(goToNext, scrollInterval);
      return () => clearInterval(interval);
    }
  }, [isAutoScrolling, goToNext, scrollInterval]);

  return (
    <div className="photo-gallery">
      <div className="image-container">
        <div className="image-slider" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((image, index) => (
            <div key={index} className="image-wrapper">
              <img src={image} alt={`Slide ${index}`} className="gallery-image" />
            </div>
          ))}
        </div>
        <button
          aria-label="Previous Slide"
          className="nav-button prev-button"
          onClick={() => {
            setIsAutoScrolling(false);
            goToPrevious();
          }}
        >
          ‹
        </button>
        <button
          aria-label="Next Slide"
          className="nav-button next-button"
          onClick={() => {
            setIsAutoScrolling(false);
            goToNext();
          }}
        >
          ›
        </button>
      </div>
      <div className="thumbnail-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            onClick={() => handleClick(index)}
            className={`thumbnail ${currentIndex === index ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;