import React from 'react';
import aaGiftCard from '../../assets/images/aa-gift-card.svg';
import { Text } from '../../components/Text';

const PromoBox = ({link}) => {
  return (
    <div className="c-box c-box--promo-interview">
      <div className="l-flex-between">
        <section className="c-box__title">
          <div>
            <i className="font-size-large-icon fa-solid fa-gift-card"></i>
          </div>
        </section>
        <section className="c-section-alt" style={{margin: '0 32px'}}>
          <div className="c-section-alt__content">
            <div className={"c-box__title"}>
              <Text tid={"helpImproveAutoAuth"} />
            </div>
          </div>
        </section>
        <section className="c-section-alt">
          <div className="c-section-alt__content">
            <button
              className="c-btn-full"
              onClick={()=>window.open(link,'_blank')}
              >
              Choose a Time for a Short Interview
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PromoBox;