import React, { FC } from 'react';
import { TextOnly, Text } from './Text';
import logoPlusCRM from '../assets/images/aa-logo-pluscrm.svg';
import { useUser } from '../context/User';
import { useScreenSize } from '../context/ScreenSize';
import { CrmPromoType } from '../types';

interface PromoCardProps {
  promoType: CrmPromoType,
}

const PromoCard: FC<PromoCardProps> = (props) => {
  const { promoType } = props;
  const { currentShop } = useUser();
  const { windowWidth } = useScreenSize();

  return (
    <div className={`${windowWidth > 1500 && 'l-container-sm'} u-margin-none u-min-width-350`}>
      <div className="c-card u-margin-right-none">
        <div className="c-logo u-margin-top-large">
          <img src={logoPlusCRM} className="c-logo__image" alt="logo" />
        </div>

        { promoType === 'manageOrders' && (
          <>
            <h2 className="c-card__title">
              <Text tid={currentShop?.shopType === 'PLUS' ? "signUpAndStreamlineYourShopOperations" : "upgradeToAutoAuthPlusCrmAndStreamline"} />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
                {TextOnly('enhanceShopsEfficiencyWithOrderManagementExplain')}:
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("allServiceOrdersAtAGlanceExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("quicklyCreateAndLogOrdersExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("convenientlyTrackStatusOfOrdersExplain")}
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("attachServiceOrdersToCalendarExplain")}
              </div>
            </div>
          </>
        )}

        { promoType === 'calendar' && (
          <>
            <h2 className="c-card__title">
              <Text tid={currentShop?.shopType === 'PLUS' ? "signUpAndUnlockBenefits" : "upgradeAndSignUpAndUnlockBenefits"} />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
                {TextOnly('leverageTheAutoAuthPlusCrmCalendar')}:
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("manageAppointmentsAndTimeOffExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("seeOverViewOfScheduleExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("gainValuableInsightsIntoYourShopsBusiestTimes")}
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("sendAutomatedTextEmailNotificationsExplain")}
              </div>
            </div>
          </>
        )}

        { promoType === 'manageCustomers' && (
          <>
            <h2 className="c-card__title">
              <Text tid={
                currentShop?.shopType === 'PLUS'
                  ? "signUpForPlusCrmAndGetAccessToCustomerDirectory"
                  : "upgradeAndSignUpForPlusCrmAndGetAccessToCustomerDirectory"
                }
              />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
                {TextOnly('keepInfoOrganizedWithCustomerDirectory')}:
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("addNewCustomersExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("findCustomerContactInfoExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("referenceCustomersServiceHistoryExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("attachCustomerToAppointmentExplain")}
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("sendTextEmailRemindersExplain")}
              </div>
            </div>
          </>
        )}

        { promoType === 'manageVehicles' && (
          <>
            <h2 className="c-card__title">
              <Text tid={
                currentShop?.shopType === 'PLUS'
                  ? "signUpForPlusCrmAndGetAccessToVehicleDirectory"
                  : "upgradeAndSignUpForPlusCrmAndGetAccessToVehicleDirectory"
                }
              />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
                {TextOnly('keepInfoOrganizedWithVehicleDirectory')}:
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("addNewVehiclesExplain")}
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("quicklyGlanceAtVehicleInfoExplain")}
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                {TextOnly("editVehicleInfoExplain")}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PromoCard;