import React, { createContext, useContext, useState, useEffect } from 'react';

// create the screen context
export const ScreenSizeContext = createContext({
  isMobile: false,
  windowWidth: 0,
  windowHeight: 0,
});

export function useScreenSize() {
  return useContext(ScreenSizeContext);
}

type ScreenSizeProviderType = {
  children: JSX.Element;
};

export function ScreenSizeProvider({ children }: ScreenSizeProviderType) {
  // Initial setup of isMobile
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = !!userAgent.match(/(mobile|android|iphone|ipad)/);

  const [isMobile, setIsMobile] = useState(isMobileDevice);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    // Update isMobile
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = !!userAgent.match(/(mobile|android|iphone|ipad)/);
    setIsMobile(isMobileDevice);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const provider = {
    isMobile,
    windowWidth,
    windowHeight,
  };

  return (
    <ScreenSizeContext.Provider value={provider}>
      {children}
    </ScreenSizeContext.Provider>
  );
}
