import React, { useEffect, useState } from 'react';
import { TextOnly } from '../../../components/Text';
import { Header } from '../../../components/Header';
import Calendar from './Calendar';
import {
  crmGetShopCustomers,
  crmGetShopEvents,
  crmGetShopOrders
} from '../../../libs/db-lib';
import { toast } from 'react-toastify';
import { Loading } from '../../../components/Loading';
import { currentShopUsersFromProps } from '../../../libs/utils';
import LoaderButton from '../../../components/LoaderButton';
import { Dialog } from '@reach/dialog';
import AddEditEventForm from './AddEditEventForm';
import { HIGHLIGHTER_COLORS_OBJ } from '../../../CONSTANTS';

const EventManagement: React.FC<any> = (props) => {

  const { user, currentShop } = props;
  const shopUserList = currentShopUsersFromProps(currentShop.shopUsers, ['userID', 'firstName', 'lastName', 'userName', 'shopUserState']);
  const [shopEvents, setShopEvents] = useState<any[] | null>(null);
  const [shopOrders, setShopOrders] = useState<any[]>([]);
  const [shopCustomers, setShopCustomers] = useState<any[]>([]);
  const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false);
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [isAppointment, setIsAppisAppointment] = useState<boolean>(false);


  const getAllShopEvents = async () => {
    const response: any = await crmGetShopEvents(currentShop.shopID);
    if (!response?.error) {
      const shopEvents = response.map((event: any) => {
        const crmColor = 'crm-'
          + (event.allDay ? 'all-day-' : '')
          + (event.highlighterColor);
          event.classNames = [HIGHLIGHTER_COLORS_OBJ[event.highlighterColor], crmColor];
        return event;
      });
      setShopEvents(shopEvents);
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
  }

  const getAllShopOrders = async () => {
    const ordersResponse: any = await crmGetShopOrders(currentShop.shopID);
    if (!ordersResponse?.error) {
      setShopOrders(ordersResponse);
    } else {
      toast.error(`${TextOnly('error')}: ${ordersResponse.error}`, {
        containerId: 'standard',
      });
    }
  }

  const getAllShopCustomers = async () => {
    const customersResponse: any = await crmGetShopCustomers(currentShop.shopID);
    if (!customersResponse?.error) {
      setShopCustomers(customersResponse);
    } else {
      toast.error(`${TextOnly('error')}: ${customersResponse.error}`, {
        containerId: 'standard',
      });
    }
  }

  useEffect(()=>{
    getAllShopEvents();
    getAllShopOrders();
    getAllShopCustomers();
  },[]);

  const handleCancelModal = () => {
    if (!isLoadingEvent) {
      document.querySelector('.c-modal-slider')?.classList.add('closed');
      setTimeout(() => {
        setShowEventModal(false);
        setIsAppisAppointment(false);
      }, 350);
    }
  };

  return (
    <>
      <div>
        <Header title={TextOnly('calendar')} />
        <div className="l-flex-wrap flex-wrap-wrap-reverse">
          <div className="u-margin-right u-margin-top-small">
            <div className='display-flex'>
              <LoaderButton
                text={TextOnly('scheduleAppointment')}
                icon={<i className="c-btn__icon fal fa-plus" />}
                className='u-margin-right'
                onClick={() =>{
                  setIsAppisAppointment(true);
                  setShowEventModal(true);
                }}
              />
              <LoaderButton
                text={TextOnly('scheduleTimeOff')}
                icon={<i className="c-btn__icon fal fa-plus" />}
                onClick={() =>{
                  setIsAppisAppointment(false);
                  setShowEventModal(true);
                }}
              />
            </div>
          </div>
        </div>
        { (shopEvents === null) ? <Loading /> :
          <Calendar
            userLanguage={user.language}
            events={shopEvents}
            currentShop={currentShop}
            getAllShopEvents={getAllShopEvents}
            getAllShopOrders={getAllShopOrders}
            shopUserList={shopUserList}
            shopOrders={shopOrders}
            shopCustomers={shopCustomers}
            user={user}
          />
        }
      </div>
      <Dialog
        isOpen={showEventModal}
        className="c-modal-slider"
        onDismiss={handleCancelModal}
        id={`addEvent`}
        aria-label={'addEvent'}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={()=>handleCancelModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          {isAppointment ? TextOnly('scheduleAppointment') : TextOnly('scheduleEmployeeTimeOff')}
        </h1>
        <AddEditEventForm
          currentShop={currentShop}
          onSubmit={async () => {
            await Promise.all([ //wait for all functions to be completed
              getAllShopEvents(),
              getAllShopOrders(),
            ]); // Run functions concurrently
          }}
          isLoading={isLoadingEvent}
          setIsLoading={setIsLoadingEvent}
          handleCloseModal={()=>handleCancelModal()}
          shopUserList={shopUserList}
          shopOrderList={shopOrders}
          isAppointment={isAppointment}
          shopCustomerList={shopCustomers}
          user={user}
        />
      </Dialog>
    </>
  );
};

export default EventManagement;
