import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import 'react-table/react-table.css';
import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import App from './App';
import { ToastContainer } from 'react-toastify';
import config from './config';
import { init } from '@fullstory/browser';

import { LanguageProvider } from './libs/Language';
import { MenuProvider } from './context/Menu';
import { UserProvider } from './context/User';
import { AA_ENV } from './CONSTANTS';
import { ScrollToTop } from './components/ScrollToTop';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './assets/fonts/CODE2000';
import { ScreenSizeProvider } from './context/ScreenSize';


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'authdiag-dev',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'authdiag-dev-mb',
        endpoint: config.apiGateway.MB_URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'authdiag-dev-crm',
        endpoint: config.apiGateway.CRM_URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

AWS.config.correctClockSkew = true;

const queryClient = new QueryClient();

if (config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.DEV) {
  init({ orgId: '15DHKH' });
}

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LeevFUaAAAAAAoajEyksT6MXLyOgaJsGNLuDpKO"
    scriptProps={{
      async: true,
      defer: true,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <UserProvider>
          <BrowserRouter>
            <ScreenSizeProvider>
              <MenuProvider>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </MenuProvider>
            </ScreenSizeProvider>
          </BrowserRouter>
        </UserProvider>
      </LanguageProvider>
      <ToastContainer
        enableMultiContainer
        containerId={'standard'}
        autoClose={3500}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        style={{
          zIndex: 10000,
        }}
      />
      <ToastContainer
        className={'wide-toast'}
        enableMultiContainer
        containerId={'wide'}
        autoClose={3500}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        style={{
          zIndex: 10000,
        }}
      />
    </QueryClientProvider>
  </GoogleReCaptchaProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const serviceWorkerConfig = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version available!  Refresh to update your app?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
 };
serviceWorkerRegistration.register(serviceWorkerConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();