import React, { useState } from 'react';
import AddEditCustomerForm from './AddEditCustomerForm';
import VehicleList from '../ManageVehicles/VehicleList';
import { Text } from '../../../components/Text';
import AddEditVehicleForm from '../ManageVehicles/AddEditVehicleForm';


const CustomerDetail: React.FC<any> = (props) => {

  const {
    currentShop,
    customer,
    onSubmit,
  } = props;

  const [isLoadingEditCustomer, setIsLoadingEditCustomer] = useState<boolean>(false);
  const [isLoadingVehicle, setIsLoadingVehicle] = useState<boolean>(false);
  const [showVehicleForm, setShowVehicleForm] = useState<boolean>(false);

  return (
    <div className='display-flex l-flex-wrap u-margin-bottom-large'>
      <div className='u-width-100-percent u-margin-none overflow-visible u-margin-bottom-large'>
        <AddEditCustomerForm
          customer={customer}
          updateSingleCustomer={onSubmit}
          setIsLoading={setIsLoadingEditCustomer}
          isLoading={isLoadingEditCustomer}
        />
      </div>
      <div className='flex-start u-width-100-percent'>
        <VehicleList
          allVehicles={customer.vehicles}
          customer={customer}
          currentShop={currentShop}
          onSubmit={onSubmit}
        />
      </div>
      <div className='u-width-100-percent'>
        <span className='clickable' onClick={() => setShowVehicleForm(!showVehicleForm)}>
          <i className={`c-btn__icon fal ${showVehicleForm ? 'fa-minus' : 'fa-plus'}`}/> <Text tid='vehicle' />
        </span>
        <div className={`u-child-margin-none create-vehicle-form ${showVehicleForm ? 'show' : 'hide'}`}>
          <AddEditVehicleForm
            currentShop={currentShop}
            setIsLoading={setIsLoadingVehicle}
            isLoading={isLoadingVehicle}
            handleCloseModal={()=>null}
            customer={customer}
            updateSingleCustomer={onSubmit}
            embeddedForm={true}
            triggerClearVehicleForm={showVehicleForm}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
