import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { languageOptions } from '../libs/languages.js';
import LoaderButton from '../components/LoaderButton';
import { updateUser, getSystemConfig } from '../libs/db-lib';
import { fieldChangeCase } from '../libs/utils';
import { Text, TextOnly } from '../components/Text';
import {
  passwordValidate,
  nameValidate,
  isValidEmail,
  isValidLanguage,
} from '../libs/utils';
import { Loading } from '../components/Loading';
import { Header } from '../components/Header';
import { toast } from 'react-toastify';
import TechVerification from './TechVerification/TechVerification';
import { useLanguage } from '../libs/Language.js';
import AlertModal from '../components/AlertModal';
import { NavLink } from 'react-router-dom';
import { SHOP_ROLES } from '../CONSTANTS';
import config from '../config';



class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.user.firstName || '',
      lastName: this.props.user.lastName || '',
      originalEmail: this.props.user.email || '',
      email: this.props.user.email || '',
      emailVerified: this.props.user.emailVerified || false,
      language: this.props.user.language || '',
      userName: this.props.user.userName || '',
      emailChanged: false,
      alertMessage: '',
      alertTitle: '',
      showModal: false,
      oldPassword: '',
      newPassword: '',
      verifyPassword: '',
      paymentInfo: null,
      emailVerificationCodeSent: false,
      confirmationCode: '',
      user: props.user,
      isLoading: false,
      isChangePasswordLoading: false,
      certifiedStatus: '',
      certifiedStatusColor: '',
    };
  }

  componentDidMount = async () => {
    let config = await getSystemConfig();
    (config);
    this.setState({
      config: this.props.config,
    });

    let self = this;

    this.setState({
      emailVerified: this.props.user.emailVerified || false,
      email: this.props.user.email || '',
    });
  }


  validateForm = () => {
    let namesValid =
      nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);
    let languageValid =
      !this.state.language || isValidLanguage(this.state.language);

    return (
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.email.length > 0 &&
      namesValid &&
      emailValid &&
      languageValid &&
      (this.state.user.firstName !== this.state.firstName ||
        this.state.user.lastName !== this.state.lastName ||
        this.state.user.email !== this.state.email.toLowerCase() ||
        this.state.user.language !== this.state.language)
    );
  }

  validatePasswordForm = () => {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      passwordValidate(this.state.newPassword) &&
      this.state.newPassword === this.state.verifyPassword
    );
  }

  handleChange = (actionType, event) => {
    if (actionType === 'language') {
      this.setState({
        [actionType]: event.target.value || '',
      });
    } else {
      event = actionType;
      let newValue = event.target.value;
      if (event.target.id === 'email') {
        fieldChangeCase(this, event.target, 'lower', false);
        newValue = newValue.toLowerCase();
        if (newValue !== this.state.originalEmail) {
          this.setState({ emailChanged: true });
        } else {
          this.setState({ emailChanged: false });
        }
      } else if (event.target.id === 'confirmationCode') {
        const reg = new RegExp(/^(\s{0}|\d+)$/);
        if (
          event.target.value.length < 11 &&
          reg.test(event.target.value) === true
        ) {
          this.setState({
            confirmationCode: event.target.value,
          });
        }
      } else {
        this.setState({
          [event.target.id]: event.target.value,
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  handleValidateEmail = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.setState({ emailVerificationCodeSent: true });
      })
      .catch((e) => {
        this.setState({
          alertTitle: TextOnly('error'),
          alertMessage: TextOnly('errorSendingVerifyCode') + ': ' + e.message,
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleSubmitConfirmationCode = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', this.state.confirmationCode)
      .then(() => {
        let newUser = this.state.user;
        newUser.emailVerified = true;
        this.setState({
          emailVerificationCodeSent: false,
          emailVerified: true,
          user: newUser,
          alertTitle: TextOnly('success'),
          alertMessage: TextOnly('emailAddressVerified'),
          showModal: true,
          isLoading: false,
        });
        this.props.fetchUser();
      })
      .catch((e) => {
        this.setState({
          alertTitle: TextOnly('error'),
          alertMessage: TextOnly('invalidVerifyCode'),
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    } else {
      return;
    }

    this.setState({ isLoading: true, email: this.state.email });

    let result = await updateUser(
      this.state.firstName,
      this.state.lastName,
      this.state.email,
      this.state.language
    );
    if (result?.error) {
      this.setState({
        alertMessage: result.error,
        showModal: true,
        isLoading: false,
        email: this.state.originalEmail,
        emailChanged: false,
      });
    } else {
      if (this.props.user.language !== this.state.language) {
        this.props.languageContext.userLanguageChange(this.state.language);
      }
      let newUser = this.state.user;
      newUser.firstName = this.state.firstName;
      newUser.lastName = this.state.lastName;
      newUser.email = this.state.email;
      newUser.language = this.state.language;
      toast.success(TextOnly('profileUpdated'), { containerId: 'standard' });

      if (this.state.emailChanged) {
        this.setState({
          emailVerificationCodeSent: true,
          emailVerified: false,
          emailChanged: false,
          originalEmail: newUser.email,
          alertTitle: TextOnly('verify'),
          alertMessage: TextOnly('toCompleteEmailAddressChange'),
          showModal: true,
        });
      }
      this.props.fetchUser();
      this.setState({ user: newUser, isLoading: false });
    }
  };

  handlePasswordSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isChangePasswordLoading: true });

    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        this.state.oldPassword,
        this.state.newPassword
      );
      this.setState({
        isChangePasswordLoading: false,
        oldPassword: '',
        newPassword: '',
        verifyPassword: '',
      });
      toast.success(TextOnly('passwordUpdated'), { containerId: 'standard' });
    } catch (e) {
      let msg = TextOnly('oldPasswordNotCorrect');
      if (e.message !== 'Incorrect username or password.') {
        msg = e.message;
      }
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: msg,
        showModal: true,
        isChangePasswordLoading: false,
      });
    }
  };

  render = () => {
    let langOptions = Object.keys(languageOptions).map((key) => {
      return (
        <option key={key} value={key}>
          {languageOptions[key]}
        </option>
      );
    });

    if (!this.props.user || !this.state.userName) return <Loading />;

    return (
      <>
        <Header title={TextOnly('myProfile')} />
        <div className="l-view-layout--user-profile">
          <div className="user-profile-form-column">
            <form onSubmit={this.handleSubmit}>
              <div>
                <h2>
                  <Text tid="userDetails" />
                </h2>
                <label className="u-margin-bottom-large">
                  <Text tid="username" />: {this.state.userName}
                </label>
              </div>
              <div className="c-field">
                <label htmlFor="firstName" className="c-field__label">
                  <Text tid="firstName" />:
                </label>
                <input
                  id="firstName"
                  type="text"
                  maxLength="50"
                  className={`c-input ${
                    !nameValidate(this.state.firstName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('firstName')}
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                {!nameValidate(this.state.firstName) ? (
                  <div className="c-field__error">
                    <Text tid="firstNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="lastName" className="c-field__label">
                  <Text tid="lastName" />:
                </label>
                <input
                  id="lastName"
                  type="text"
                  maxLength="50"
                  className={`c-input ${
                    !nameValidate(this.state.lastName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('lastName')}
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                {!nameValidate(this.state.lastName) ? (
                  <div className="c-field__error">
                    <Text tid="lastNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="email" className="c-field__label">
                  <Text tid="emailAddress" />:
                </label>
                <input
                  id="email"
                  type="text"
                  maxLength="50"
                  className={`c-input ${
                    !isValidEmail(this.state.email) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('email')}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                {!isValidEmail(this.state.email) ? (
                  <div className="c-field__error">
                    <Text tid="emailHint" />
                  </div>
                ) : null}
              </div>

              <div className="u-margin-bottom">
                {this.state.emailVerified ||
                this.state.emailChanged ||
                this.state.emailVerificationCodeSent ? null : (
                  <button
                    type="button"
                    id="validate-email"
                    className="c-btn"
                    disabled={
                      this.state.emailVerificationCodeSent ||
                      this.state.emailChanged
                    }
                    onClick={this.handleValidateEmail}
                  >
                    <Text tid="validateEmail" />
                  </button>
                )}
              </div>
              {this.state.emailVerificationCodeSent &&
              !this.state.emailChanged ? (
                <>
                  <div className="c-field">
                    <label
                      className="c-field__label"
                      htmlFor="emailVerification"
                    >
                      <Text tid="emailSentMsg" />
                    </label>
                    <input
                      type="text"
                      id="confirmationCode"
                      maxLength="10"
                      value={this.state.confirmationCode}
                      placeholder={TextOnly('confirmationCode')}
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.state.emailVerified ? null : (
                    <button
                      type="button"
                      id="validate-email"
                      className="c-btn u-margin-bottom"
                      disabled={
                        !this.state.emailVerificationCodeSent ||
                        this.state.confirmationCode?.length < 6
                      }
                      onClick={this.handleSubmitConfirmationCode}
                    >
                      <Text tid="submitConfirmationCode" />
                    </button>
                  )}
                </>
              ) : null}
              <div className="c-field">
                <label htmlFor="language" className="c-field__label">
                  <Text tid="language" />:
                </label>
                <div className="c-select">
                  <select
                    id="language"
                    onChange={this.handleChange.bind(this, 'language')}
                    value={this.state.language}
                  >
                    {langOptions}
                  </select>
                </div>
              </div>
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            </form>
          </div>

          { config.CERTIFIED_TECH_STATE === "ACTIVE" ?
            <div className="user-profile-form-column">
              <h2>
                <Text tid="techCertification" />
              </h2>
              <div className="c-field text-bold">
                <Text tid="status" />
                {': '}
                <span className={"u-font-weight-bold "+this.state.certifiedStatusColor}>
                  <Text tid={this.state.certifiedStatus} />
                </span>
              </div>
              <div className="c-box">
                <TechVerification
                  fetchUser={this.props.fetchUser}
                  currentShop={this.props.currentShop}
                  setCertStatus={(value) => {
                    this.setState({
                      certifiedStatus: value.certifiedStatus,
                      certifiedStatusColor: value.certifiedStatusColor,
                    });
                  }} />
              </div>
            </div>
          : '' }
          {this.state.user && this.state.emailVerified === true ? (
          <div className="user-profile-form-column">
            <form onSubmit={this.handlePasswordSubmit}>
              <h2>
                <Text tid="changePassword" />
              </h2>
              <div className="c-field">
                <label htmlFor="oldPassword" className="c-field__label">
                  <Text tid="oldPassword" />:
                </label>
                <input
                  id="oldPassword"
                  type="password"
                  maxLength="50"
                  placeholder={TextOnly('oldPassword')}
                  value={this.state.oldPassword}
                  onChange={this.handleChange}
                />
              </div>

              <div className="c-field">
                <label htmlFor="newPassword" className="c-field__label">
                  <Text tid="newPassword" />:
                </label>
                <input
                  id="newPassword"
                  type="password"
                  maxLength="50"
                  className={`c-input ${
                    this.state.newPassword.length > 0 &&
                    !passwordValidate(this.state.newPassword)
                      ? 'error'
                      : ''
                  }`}
                  placeholder={TextOnly('newPassword')}
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                />
                {this.state.newPassword.length > 0 &&
                !passwordValidate(this.state.newPassword) ? (
                  <div className="c-field__error">
                    <Text tid="passwordHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="verifyPassword" className="c-field__label">
                  <Text tid="verifyNewPassword" />:
                </label>
                <input
                  id="verifyPassword"
                  type="password"
                  maxLength="50"
                  className={`c-input ${
                    this.state.verifyPassword.length > 0 &&
                    this.state.newPassword !== this.state.verifyPassword
                      ? 'error'
                      : ''
                  }`}
                  placeholder={TextOnly('verifyNewPassword')}
                  value={this.state.verifyPassword}
                  onChange={this.handleChange}
                />
                {this.state.verifyPassword.length > 0 &&
                this.state.newPassword !== this.state.verifyPassword ? (
                  <div className="c-field__error">
                    <Text tid="confirmPasswordHint" />
                  </div>
                ) : null}
              </div>

              <LoaderButton
                type="submit"
                disabled={!this.validatePasswordForm()}
                isLoading={this.state.isChangePasswordLoading}
                text={TextOnly('changePassword')}
                loadingText={TextOnly('changing')}
              />
            </form>
          </div>
          ) : null}

          {this.state.user?.shopRole !== SHOP_ROLES.OWNER ? (
            <div className="signup-column">
              <div className="c-card">
                <h2 className="c-card__title">
                  <Text tid="forServiceCenters" />
                </h2>
                <p className="c-card__description">
                  <Text tid="serviceCenterSignupMessage" />
                </p>
                <NavLink to="/shopSignup">
                  <Text tid="serviceCenterSignupLink" />
                </NavLink>
              </div>

              <div className="c-card u-margin-top-xlarge">
                <h2 className="c-card__title">
                  <Text tid="forTechnicians" />
                </h2>
                <p className="c-card__description">
                  {/* // TODO: add ordered list - Chance Smith 9/24/21 */}
                  <Text
                    tid="employeeSignupMessage"
                    sub={{ userProfile: <Text tid="userProfile" /> }}
                  />
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size={'small'}
          handleCancel={this.handleCancel.bind(this)}
        />
      </>
    );
  }
}

export default (props) => {
  const languageContext = useLanguage();
  return <UserProfile languageContext={languageContext} {...props} />;
};
