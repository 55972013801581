import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const BasicDatePicker: React.FC<any> = (props) => {
  const { stateVariable, setStateVariable, minSelectableDate, disabled=false } = props;
  const [date, setDate] = useState<Date>();
  const [minDate, setMinDate] = useState<Date>();
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(()=>{
    if (stateVariable) {
      setDate(new Date(`${stateVariable}T00:00`));

    } else if (minSelectableDate) {
      const minDate = new Date(`${minSelectableDate}T00:00:00`);
      setDate(minDate);
      setMinDate(minDate);

    } else {
      setDate(new Date());
    }
  }, [])

  useEffect(() => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      setStateVariable(formattedDate);
    }
  }, [date]);

  useEffect(() => {
    if (minSelectableDate && stateVariable) {
      const newDate = new Date(`${minSelectableDate}T00:00:00`);
      if (minSelectableDate > stateVariable) {
        setDate(newDate);
      }
      setMinDate(newDate);
    }
  }, [minSelectableDate]);


  return (
    <>
      <div
        tabIndex={0}
        className={`datepicker-wrapper ${isFocus ? 'is-focus' : ''} ${disabled ? 'disabled' : ''}`}
      >
        <DatePicker
          calendarIcon={<i className="fa-light fa-calendar-days clickable" />}
          clearIcon={null}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onChange={(e) => setDate(e as Date)}
          minDate={minDate}
          value={date}
          calendarProps={
            {calendarType: 'gregory'}
          }
          disabled={disabled}
        />
      </div>
      {/* {minSelectableDate && minSelectableDate > stateVariable && (
        <div className="c-field__error">
          <Text tid="endDateHint" />
        </div>
      )} */}
    </>
  );
};

export default BasicDatePicker;