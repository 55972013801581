import React from 'react';
import { Text } from '../../components/Text';

interface SubEndingNotifyProps {
  paidThroughDate: string,
  expired: boolean,
  last4?: string,
}
const SubEndingNotify: React.FC<SubEndingNotifyProps> = (props) => {

  const {
    paidThroughDate,
    expired,
    last4,
  } = props;

  return (
    <div>
      {expired ? (
        <div className="c-field__error">
          { last4 ?
            <Text tid="userCanceledSubscription" />
            :
            <Text tid="noPaymentMethodAvailable" />
          }
          <div className="text-bold">
            <Text tid="yourSubscriptionEnded" />{' '}
            {paidThroughDate}
          </div>
        </div>
      ) : (
        <div className="c-field__error">
          { last4 ?
            <Text tid="userCanceledSubscription" />
            :
            <Text tid="noPaymentMethodAvailable" />
          }
          <div className="text-bold">
            <Text tid="yourSubscriptionWillEnd" />{' '}
            {paidThroughDate}
          </div>
        </div>
      )}
    </div>
  );
}

export default SubEndingNotify;