import React, { useEffect, useState } from "react";

const BasicTimePicker: React.FC<any> = (props) => {

  const { stateVariable, setStateVariable, minSelectableTime, isEndTime, disabled=false } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(()=>{
    if (!stateVariable) {
      const currentTime = new Date();
      let currentHour = currentTime.getHours();
      let currentMinute = currentTime.getMinutes();
      //Round up to the next XX:30 or XX:00 time
      if (currentMinute > 30) {
        currentMinute = 0;
        currentHour += 1;
      } else {
        currentMinute = 30;
      }
      if (isEndTime) {
        currentHour += 1;
      }
      setStateVariable(
        `${currentHour}:${currentMinute < 10 ? `0${currentMinute}` : currentMinute}`
      );
    } else {
      if (!!minSelectableTime && (minSelectableTime >= stateVariable) && (minSelectableTime < '23:00')) {
        let newHour = Number(minSelectableTime.split(':')[0]) + 1;
        let newMinute = Number(stateVariable.split(':')[1]);
        setStateVariable(
          `${newHour}:${newMinute < 10 ? `0${newMinute}` : newMinute}`
        );
      }
    }
  },[minSelectableTime, stateVariable]);


  return stateVariable === null ? null : (
    <>
      <div tabIndex={0} className={`timepicker-wrapper ${isFocus ? 'is-focus' : ''} ${disabled ? 'disabled' : ''}`}>
        <input
          type="time"
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          value={stateVariable}
          className="form-control"
          onChange={(e) => setStateVariable(e.target.value)}
          min={minSelectableTime}
          step="900"
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default BasicTimePicker;