export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  AUTH_DIAG_ENVIRONMENT: "PROD",
  MESSAGE_BOARD_STATE: "ACTIVE",
  CERTIFIED_TECH_STATE: "INACTIVE",
  OWNER_VERIFICATION_STATE: "INACTIVE",
  CRM_STATE: "ACTIVE",
  STRIPE_PUBLIC_KEY: "pk_live_51NyJtqD9mKfN0INjbodoUkFTp2l14aCPeir2BtOPrndm8W8EGgWdhuX0aHTuZsDdDZN9tF2cYLztXgrXpMCWMCbb00IsBGKrn2",
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://te3hog68tb.execute-api.us-west-2.amazonaws.com/dev",
    MB_URL: "https://rpad4fbkih.execute-api.us-west-2.amazonaws.com/dev",
    CRM_URL: "https://hryic0hg88.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_8mUyDfy6H",
    APP_CLIENT_ID: "2o12fjbo1uoljaac21l7hbmsrv",
    DOMAIN: "https://autoauth-confirm.auth.us-west-2.amazoncognito.com",
    IDENTITY_POOL_ID: "us-west-2:4669f1d0-d8aa-4acb-900e-664feda13492"
  }
};
