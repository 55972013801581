import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import { CreateMenu } from '../../components/CreateMenu';
import LoaderButton from '../../components/LoaderButton';
import AlertModal from '../../components/AlertModal';
import ToolTip from '../../components/ToolTip';
import ShopInfo from './ShopInfo';
import ManageBilling from './ManageBilling';
import Subscriptions from './Subscriptions';

import {
  getOEMRegionList,
  logSmartyStreets,
  getRegionOemToolCompatibility,
} from '../../libs/db-lib';
import { Text, TextOnly } from '../../components/Text';
import {
  nameValidate,
  scalePdf,
} from '../../libs/utils';
import { Header } from '../../components/Header';
import 'react-phone-number-input/style.css'
import countries from 'i18n-iso-countries';
import { SHOP_TYPES } from '../../CONSTANTS';
import _ from 'underscore';

export default class ShopProfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emailChanged: false,
      alertTitle: '',
      alertMessage: '',
      showModal: false,
      showAddUsersModal: false,
      username: this.props.user.userName || '',
      shopPaymentInfo: {},
      paymentInfo: null,
      user: this.props.user,
      numToAdd: null,
      isEnterprise: this.props?.currentShop?.shopType === SHOP_TYPES.ENTERPRISE,
      isLoading: false,
      isLoadingIncreaseMaxUsers: false,
      isLoadingShopName: false,
      isLoadingCancelSubscription: false,
      isLoadingConfirmAddress: false,
      isLoadingChangingOEMRegions: false,
      isLoadingRepayDisputedPayment: false,
      isLoadingChargingRepayment: false,
      isLoadingCancelDowngradeSubscription: false,
      loadingAddress: '',
      paymentAmount: 0,
      showReceiptModal: false,
      currentReceipt: null,
      pdfScale: scalePdf(window.outerWidth),
      showCancelSubscriptionModal: false,
      showDowngradeSubscriptionModal: false,
      showPaymentHistoryModal: false,
      showVerifyAddressModal: false,
      addressesToVerify: [],
      internationalAddressesToVerify: [],
      addressToLog: {},
      smartyStreetsResponse: {},
      selectedAddress: {},
      showOEMRegionRemovalModal: false,
      showOEMRegionReinstateModal: false,
      showRepayDisputedPaymentModal: false,
      oemIDs: null,
      oemIDsSelected: new Set(),
      oemIDsAdded: new Set(),
      confirmOemRegionModal: false,
      showModalMO: false,
      oemIDsRemoved: new Set(),
      regionCode: this.props.currentShop?.regionCode,
      showPriceBreakdown: false,
      perTechCertRate: 0,
      chartData: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentShop?.phone !== prevProps.currentShop?.phone) {
      this.setState({
        shopPhoneNumber: this.props.currentShop?.phone,
      });
    }

    if (this.props.currentShop?.oemIDs !== prevProps.currentShop?.oemIDs) {
      if(this.props.currentShop.oemIDs) this.props.currentShop.oemIDs.forEach((oemID) => {
        this.state.oemIDsSelected.add(oemID);
      });
    }
  }

  async componentDidMount() {
    let self = this;
    let config = this.props.config;

    const oemRegions = await getOEMRegionList();
    const oemIDsSelected = new Set();
    if(this.props?.currentShop?.oemIDs?.length) this.props.currentShop.oemIDs.forEach((oemRegionID) => {
      oemIDsSelected.add(oemRegionID);
    });

    this.setState({
      config: config,
      oemRegions: oemRegions,
      oemIDsSelected: oemIDsSelected,
    });

    let countryISO2Codes = Object.keys(
      countries.getNames('en', { select: 'official' })
    );
    if (countryISO2Codes.indexOf(this.state.country) === -1) {
      this.setState({
        country: '',
      });
    }

    // Set timeout to get the compatibility data in the background
    setTimeout(async () => {
      const response = await getRegionOemToolCompatibility();
      if (!response.error) {
        self.setState({
          chartData: response.data
        });
      }
    }, 350);

    window.addEventListener(
      'resize',
      () => {
        this.setState({
          pdfScale: scalePdf(window.outerWidth),
        });
      },
      true
    );
    window.addEventListener(
      'orientationchange',
      () => {
        this.setState({
          pdfScale: scalePdf(window.outerWidth),
        });
      },
      true
    );
    if (this?.typeaheadRef?.current?._instance?.state) {
      this.typeaheadRef.current._instance.state.text =
        this.props.currentShop?.streetAddress1 || '';
    }

    if (this.props.viewReceipt === 'true') {
      const transactionFound =
        this.props.currentShop.shopPaymentInfo.transactions.some(
          (transaction) => transaction.id === this.props.transactionId
        );

      if (!transactionFound) {
        this.props.history.push('/myActivity');
        return;
      }
      this.setState(
        {
          showPaymentHistoryModal: true,
        },
        () => {
          if (this.props.transactionId) {
            this.handleViewReceipt(
              this.props.transactionId,
              new Event('click')
            );
          }
        }
      );
    }
  }

  translateStatus(status) {
    if (status === 'submitted_for_settlement') {
      return (
        <b className="u-text-success">{TextOnly('submittedForSettlement')}</b>
      );
    } else if (status === 'settled') {
      return <b className="u-text-success">{TextOnly('settled')}</b>;
    } else if (
      status === 'gateway_rejected' ||
      status === 'processor_declined' ||
      status === 'settlement_declined'
    ) {
      return <b className="u-text-error">{TextOnly('failedCharge')}</b>;
    } else {
      return status;
    }
  }

  profileFieldsHaveChanged() {
    let shopNameValid = nameValidate(this.state.shop);
    let addressValid = this.state?.streetAddress1?.length > 0;
    let cityValid = this.state?.city?.length > 0;
    let stateValid = this.state?.state?.length > 0;
    let zipValid = this.state?.zip?.length > 0;
    let countryValid = this.state?.country?.length > 0;
    let phoneValid = this.state?.shopPhoneNumber?.length > 0;
    return (
      shopNameValid &&
      addressValid &&
      cityValid &&
      stateValid &&
      zipValid &&
      countryValid &&
      phoneValid &&
      (
        this.state.shop !== this.props.currentShop.shopName ||
        this.state.streetAddress1 !== this.props.currentShop.streetAddress1 ||
        this.state.streetAddress2 !== this.props.currentShop.streetAddress2 ||
        this.state.city !== this.props.currentShop.city ||
        this.state.state !== this.props.currentShop.state ||
        this.state.country !== this.props.currentShop.country ||
        this.state.zip !== this.props.currentShop.zip ||
        this.state.shopPhoneNumber !== this.props.currentShop?.phone ||
        this.state.shareInfo !== this.props.currentShop.shareInfo
      )
    );
  }

  handleCancel = () => {
    this.setState({ showModal: false, showRepayDisputedPaymentModal: false });
  };

  validateAddress = () => {
    var xmlHttp = new XMLHttpRequest();
    const self = this;

    const isInternational = this.state.country !== 'US';

    const smartyStreetKey = this.state.config.smartyStreets.key;

    const apiHost = isInternational ? 'international-street' : 'us-street';
    const verifyPrefix = isInternational ? 'verify' : 'street-address';

    const streetAddress1 = this.state.streetAddress1.replace('#', 'Suite ');
    const streetAddress2 = this.state.streetAddress2.replace('#', 'Suite ');
    const streetAddress1Str = isInternational
      ? `&address1=${this.state.streetAddress1}`
      : `&street=${streetAddress1}`;
    const streetAddress2Str = this.state.streetAddress2
      ? isInternational
        ? `&address2=${this.state.streetAddress2}`
        : `&secondary=${streetAddress2}`
      : '';
    const cityStr = isInternational
      ? `&locality=${this.state.city}`
      : `&city=${this.state.city}`;
    const stateStr = isInternational
      ? `&administrative_area=${this.state.state}`
      : `&state=${this.state.state}`;
    const zipStr = isInternational
      ? `&postal_code=${this.state.zip}`
      : `&zipcode=${this.state.zip}`;
    const countryStr = isInternational ? `&country=${this.state.country}` : '';

    let url = `https://${apiHost}.api.smartystreets.com/${verifyPrefix}?key=${smartyStreetKey}`;
    url += streetAddress1Str;
    url += streetAddress2Str;
    url += cityStr;
    url += stateStr;
    url += zipStr;
    url += countryStr;
    url += isInternational ? '' : `&candidates=10`;
    url += isInternational ? '&license=international-global-plus-cloud' : '';

    const addressToLog = {
      isInternational: isInternational,
      requestUrl: url,
      streetAddress1: self.state.streetAddress1,
      streetAddress2: self.state.streetAddress2,
      city: self.state.city,
      state: self.state.state,
      zip: self.state.zip,
      country: self.state.country,
    };

    xmlHttp.onreadystatechange = async function () {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        const jsonResponse = JSON.parse(xmlHttp.response);
        let result = {};

        self.setState({
          addressToLog: addressToLog,
        });

        if (self.state.country === 'US') {
          self.setState({
            addressesToVerify: jsonResponse,
            smartyStreetsResponse: jsonResponse,
          });
          if (jsonResponse.length === 0) {
            result = {
              success: false,
              component: self.constructor.name,
              error: {
                type: 'API',
                statusText:
                  'Unable to find an address for the given input. Please check and try again.',
              },
            };
            await logSmartyStreets(result, addressToLog, jsonResponse, {});
          }
        } else {
          let internationalAddressesToVerify = jsonResponse.filter(
            (address) => {
              return address.analysis.verification_status !== 'None';
            }
          );
          self.setState({
            internationalAddressesToVerify: internationalAddressesToVerify,
            smartyStreetsResponse: jsonResponse,
          });
          if (internationalAddressesToVerify.length === 0) {
            result = {
              success: false,
              component: self.constructor.name,
              error: {
                type: 'API',
                statusText:
                  'Unable to find an address for the given input. Please check and try again.',
              },
            };
            await logSmartyStreets(result, addressToLog, jsonResponse, {});
          }
        }

        self.handleShowVerifyAddressModal();
      } else if (
        xmlHttp.status !== 200 &&
        xmlHttp.status !== 0 &&
        xmlHttp.readyState === 4
      ) {
        let result = {
          success: false,
          component: self.constructor.name,
          error: {
            type: 'HTTP',
            code: xmlHttp.status,
            responseText: JSON.parse(xmlHttp.responseText).message,
          },
        };
        await logSmartyStreets(result, addressToLog, {}, {});
        self.setState({
          alertTitle: 'Error',
          alertMessage: JSON.parse(xmlHttp.responseText).message,
          showModal: true,
        });
      }
    };

    xmlHttp.open('GET', url, true); // true for asynchronous
    xmlHttp.setRequestHeader('Content-Type', 'application/json');
    xmlHttp.send(null);
  };


  handleCancelReceiptModal() {
    const self = this;
    document.querySelector('.c-modal').classList.add('closed');
    setTimeout(() => {
      self.setState({ showReceiptModal: false });
    }, 350);
  }

  handleShowVerifyAddressModal() {
    this.setState({
      showVerifyAddressModal: true,
    });
  }

  handleCancelModal() {
    const self = this;
    const modalSlider = document.querySelector('.c-modal-slider');
    if (modalSlider) {
      modalSlider.classList.add('closed');
    }
    setTimeout(() => {
      self.setState({
        showOEMRegionRemovalModal: false,
        oemIDsRemoved: new Set(),
        showAddUsersModal: false,
        showReceiptModal: false,
        showAddUsersCompleteModal: false,
        showOEMRegionReinstateModal: false,
        isLoadingIncreaseMaxUsers: false,
        showPaymentHistoryModal: false,
        showVerifyAddressModal: false,
        showRepayDisputedPaymentModal: false,
        isLoadingRepayDisputedPayment: false,
        showModal: false,
      });
    }, 350);
  }


  compareDates(a, b, desc){
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (desc) {
      if (dateA > dateB) {
        return 1;
      } else  {
        return -1;
      }
    } else {
      if (dateA < dateB) {
        return -1;
      } else {
        return 1;
      }
    }
  };


  render() {
    const self = this;
    const { shopPaymentInfo, newBillAmount, upgradeNeeded } = this.props.currentShop;
    const nextPaymentAmount = newBillAmount && upgradeNeeded ?
      parseFloat(newBillAmount).toFixed(2) : shopPaymentInfo.nextBillAmount;
    // const nextBillAmount = upgradeNeeded ? nextPaymentAmount : this.calculateSubPrice(this.props.currentShop);
    const nextBillAmount = nextPaymentAmount;
    // const subscriptionCredit = shopPaymentInfo.balance && shopPaymentInfo.balance !== "0.00" ?
    //     -1 * shopPaymentInfo.balance
    //   : !upgradeNeeded ?
    //     (parseFloat(nextBillAmount) - parseFloat(nextPaymentAmount)).toFixed(2)
    //     : 0;

    const isEnterprise = this.state.isEnterprise;
    const isPlus = this.props?.currentShop?.shopType === SHOP_TYPES.PLUS;
    const isDowngradingAtRenewal = this.props?.currentShop?.downgradeToStandardPending ? true : false;
    const renewalTier = (isDowngradingAtRenewal || !isPlus) && !upgradeNeeded ? 'AutoAuth STANDARD' : 'AutoAuth PLUS';

    const paymentState = this.props?.user?.paymentState || '';
    const paidThruDate = this.props?.currentShop?.shopPaymentInfo
      ? new Date(shopPaymentInfo.paidThroughDate).getTime()
      : 0;
    const today = new Date().getTime();

    const intAddrToVerify = this.state.internationalAddressesToVerify;

    // US Street Addresses can be validated to mutiple possibilities
    const addressOptions =
      this.state.country === 'US' &&
      this.state.addressesToVerify.map((address) => {
        // If address contains a secondary number (apt, suite, unit)
        // Separate it, since we divide them into two components
        let primaryAddress = '';
        let secondaryAddress = '';
        if (address.components.secondary_designator) {
          let index = address.delivery_line_1.indexOf(
            address.components.secondary_designator
          );
          primaryAddress = address.delivery_line_1.substring(0, index).trim();
          secondaryAddress = address.delivery_line_1.substring(index).trim();
        }
        return (
          <div key={address.delivery_line_1}>
            <div className="c-verify-address u-margin-top">
              <div className="c-verify-address__fields">
                <dl key="1">
                  <dt>
                    <Text tid="streetAddress1" />
                  </dt>
                  <dd>{primaryAddress || address.delivery_line_1 || ''}</dd>
                </dl>
                {address.components.secondary_designator ? (
                  <dl key="2">
                    <dt>
                      <Text tid="streetAddress2" />
                    </dt>
                    <dd>{secondaryAddress}</dd>
                  </dl>
                ) : null}

                <dl key="3">
                  <dt>
                    <Text tid="city" />
                  </dt>
                  <dd>{address.components.city_name}</dd>
                </dl>
                <dl key="4">
                  <dt>
                    <Text tid="state" />
                  </dt>
                  <dd>{address.components.state_abbreviation || ''}</dd>
                </dl>
                <dl key="5">
                  <dt>
                    <Text tid="zipCode" />
                  </dt>
                  <dd>{address.components.zipcode || ''}</dd>
                </dl>
                <dl key="6">
                  <dt>
                    <Text tid="country" />
                  </dt>
                  <dd>{this.state.country}</dd>
                </dl>
              </div>
              <div className="c-verify-address__footer">
                <div />
                <div className="c-verify-address__footer-right">
                  <LoaderButton
                    className="u-margin-top"
                    type="button"
                    isLoading={
                      this.state.isLoadingConfirmAddress &&
                      address.delivery_line_1 === this.state.loadingAddress
                    }
                    text={TextOnly('confirm')}
                    loadingText={TextOnly('savingChanges')}
                    onClick={() => {
                      self.setState(
                        {
                          loadingAddress: address.delivery_line_1,
                          streetAddress1: address.components
                            .secondary_designator
                            ? primaryAddress
                            : address.delivery_line_1,
                          streetAddress2: address.components
                            .secondary_designator
                            ? secondaryAddress
                            : '',
                          city: address.components.city_name,
                          state: address.components.state_abbreviation,
                          zip: address.components.zipcode,
                          country: self.state.country,
                          phone: self.state.phone,
                        },
                        () => {
                          self.handleEditShop();
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });

    // International addresses
    const intAddressOptions = intAddrToVerify.map((address, index) => {
      return (
        <div className="c-verify-address u-margin-top" key={index}>
          <div className="c-verify-address__fields">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => {
              if (address[`address${num}`]) {
                return (
                  <dl key={num}>
                    <dt />
                    <dd>{address[`address${num}`]}</dd>
                  </dl>
                );
              }
              return null;
            })}
            <dl>
              <dt />
              <dd>{address.components.country_iso_3}</dd>
            </dl>
          </div>
          <div className="c-verify-address__footer">
            <div />
            <div className="c-verify-address__footer-right">
              <LoaderButton
                className="u-margin-top"
                type="button"
                isLoading={this.state.isLoadingConfirmAddress}
                text={TextOnly('confirm')}
                loadingText={TextOnly('verifying')}
                onClick={() => {
                  let streetAddr = address.address1;
                  if (
                    Object.keys(address.metadata).length === 0 ||
                    address.metadata.address_format
                      .split('|')[0]
                      .indexOf('premise') === -1
                  ) {
                    streetAddr =
                      address.components.premise +
                      ' ' +
                      address.components.thoroughfare;
                  }
                  let postalCode = address.components.postal_code;
                  if (
                    address.components.postal_code_short &&
                    address.components.postal_code_extra
                  ) {
                    postalCode = `${address.components.postal_code_short}-${address.components.postal_code_extra}`;
                  }
                  self.setState(
                    {
                      streetAddress1: streetAddr,
                      city: address.components.dependent_locality
                        ? address.components.dependent_locality +
                          ', ' +
                          address.components.locality
                        : address.components.locality,
                      state: address.components.administrative_area,
                      zip: postalCode,
                      country: self.state.country,
                      phone: self.state.phone,
                    },
                    () => {
                      self.handleEditShop();
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    });

    const freeformProps = {};
    freeformProps.renderMenu = (results, menuProps) => {
      return CreateMenu(
        results,
        menuProps,
        'label',
        ['', 'label'],
        true,
        '',
        true
      );
    };


    let disputeAmount = Number(parseFloat(this.props.currentShop?.disputeAmount).toFixed(2));
    if (this.props?.currentShop?.shopType !== "ENTERPRISE" && (!disputeAmount || disputeAmount === "")) {
      for (let i = 0; i < this.props.currentShop.shopPaymentInfo.transactions.length; i++) {
        if (this.props.currentShop.shopPaymentInfo.transactions[i].type !== 'paymentRefund') {
          disputeAmount = Number(parseFloat(this.props.currentShop.shopPaymentInfo.transactions[i].amount).toFixed(2));
          break;
        }
      }
    }

    return (
      <div className="home">
        <Header title={TextOnly('shopProfile')} />

          <div className={!this.props.user?.shops || !this.state?.oemRegions ? 'hide-element' : ''}>
            <div className="c-box--white u-padding-none">
              <Tabs defaultActiveKey={'shopInfo'}>
                <Tab
                  eventKey={'shopInfo'}
                  label={TextOnly('shopInfo')}
                  className="tab-shop-profile"
                >
                  <ShopInfo
                    currentShop={this.props?.currentShop}
                    config={this.state.config}
                    fetchUser={this.props.fetchUser}
                    />
                </Tab>
                { !isEnterprise ?
                  <Tab
                    className="tab-shop-profile"
                    eventKey={'subscriptions'}
                    label={TextOnly('subscriptions')}
                  >
                    <Subscriptions
                      user={this.props?.user}
                      config={this.state.config}
                      compatiblityChart={this.state.chartData}
                      currentShop={this.props?.currentShop}
                      oemRegions={this.state.oemRegions}
                      fetchUser={this.props.fetchUser}
                    />
                  </Tab>
                : '' }
                { !isEnterprise ?
                  <Tab
                    className="tab-shop-profile"
                    eventKey={'manageBilling'}
                    label={TextOnly('manageBilling')}
                  >
                    <ManageBilling
                      user={this.props?.user}
                      currentShop={this.props?.currentShop}
                      oemRegions={this.state.oemRegions}
                      config={this.state.config}
                      fetchUser={this.props.fetchUser}
                    />
                  </Tab>
                : '' }
            </Tabs>
          </div>
        </div>
        <div className={this.props.user?.shops && this.state?.oemRegions ? 'hide-element' : 'loading-panel'}>
          <i
            className="fal fa-spinner-third spinning"
            title={TextOnly('loading')}
          />
        </div>
        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />

        <Dialog
          isOpen={this.state.showVerifyAddressModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal-slider"
          aria-label={TextOnly('verifyAddress')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>

          {addressOptions.length > 0 || intAddressOptions.length > 0 ? (
            <>
              <div className="h2 c-verify-address__title">
                <Text tid="verify" />
              </div>
              {this.state.country === 'US' ? addressOptions : intAddressOptions}
            </>
          ) : (
            <div className="h2 c-verify-address__title">
              <Text tid="unableToFindAddress" />
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}
