import React from 'react';
import ToolTip from './ToolTip';

interface TechAvatarProps {
  tech: any,
  size?: string,
}

export const TechAvatar: React.FC<TechAvatarProps> = ({
  tech,
  size='',
}) => {

  const colorClassName = tech?.avatarColor || 'tech-color-no-color-assigned';

  return (
    <ToolTip text={tech?.name || `${tech?.firstName} ${tech?.lastName}`}>
      <div className={`tech-profile-avatar ${colorClassName} ${size}`}>
        { tech?.name ?
          `${tech.name[0]}${tech.name[tech.name.indexOf(' ') + 1]}`
          : `${tech?.firstName[0]}${tech?.lastName[0]}`
        }
      </div>
    </ToolTip>
  );
};
