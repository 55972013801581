import React, { useEffect } from "react";
import PhoneInput, { isPossiblePhoneNumber, type Value, Country } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { Text } from "../Text";

interface BasicPhoneInputProps {
  phoneNumber: any;
  setPhoneNumber: Function;
  setIsValidPhone?: Function;
  country?: Country;
  disabled?: boolean;
}

const BasicPhoneInput: React.FC<BasicPhoneInputProps> = ({ phoneNumber, setPhoneNumber, setIsValidPhone=(()=>null), country='US', disabled=false }) => {

  const handleChangePhoneNumber = (number?: Value | undefined) => {
    setPhoneNumber(number ?? '');
  }

  useEffect(()=>{
    const isPossibleNum: boolean = isPossiblePhoneNumber(phoneNumber ?? '');
    setIsValidPhone(isPossibleNum);
  },[phoneNumber]);

  return (
    <div>
      <PhoneInput
        id="phone"
        type="tel"
        maxLength="50"
        className={`c-input ${
          !isPossiblePhoneNumber(phoneNumber) && phoneNumber !== ''
            ? "phoneError"
            : ""
        }`}
        value={phoneNumber}
        onChange={handleChangePhoneNumber}
        defaultCountry={country}
        autoComplete={'off'}
        disabled={disabled}
      />
      { (phoneNumber.length > 0) && !isPossiblePhoneNumber(phoneNumber) &&
        <div className="c-field__error">
          <Text tid="phoneNumberHint" />
        </div>
      }
    </div>
  );
};

export default BasicPhoneInput;
