import React from 'react';
import { ChildProps } from '../../types';
import { useScreenSize } from '../../context/ScreenSize';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import AASubscription from './AASubscription';
import CRMSubscription from './CRMSubscription';

interface RegionData {
  [key: string]: any;
}
interface SubscriptionsProps extends ChildProps {
  oemRegions: { [key: string]: any}[];
  compatiblityChart: RegionData[];
}

const Subscriptions = (props: SubscriptionsProps) => {

  const {
    user,
    currentShop,
    config,
    fetchUser,
    oemRegions,
    compatiblityChart,
  } = props;

  const { windowWidth, isMobile } = useScreenSize();
  const isSmallScreen = windowWidth < 690 || isMobile;


  return (
    <>
      <div className={`l-flex-row`}>
        {isSmallScreen || isMobile ? (
          <Tabs
            defaultActiveKey={'autoAuth'}
            listClassName={'l-flex-gap-small'}
            contentClassName="tab-content--subscriptions"
          >
            <Tab
              eventKey={'autoAuth'}
              label={`AutoAuth`}
              className="tab-subscriptions u-width-100-percent"
            >
              <AASubscription
                user={user}
                currentShop={currentShop}
                config={config}
                fetchUser={fetchUser}
                oemRegions={oemRegions}
                compatiblityChart={compatiblityChart}
              />
            </Tab>
            <Tab
              eventKey={'crm'}
              className="tab-subscriptions u-width-100-percent"
              label={'PLUS CRM'}
            >
              <CRMSubscription
                config={config}
                currentShop={currentShop}
                fetchUser={fetchUser}
                user={user}
              />
            </Tab>
          </Tabs>
        ) : (
          <div className="l-flex-gap u-flex-expand">
            <AASubscription
              user={user}
              currentShop={currentShop}
              config={config}
              fetchUser={fetchUser}
              oemRegions={oemRegions}
              compatiblityChart={compatiblityChart}
              className={"l-container u-width-100-percent"}
            />
            <CRMSubscription
              config={config}
              currentShop={currentShop}
              fetchUser={fetchUser}
              className={`l-container u-width-100-percent`}
              user={user}
            />
          </div>
        )}
      </div>
    </>

  );
}

export default Subscriptions;

