import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';
import AlertModal from '../components/AlertModal';
import { Text, TextOnly } from '../components/Text';
import { upgradeShopType, getSystemConfig, shopCRMSignup } from '../libs/db-lib';
import { SHOP_TYPES } from '../CONSTANTS';
import { PricingTable } from '../components/PricingTable';
import { toast } from 'react-toastify';
import { Dialog } from '@reach/dialog';
import CrmPromoContent from './Crm/Promo/CrmPromoContent';

import StatsAndTrendsPromo from './StatsAndTrendsPromo';

export default class UpgradeToStandardPlus  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showConfirmModal: false,
      showPaymentMethodModal: false,
      isLoadingUpgrade: false,
      paymentAmount: 0,
      plusShopRate: 0,
      showReceiptModal: false,
      currentReceipt: {},
      showLearnMoreModal: false,
      reviewOnTop: window.innerWidth <= 1200,
      innerWidth: window.innerWidth,
      newShopType: null,
      showCrmLearnMoreModal: false,
      windowWidth: 0,
      showCRMSignupModal: false,
      config: null,
      isLoadingCRMUpgrade: false,
      cantUpgradeTitle: 'noPaymentMethodAvailable',
      cantUpgradeMessage: 'shopDoesNotHaveFormOfPayment',
    };
  }

  async componentDidMount() {
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.handleResize);
    try {
      let config = await getSystemConfig();
      this.setState({
        plusShopRate: config.prices.plusShop,
        plusCrmRate: config.prices.crmBasicMonthly.toFixed(2),
        config: config,
      });
    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
      showCRMSignupModal: false,
    });
  };

  async upgradeToPlus() {
    if(!this.props.user) return;
    this.setState({ isLoadingUpgrade: true });
    let result = await upgradeShopType(
      this.props.currentShop.shopID,
      this.state.newShopType,
      !this.props.currentShop?.crmFreeTrialUsed,
    );
    this.setState({
      isLoadingUpgrade: false,
      showConfirmModal: false
    });
    if (result?.error) {
      toast.error(result.error, {
        containerId: 'standard',
        autoClose: false,
        closeOnClick: true
      });
    } else {
      this.props.fetchUser();
      this.props.history.push('/myActivity');
      toast.success(TextOnly('youHaveBeenCharged') + ` $${result}.`, {
        containerId: 'standard',
      });
    }
  }

  async handleConfirmCRMModal () {
    this.setState({
      isLoadingCRMUpgrade: true,
    })

    let ret = await shopCRMSignup(
      this.props.currentShop?.shopID,
      !this.props.currentShop?.crmFreeTrialUsed
    );
    if (ret?.error) {
      toast.dismiss();
      toast.error(ret.error, {
        containerId: 'standard',
      });
      document.querySelector('.c-modal')?.classList.add('closed');
      setTimeout(() => {
        this.setState({
          isLoadingCRMUpgrade: false,
          showCRMSignupModal: false,
        })
      }, 350);
    } else {
      this.props.fetchUser();
      this.props.history.push('/myActivity');
      toast.success(
        `${TextOnly('shopCRMActive', { price: ret?.chargedToday })}`,
        { containerId: 'standard' }
      );
    }
  }

  handleChangePlan = (shopType) => {
    if(!this.props.user) return;
    const noPaymentMethod = !this.props.currentShop.shopPaymentInfo.last4;

    if (noPaymentMethod) {
      // show modal telling the user to add a payment method
      this.setState({
        cantUpgradeMessage: 'shopDoesNotHaveFormOfPayment',
        cantUpgradeTitle: 'noPaymentMethodAvailable',
        showPaymentMethodModal: true,
      });
    } else {
      if (this.props.currentShop?.shopSubscriptionState === "USER_CANCEL") {
        this.setState({
          cantUpgradeMessage: (this.props.currentShop?.shopType === "PLUS" ? "activeShopRequiredForCRMSignup" : "activeShopRequiredForUpgrade"),
          cantUpgradeTitle: 'activeShopRequiredTitle',
          showPaymentMethodModal: true,
        });
      } else {
        if (this.props.currentShop?.shopType === "PLUS" && shopType === "PLUSCRM") {
          this.setState({
            showCRMSignupModal: true,
          });
        } else {
          this.setState({
            showConfirmModal: true,
            newShopType: shopType,
          });
        }
      }
    }
  };

  handleNavToShopProfile = () => {
    this.props.history.push('/shopProfile');
  };

  handleCancelPaymentMethodModal = () => {
    this.setState({
      showPaymentMethodModal: false,
    });
  };
  handleCancelConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
    });
  };

  handleShowLearnMoreModal = () => {
    this.setState({
      showLearnMoreModal: true,
    });
  };

  handleShowCrmLearnMoreModal = () => {
    this.setState({
      showCrmLearnMoreModal: true,
    });
  };

  handleCancelLearnMoreModal = () => {
    let selector =
      this.state.windowWidth < 753 ? '.c-modal-slider-90' : '.c-modal-slider-75';
    document.querySelector(selector).classList.add('closed');
    setTimeout(() => {
      this.setState({
        showLearnMoreModal: false,
      });
    }, 350);
  };

  handleCancelCrmLearnMoreModal = () => {
    let selector =
      this.state.windowWidth < 753 ? '.c-modal-slider-90' : '.c-modal-slider-75';
    document.querySelector(selector).classList.add('closed');
    setTimeout(() => {
      this.setState({
        showCrmLearnMoreModal: false,
      });
    }, 350);
  };

  handleResize = () => {
    this.setState({
      reviewOnTop: window.innerWidth <= 1200,
      windowWidth: window.outerWidth,
    });
  };

  render() {

    const { reviewOnTop, windowWidth } = this.state;
    const crmTotalUpchargePrice =
            (this.props.currentShop?.shopMaxUsers -
              this.state.config?.shopBaseValues?.plusBaseUsers) * this.state.config?.prices?.crmUserUpcharge;
    const crmUserUpchargeStr = this.state.config?.prices?.crmUserUpcharge?.toFixed(2);
    const crmTotalPrice = this.state.config?.prices.crmBasicMonthly + crmTotalUpchargePrice;

    return (
      <div className="home">

        {this.props.auto ?

          <div className="l-container">
            <h2>
              <Text tid="autoUpgradeNeeded"/>
            </h2>

          </div>

          : ''
        }

        <>
          <h2 className="c-page-heading">
            <Text tid="comparePlans" />
          </h2>
          <p>
            <strong>
              <Text tid="autoAuthRepresents"/>
            </strong>
          </p>
          <div className="l-container l-view-layout__pricing-content">
            {/* {reviewOnTop &&
              <div className={`c-notice c-notice--promo-plus costumer-quote maxwidth-600px ${innerWidth <= 449.99 ? 'backgroundcolor-aa-gray' : null}`}
              >
                <Quotation
                  className={`margin-bottom-20px ${innerWidth <= 449.99 ? 'q-box' : 'q-box2'}`}
                  title={TextOnly('messageBoard')}
                />
                <p className={`${innerWidth <= 449.99 ? 'color-aa-darkblue' : ''}`}>
                  "{TextOnly('toryMReview')}"
                </p>
                <p className={`${innerWidth <= 449.99 ? 'color-aa-darkblue' : ''}`}>
                  -Tory M.
                </p>
              </div>
            } */}
            <PricingTable
              handleChangePlan={this.props.handleChangePlan ?? this.handleChangePlan}
              handleLearnMore={this.props.handleShowLearnMoreModal ?? this.handleShowLearnMoreModal}
              handleCrmLearnMore={this.props.handleShowCrmLearnMoreModal ?? this.handleShowCrmLearnMoreModal}
              shopType={this.props.currentShop?.shopType ?? null}
              user={this.props.user}
              currentShop={this.props.currentShop}
            />
            {/* {!reviewOnTop &&
              <div className="c-notice c-notice--promo-plus costumer-quote">
                <Quotation
                  className={`margin-bottom-20px q-box2`}
                  title={TextOnly('messageBoard')}
                />
                <p>
                    "{TextOnly('toryMReview')}"
                </p>
                <p>-Tory M.</p>
              </div>
            } */}
          </div>
        </>


        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />

        <Dialog
          isOpen={this.state.showPaymentMethodModal && this.props.user}
          onDismiss={this.handleCancelPaymentMethodModal}
          className="c-modal"
          aria-label={TextOnly('noPaymentMethodAvailable')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelPaymentMethodModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid={this.state.cantUpgradeTitle} />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid={this.state.cantUpgradeMessage} />
            </p>
            <div className="l-flex u-margin-top-large">
              <button
                className="c-btn-outline u-margin-right"
                onClick={this.handleCancelPaymentMethodModal}
              >
                <Text tid="cancel" />
              </button>
              <button className="c-btn" onClick={this.handleNavToShopProfile}>
                <Text tid="goToShopProfile" />
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showCRMSignupModal}
          onDismiss={()=> {this.handleCancel.bind(this)}}
          className="c-modal"
          aria-label={TextOnly('confirmCRMSignup')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancel.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmCRMSignup" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouWantToSignupCRM" />
            </p>
            <p>
              { this.props.currentShop?.crmFreeTrialUsed ?
                <Text tid="youWillBeChargedToday" sub={{price: <>{crmTotalPrice.toFixed(2)}</>}} />
                :
                <Text tid="afterFreeTrialYouWillBeCharged" sub={{price: <>{crmTotalPrice.toFixed(2)}</>}} />
              }
            </p>

            <p className={crmTotalPrice - this.state.config?.prices.crmBasicMonthly > 0 ? "u-text-error" : 'hide-element'}>
              <Text tid="note" />
              <Text tid={"crmUpchargeExplain"} sub={{base: this.state.plusCrmRate, upcharge: crmUserUpchargeStr}} />
            </p>
            <button
              className="c-btn-outline"
              onClick={this.handleCancel.bind(this)}
              disabled={this.state.isLoadingCRMUpgrade}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={this.state.isLoadingCRMUpgrade}
              text={TextOnly('confirm')}
              loadingText={TextOnly('savingChanges')}
              onClick={this.handleConfirmCRMModal.bind(this)}
            />
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showConfirmModal && this.props.user}
          onDismiss={this.handleCancelConfirmModal}
          className="c-modal"
          aria-label={TextOnly('areYouSureUpgradeShopType', {shopType: this.state.newShopType === 'PLUSCRM' ? 'PLUS CRM' : 'PLUS'})}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelConfirmModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="upgrade" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureUpgradeShopType" sub={{shopType: this.state.newShopType === 'PLUSCRM' ? 'PLUS CRM' : 'PLUS'}} />
            </p>
            <p>
              <Text
                tid="willBeChargedUpgradingToPlus"
                sub={{
                  amount: this.state.plusShopRate,
                }}
              />
            </p>
            { this.state.newShopType?.includes('CRM') ?
              <>
              { this.props.currentShop?.crmFreeTrialUsed ?
                <p>
                  <Text
                    tid="crmChargeExplained"
                    sub={{
                      price: this.state.plusCrmRate,
                    }}
                  />
                </p>
                :
                <p>
                  <Text
                    tid="crmChargeExplainedFreeTrial"
                    sub={{
                      price: this.state.plusCrmRate,
                    }}
                  />
                </p>
              }
              </>
            :  '' }
            <div className="l-container l-container-center l-flex u-margin-top-large">
              <button
                className="c-btn-outline u-margin-right"
                onClick={this.handleCancelConfirmModal}
              >
                <Text tid="cancel" />
              </button>
              <LoaderButton
                className="c-btn"
                type="submit"
                isLoading={this.state.isLoadingUpgrade}
                text={TextOnly('confirm')}
                loadingText={TextOnly('upgrading')}
                onClick={this.upgradeToPlus.bind(this)}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showLearnMoreModal}
          onDismiss={this.handleCancelLearnMoreModal}
          className={
            this.state.windowWidth < 753
              ? 'c-modal-slider-90'
              : 'c-modal-slider-75'
          }
          aria-label={TextOnly('learnMore')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelLearnMoreModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="standardPlusFeatures" />
          </h1>

          <div className="c-modal__body">
            {<StatsAndTrendsPromo props={this.props} />}
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showCrmLearnMoreModal}
          onDismiss={this.handleCancelCrmLearnMoreModal}
          className={
            this.state.windowWidth < 753
              ? 'c-modal-slider-90'
              : 'c-modal-slider-75'
          }
          aria-label={TextOnly('learnMore')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelCrmLearnMoreModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="plusCrmFeatures" />
          </h1>

          <div className="c-modal__body">
            <CrmPromoContent promoType='calendar' />
            <CrmPromoContent promoType='manageOrders' />
            <CrmPromoContent promoType='manageCustomers' />
            <CrmPromoContent promoType='manageVehicles' />
          </div>
        </Dialog>
      </div>
    );
  }
}