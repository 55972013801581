import React, { useState, useEffect, useRef } from 'react';
import { Shop, User } from '../../types';
import { Text, TextOnly } from '../../components/Text';
import { PAYMENT_STATES, SHOP_TYPES } from '../../CONSTANTS';
import PriceBreakdown from '../../components/PriceBreakdown';
import AlertModal from '../../components/AlertModal';

interface NextPaymentProps {
  currentShop: Shop,
  subscriptionType: string,
  user: User,
  config: { [key: string]: any },
}
const NextPayment: React.FC<NextPaymentProps> = (props) => {

  const {
    currentShop,
    subscriptionType,
    user,
    config,
  } = props;

  const [title, setTitle] = useState('');
  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
  const [description, setDescription] = useState<any>();

  const newBillAmount = currentShop.newBillAmount;
  const shopPaymentInfo = currentShop.shopPaymentInfo;
  const nextBillDate = shopPaymentInfo?.nextBillingDate;
  const crmNextBillDate = shopPaymentInfo?.crmNextBillingDate;
  const nextPaymentAmount = newBillAmount ?
    parseFloat(newBillAmount).toFixed(2) : shopPaymentInfo?.nextBillAmount;
  const crmNextBillAmount = shopPaymentInfo?.crmNextBillAmount;
  const nextBillAmount = nextPaymentAmount;
  const paymentState = subscriptionType === 'CRM' ? currentShop?.crmSubscriptionState : currentShop?.shopSubscriptionState;
  const isDowngradingAtRenewal = props?.currentShop?.downgradeToStandardPending ? true : false;
  const isPlus = props?.currentShop?.shopType === SHOP_TYPES.PLUS;
  const renewalTier = (isDowngradingAtRenewal || !isPlus) ? 'AutoAuth STANDARD' : 'AutoAuth PLUS';
  // currentTier only used for CRM subscription breakdown since it is billed monthly
  let currentTier = isPlus ? 'AutoAuth PLUS' : 'AutoAuth STANDARD';
  const lastFourStr = shopPaymentInfo?.last4 ?
                        ' ...' + shopPaymentInfo.last4
                        : null;
  return (
    <>
      <div>
        {subscriptionType === 'AutoAuth' ?
          <>
            {lastFourStr &&
              nextBillAmount &&
              paymentState !== PAYMENT_STATES.NONE ? (
                <div className="l-margin-top-2rem">
                  <section className="c-section">
                    <label className="c-section__label">
                      <Text tid="nextSubAmount" sub={{'subscription': <span className="text-bold">{subscriptionType}</span>}} />
                    </label>
                    <div>
                      <span className="c-section__key c-section__content-no-space-between">
                        <span>
                          <div className="text-bold">${nextPaymentAmount} ({renewalTier})</div>
                          { paymentState !== PAYMENT_STATES.PAST_DUE ?
                            <div className={'u-margin-top-xsmall'}>
                              <Text tid="toBeChargedOn" />{' '}
                                <span className="text-bold">{nextBillDate}</span>
                            </div>
                            : <div className="c-field__error">{ TextOnly("pastDue") }</div>
                          }
                        </span>
                        <span>
                          <button
                            className="c-btn-icon"
                            onClick={() => {
                              setTitle(TextOnly('priceBreakdown'));
                              setShowPriceBreakdown(true);
                              setDescription(
                                <PriceBreakdown
                                  config={config}
                                  renewalTier={renewalTier}
                                  subscriptionType={'AutoAuth'}
                                  shop={props.currentShop}
                                />
                              );
                            }}
                          >
                            <div className="c-btn__inner">
                              <i className="c-btn__icon fal fa-question-circle" />
                            </div>
                          </button>
                        </span>
                      </span>
                    </div>
                  </section>
                </div>
              )
            : null}
          </>
        : subscriptionType === 'CRM' ?
        <>
          {lastFourStr &&
            crmNextBillAmount &&
            paymentState !== PAYMENT_STATES.NONE ? (
              <div className="l-margin-top-2rem">
                <section className="c-section">
                  <label className="c-section__label">
                    <Text tid="nextSubAmount" sub={{'subscription': <span className="text-bold">{subscriptionType}</span>}} />
                  </label>
                  <div>
                    <span className="c-section__key c-section__content-no-space-between">
                      <span>
                        <div className="text-bold">${crmNextBillAmount}</div>
                        { paymentState !== PAYMENT_STATES.PAST_DUE ?
                          <div className={'u-margin-top-xsmall'}>
                            <Text tid="toBeChargedOn" />{' '}
                              <span className="text-bold">{crmNextBillDate}</span>
                          </div>
                          : <div className="c-field__error">{ TextOnly("pastDue") }</div>
                        }
                      </span>
                      <span>
                        <button
                          className="c-btn-icon"
                          onClick={() => {
                            setTitle(TextOnly('priceBreakdown'));
                            setShowPriceBreakdown(true);
                            setDescription(
                              <PriceBreakdown
                                config={config}
                                renewalTier={
                                  crmNextBillDate && nextBillDate
                                  && (crmNextBillDate < nextBillDate)
                                    ? currentTier
                                    : renewalTier
                                }
                                subscriptionType={'CRM'}
                                shop={currentShop}
                              />
                            );
                          }}
                        >
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fal fa-question-circle" />
                          </div>
                        </button>
                      </span>
                    </span>
                  </div>
                </section>
              </div>
            )
          : null}
        </>
      : '' }
      </div>
      <AlertModal
        showModal={showPriceBreakdown}
        handleCancel={() => setShowPriceBreakdown(false)}
        title={title}
        message={description}
      />
    </>
  );

}

export default NextPayment;