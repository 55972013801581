import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import HelpTooltip from '../../../components/HelpTooltip';
import { Dialog } from '@reach/dialog';
import CustomerDetail from './CustomerDetail';

const EditCustomer: React.FC<any> = ({ customer, currentShop, onSubmit
 }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.edit-customer')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);
  };

  return (
    <div>
      <HelpTooltip label={TextOnly('customerDetails')}>
        <button
          className="c-btn-icon-compact"
          onClick={() => setShowModal(true)}
        >
          <div className="c-btn__inner">
            <span className="c-btn__icon fal fa-pen-to-square" />
          </div>
        </button>
      </HelpTooltip>
      <Dialog
        isOpen={showModal}
        className="c-modal-slider edit-customer"
        id={TextOnly('customerDetails')}
        aria-label={TextOnly('customerDetails')}
        onDismiss={()=>handleCancelModal()}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={()=>handleCancelModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">{TextOnly('customerDetails')} - {customer.firstName} {customer.lastName}</h1>
        <div className='u-margin-center u-margin-top l-container-med'>
          <CustomerDetail
            currentShop={currentShop}
            customer={customer}
            onSubmit={onSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default EditCustomer;
