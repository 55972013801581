import React, { useState, useEffect, useRef } from 'react';
import { Shop } from '../../types';
import { Text, TextOnly } from '../../components/Text';
import LoaderButton from '../../components/LoaderButton';
import { PAYMENT_STATES } from '../../CONSTANTS';
import braintree from 'braintree-web-drop-in';
import { getBraintreeConfig } from '../../libs/utils-ts';
import { toast } from 'react-toastify';
import {
  updateShopPaymentInfo,
} from '../../libs/db-lib';


interface ChangePaymentProps {
  currentShop: Shop,
  config: any,
  fetchUser: any,
}
const ChangePayment: React.FC<ChangePaymentProps> = (props) => {

  const {
    currentShop,
    config,
    fetchUser,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [braintreeDropin, _setBraintreeDropin] = useState<any>(null);
  const braintreeDropinRef = useRef(braintreeDropin);
  const setBraintreeDropin = (data: any) => {
    braintreeDropinRef.current = data;
    _setBraintreeDropin(data);
  }
  const [paymentInfo, setPaymentInfo] = useState<any>();

  const last4 = currentShop?.shopPaymentInfo?.last4;

  useEffect(() => {

  }, []);

  useEffect(() => {

    if (config && !last4) {
      const braintreeConfig = getBraintreeConfig(config?.braintreeMode);
      braintree.create(
        braintreeConfig,
        (createErr: any, instance) => {
          if (createErr) {
            console.error("braintree.create error: ", createErr);
            toast.error(`Braintree Error: ${createErr.message}`, {
              containerId: 'standard',
            });
          } else {
            setBraintreeDropin(instance);
            let button = document.getElementById('changePayment');
            if (button) {
              button.addEventListener('click', () => {
                let instance: any = braintreeDropinRef.current;
                instance.requestPaymentMethod((err: any, payload: any) => {
                  setPaymentInfo(payload);
                  if (payload) {
                    handleChangePaymentInfo(payload);
                  } else {
                    toast.dismiss();
                    toast.error(TextOnly('enterPaymentMethod'), {
                      containerId: 'standard',
                    });
                  }
                });
              });
            }
          }
        }
      );
    } else if (config && last4 && isEditingPayment) {
      const braintreeConfig = getBraintreeConfig(config?.braintreeMode);
      braintree.create(
        braintreeConfig,
        (createErr: any, instance) => {
          if (createErr) {
            console.error("braintree.create error: ", createErr);
            toast.error(`Braintree Error: ${createErr.message}`, {
              containerId: 'standard',
            });
          } else {
            setBraintreeDropin(instance);
            let button = document.getElementById('changePayment');
            if (button) {
              button.addEventListener('click', () => {
                let instance: any = braintreeDropinRef.current;
                instance.requestPaymentMethod((err: any, payload: any) => {
                  setPaymentInfo(payload);
                  if (payload) {
                    handleChangePaymentInfo(payload);
                  } else {
                    toast.dismiss();
                    toast.error(TextOnly('enterPaymentMethod'), {
                      containerId: 'standard',
                    });
                  }
                });
              });
            }
          }
        }
      );
    }

  }, [config, last4, isEditingPayment])

  const handleStartChangePayment = () => {
    setIsEditingPayment(true);
  }

  const handleChangePaymentInfo = async (paymentInfo: any) => {
    setIsLoading(true);
    let result = await updateShopPaymentInfo(
      currentShop?.shopID,
      paymentInfo
    );
    if (result?.error) {
      toast.dismiss();
      toast.error(result.error, {
        containerId: 'standard',
      });
      setIsLoading(false);
      braintreeDropinRef.current.clearSelectedPaymentMethod();
    } else {
      const message = result.amountChargedToday ? `${TextOnly(
        'youHaveBeenCharged'
      )} $${result.amountChargedToday} ${TextOnly('today')}.` : ''
      toast.dismiss()
      toast.success(
        `${TextOnly('paymentMethodUpdated')}. ${message}`, {
        containerId: 'standard',
        autoClose: false,
      });

      setIsLoading(false);
      setIsEditingPayment(false);

      braintreeDropinRef.current.clearSelectedPaymentMethod();
      await fetchUser(currentShop.shopID);
    }
  };


  return (
    last4 && !isEditingPayment ?
      <a
        className="h2 text-link u-margin-top u-margin-bottom"
        onClick={() => {
          handleStartChangePayment();
        }}>
        <span>+</span> <Text tid="changePayment" />
      </a>
    :
      <>
        {last4 ?
          <div className="h2 u-margin-top u-margin-bottom-none">
            <Text tid="changePayment" />
          </div>
        :
          <div className="h2 u-margin-top u-margin-bottom-none">
            <Text tid="addPayment" />
          </div>
        }
        <form
          onSubmit={function (event) {
            event.preventDefault();
          }}
          >
          <div className="col-sm-5 margin-top-40">
            <div id="braintree-container"></div>
          </div>
          <div className='l-container-center u-margin-bottom-small'>
            <LoaderButton
              id="changePayment"
              type="submit"
              isLoading={isLoading}
              text={
                last4
                  ? TextOnly('changePayment')
                  : currentShop?.shopSubscriptionState === PAYMENT_STATES.TERMINATED ||
                    currentShop?.shopSubscriptionState === PAYMENT_STATES.USER_CANCEL
                  ?
                    <>
                      {TextOnly('reinstateShop')}
                      <br/>
                      (${ currentShop?.shopSubscriptionState === PAYMENT_STATES.TERMINATED ?
                          currentShop?.shopPaymentInfo?.nextBillAmount
                        : '0.00' })
                    </>
                  : TextOnly('addPayment')
              }
              loadingText={TextOnly('savingPayment')}
            />
          </div>
        </form>
      </>
  )
}

export default ChangePayment;
