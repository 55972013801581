import React, { Component } from 'react';
import CustomDatePicker from '../../components/CustomDatePicker';
import { LangDictKey, Text, TextOnly } from '../../components/Text';
import moment, { Moment } from 'moment';
import _ from 'underscore';
import { Dialog } from '@reach/dialog';
import { Link } from 'react-router-dom';
import {
  generateFilterRegex,
  formatDateTime,
  formatDateTime2Lines
} from '../../libs/utils';
import {
  getUsersForShop,
  removeUserFromShop,
  changeShopUserState,
  revokeInviteFromUserFromShop,
  getShopUserAuditLog,
  getSystemConfig,
  getOEMRegionList,
} from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import ReactTable, { Column } from 'react-table';
import 'react-table/react-table.css';
import AlertModal from '../../components/AlertModal';
import { Loading } from '../../components/Loading';
import { Header } from '../../components/Header';
import { toast } from 'react-toastify';
import { ChildProps, User, Action, ShopRole, ShopState } from '../../types';
import { SHOP_ROLES, SHOP_STATES, SHOP_TYPES } from '../../CONSTANTS';
import { getSavedColumns } from '../../libs/utils-ts';
import { LogSubComponent } from '../../components/subComponents';
import { assignTechColors } from '../../libs/utils';
import { TechAvatar } from '../../components/TechAvatar';

import AddUser from './AddUser';
import SignupLinks from './SignupLinks';
import DirectoryServices from './DirectoryServices';

export default class ManageUsers extends Component<
  ChildProps,
  Record<string, any>
> {
  constructor(props: ChildProps) {
    super(props);

    this.state = {
      userName: '',
      newUserFirstName: '',
      newUserLastName: '',
      shopUsers: [],
      shopUsersFiltered: [],
      alertTitle: '',
      alertMessage: '',
      showModal: false,
      isLoading: true,
      isLoadingUsers: true,
      showConfirmModal: false,
      showDeleteModal: false,
      showAddUserModal: false,
      showBulkAddUsersModal: false,
      showSignupLinksModal: false,
      showDirectoryServicesModal: false,
      showInfoModal: false,
      isRemoveUserLoading: false,
      filter: '',
      filterActions: '',
      filterLinks: '',
      bulkImportErrors: '',
      activeKey: 1,
      userActions: [],
      previousText: TextOnly('previous'),
      nextText: TextOnly('next'),
      pageText: TextOnly('page'),
      ofText: TextOnly('of'),
      rowsText: TextOnly('rows'),
      baseURL: '',
      windowWidth: 0,
      shopID: '',
      isSendingRequest: false,
      shopUsersCSV: '',
      oemRegions: [],
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      let config = this.props.config;
      this.setState({ baseURL: config.baseUrl });

      const usersWithColors = assignTechColors(this.props.currentShop.shopUsers ?? []);

      this.setState({
        isLoadingUsers: false,
        shopUsers: usersWithColors || [],
        shopUsersFiltered: usersWithColors || [],
        windowWidth: window.outerWidth,
        // shopUsersCSV: shopUsersCSV + '\n'+ lines.join('\n'),
      });
    } catch (e: any) {
      this.setState({
        alertTitle: TextOnly('error'),
        isLoadingUsers: false,
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
      });
    }
    const oemRegionsList = await getOEMRegionList();
    this.setState({
      oemRegions: oemRegionsList,
    });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  activateField = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  async handleConfirmModal() {
    const self = this;

    // Handle delete of user
    this.setState({ isRemoveUserLoading: true });
    let userRec = this.state.userToDelete;
    let result: any;
    if (userRec?.state === 'INVITED') {
      result = await revokeInviteFromUserFromShop(
        this.props.currentShop.shopID,
        userRec.email
      );
    } else {
      result = await removeUserFromShop(
        this.props.currentShop.shopID,
        userRec?.userID
      );
    }
    if (!result?.error) {
      if (userRec?.userID) {
        this.handleRemoveUser(userRec.userID);
      } else {
        this.handleRemoveInvitee(userRec?.email);
      }

      toast.success(TextOnly('removedUser'), { containerId: 'standard' });

      document.querySelector('.c-modal-slider')?.classList.add('closed');
      setTimeout(() => {
        const remainingUsers = this.state.shopUsers.filter(
          (user: { userID: string; email: string }) => {
            if (userRec?.userID) {
              return user.userID !== userRec?.userID;
            }
            return user.email !== userRec?.email;
          }
        );
        self.setState({
          showConfirmModal: false,
          isRemoveUserLoading: false,
          userToDelete: undefined,
          shopUsers: assignTechColors(remainingUsers),
        });
      }, 350);
    } else {
      document.querySelector('.c-modal-slider')?.classList.add('closed');
      setTimeout(() => {
        this.setState({
          alertTitle: TextOnly('error'),
          alertMessage: result.error,
          showConfirmModal: false,
          isRemoveUserLoading: false,
          showModal: true,
        });
      }, 350);
    }
  }

  handleShowAlert = (
    alertTitle: string,
    alertMessage: string | JSX.Element,
    showModal: boolean
  ) => {
    this.setState({
      alertTitle: alertTitle,
      alertMessage: alertMessage,
      showModal: showModal,
      isLoading: false,
    });
  };

  handleDeleteButton(userRec: User) {
    this.setState({ userToDelete: userRec, showConfirmModal: true });
  }

  handleInfoButton(userRec: User) {
    const endDate = moment();
    const startDate = moment().subtract(1, 'month');

    this.getUserAuditLog(userRec, startDate, endDate);
  }

  async getUserAuditLog(
    userRec: User,
    startDate: moment.Moment,
    endDate: moment.Moment
  ) {
    const endDateStr = endDate.utc().toISOString();
    const startDateStr = startDate.utc().toISOString();

    this.setState({
      loadingActions: userRec?.userID,
    });
    let results = await getShopUserAuditLog(
      userRec.userID,
      this.props.currentShop.shopID,
      startDateStr,
      endDateStr
    );
    this.setState({
      userActions: results.logEntries,
      userActionsFiltered: results.logEntries,
      filterActions: '',
      startDate: startDate,
      endDate: endDate,
      actionUser: userRec,
      activeKey: 5,
      loadingActions: undefined,
      showInfoModal: true,
    });
  }

  handleAddUser = async (newUser: User) => {
    let shopUsers = this.state.shopUsers;
    shopUsers.push(newUser);
    const usersWithColors = assignTechColors(shopUsers);
    await this.props.fetchUser(this.props.currentShop.shopID);

    toast.success(TextOnly('requestHasBeenSent'), {
      containerId: 'standard',
      autoClose: false,
    });
    this.setState({
      shopUsers: usersWithColors,
      shopUsersFiltered: usersWithColors,
    });
  };

  handleBulkAddUsers = async (
    newUsers: { userList: User[] },
    errors: any[]
  ) => {
    newUsers.userList.forEach((u) => {
      if (u.inviteStatus !== 'SUCCESS') {
        errors.push(u);
      }
    });

    let shopUsers = await getUsersForShop(this.props.currentShop.shopID);
    const usersWithColors = assignTechColors(shopUsers);

    this.setState({
      shopUsers: usersWithColors,
      shopUsersFiltered: usersWithColors,
    });
  };

  async handleRemoveUser(userID: string) {
    // Update the user table
    let shopUsers = this.state.shopUsers;
    let newShopUsers = assignTechColors(shopUsers.filter((user: any) => {
      return user.userID !== userID;
    }));
    await this.props.fetchUser(this.props.currentShop.shopID);

    this.setState({ shopUsers: newShopUsers });
    if (this.state.filter) {
      this.updateFilteredList(this.state.filter);
    } else {
      this.setState({
        shopUsersFiltered: newShopUsers,
      });
    }
  }

  async handleRemoveInvitee(email?: string) {
    // Update the user table
    let shopUsers = this.state.shopUsers;
    let newShopUsers = assignTechColors(shopUsers.filter((user: { email: string }) => {
      return user.email !== email;
    }));
    await this.props.fetchUser(this.props.currentShop.shopID);

    this.setState({ shopUsers: newShopUsers });
    if (this.state.filter) {
      this.updateFilteredList(this.state.filter);
    } else {
      this.setState({
        shopUsersFiltered: newShopUsers,
      });
    }
  }

  handleChangeShopUserState(
    userID: string,
    newState: ShopState,
    newRole: ShopRole
  ) {
    // Update the user table
    let shopUsers = this.state.shopUsers;
    let index = -1;
    for (let i = 0; i < shopUsers.length; ++i) {
      if (shopUsers[i].userID === userID) {
        index = i;
        break;
      }
    }
    shopUsers[index].shopUserState = newState;
    shopUsers[index].shopUserRole = newRole;
    this.setState({ shopUsers: shopUsers });
  }

  updateFilter = _.debounce((f: string) => {
    this.updateFilteredList(f);
  }, 300);

  updateFilteredList = (filter: string) => {
    let userList = this.state.shopUsers;

    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      userList = this.state.shopUsers.filter((u: any) => {
        let addedByUser = this.state.shopUsers.filter(
          (user: any) => user?.userID === u.addedBy
        );
        let addedByUserStr =
          addedByUser?.length > 0
            ? addedByUser[0].firstName + ' ' + addedByUser[0].lastName
            : u.addedBy !== 'LINK'
            ? '<' + TextOnly('unknown') + '>'
            : TextOnly('LINK');
        let addedOnStr = formatDateTime(u.addedOn);
        if (
          regexStr.test(u.firstName) ||
          regexStr.test(u.lastName) ||
          regexStr.test(TextOnly(u.shopUserRole)) ||
          regexStr.test(TextOnly(u.shopUserState)) ||
          regexStr.test(u.userName) ||
          regexStr.test(addedOnStr) ||
          regexStr.test(addedByUserStr)
        ) {
          return true;
        } else {
          let found = false;
          if (u.additionalIDFields) {
            let keys = Object.keys(u.additionalIDFields);
            keys.forEach((key) => {
              if (regexStr.test(u?.additionalIDFields?.[key] || '')) {
                found = true;
              }
            });
          }
          return found;
        }
      });
    }
    this.setState({
      shopUsersFiltered: userList || [],
    });
  };

  updateActionFilter = _.debounce((f: string) => {
    this.updateActionFilteredList(f);
  }, 300);

  updateActionFilteredList = (filter: string) => {
    let actionList = this.state.userActions;
    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      actionList = this.state.userActions.filter((a: any) => {
        const addedOnStr = formatDateTime(a.actionDate);
        return (
          regexStr.test(a.actionMessage) ||
          regexStr.test(a.actionCode) ||
          regexStr.test(addedOnStr)
        );
      });
    }
    this.setState({
      userActionsFiltered: actionList || [],
    });
  };

  handleRangeChange = (value: { start: Moment; end: Moment }) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
    // @ts-ignore
    this.getUserAuditLog(this.state.actionUser, value.start, value.end);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'filter') {
      this.updateFilter(event.target.value);
      this.setState({
        filter: event.target.value,
      });
    } else if (event.target.id === 'filterActions') {
      this.updateActionFilter(event.target.value);
      this.setState({
        filterActions: event.target.value,
      });
    }
  };

  handleChangeState = (
    userID: string,
    newState: ShopState,
    newRole: ShopRole
  ) => {
    // Handle change state of user
    changeShopUserState(
      this.props.currentShop.shopID,
      userID,
      newState,
      newRole
    );

    this.handleChangeShopUserState(userID, newState, newRole);
  };

  handleGridSizeChanged(sizeInfo: { api: { sizeColumnsToFit: () => void } }) {
    sizeInfo.api.sizeColumnsToFit();
  }

  userDisplayName = (userID: string) => {
    let thisUser = this.state.shopUsers.filter(
      (user: any) => user.userID === userID
    );
    if (thisUser?.length) {
      return thisUser[0].firstName + ' ' + thisUser[0].lastName;
    } else {
      return userID === 'LINK'
        ? `<${TextOnly('signupLink')}>`
        : `<${TextOnly('unknown')}>`;
    }
  };

  // For alerts only
  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  // Handler to cancel all other modals
  handleCancelModal = () => {
    const self = this;
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showConfirmModal: false,
        showInfoModal: false,
        showAddUserModal: false,
        showBulkAddUsersModal: false,
        showSignupLinksModal: false,
        showDirectoryServicesModal: false,
        userToDelete: undefined,
      });
    }, 350);
  };

  handleShowAddUser = () => {
    this.setState({ showAddUserModal: true });
  };

  handleShowBulkAddUsers = () => {
    this.setState({ showBulkAddUsersModal: true });
  };

  handleShowSignupLinks = () => {
    this.setState({ showSignupLinksModal: true });
  };

  handleShowDirectoryServices = () => {
    this.setState({ showDirectoryServicesModal: true });
  };

  downloadCSV = () => {
    let shopUsersCSV = 'Username,First Name,Last Name,email,role\n';
    this?.props?.currentShop?.shopUsers?.forEach((user: User) => {
      shopUsersCSV += `${user.userName},${user.firstName},${user.lastName},${user.email ?? 'N/A'},${user.shopUserRole}\n`;
    });

    const blob = new Blob([shopUsersCSV], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    link.setAttribute('download',`AutoAuthUserExport_${year}-${month}-${day}.csv`);
    link.click();
    toast.success(TextOnly('usersCSVExported'), {
      containerId: 'standard',
    });
  };

  render() {
    const currentUser = this.props.user.userID;

    const savedColumnSizes = getSavedColumns('manageUsersColumns');
    const savedUserInfoColumnSizes = getSavedColumns(
      'manageUsersUserInfoColumns'
    );

    const isEnterprise =
      this.props.currentShop.shopType === SHOP_TYPES.ENTERPRISE;
    const isPlus = this.props.currentShop.shopType === SHOP_TYPES.PLUS;

    const windowWidth = this.state.windowWidth;

    const columnDefs: Column[] = [];

    if (this.state.windowWidth > 768) {
      columnDefs.push({
        Header: <Text tid="username" />,
        accessor: 'username',
        id: 'username',
        minWidth: savedColumnSizes.username || 150,
      });
    }
    let nameCol: Column = {
      Header: <Text tid="name" />,
      accessor: 'name',
      className: 'cell-wrap',
      minWidth: savedColumnSizes.name || 250,
      Cell: (row: any) => {
        return (
          <div className="flex-row align-items-center l-flex-gap-small">
            <TechAvatar tech={row.original} />
            <span>{row.original.name}</span>
          </div>
        )
      }
    };
    if (windowWidth < 1200) {
      nameCol.maxWidth = 100;
    }
    columnDefs.push(nameCol);

    if (this.state.windowWidth > 450) {
      columnDefs.push({
        Header: <Text tid="role" />,
        id: 'role',
        accessor: (u: User) => {
          return <Text tid={u.role} />;
        },
        minWidth: savedColumnSizes.role || 90,
      });
    }

    let stateCol: Column = {
      Header: <Text tid="state" />,
      id: 'state',
      accessor: (u: { state: LangDictKey }) => {
        return <Text tid={u.state} />;
      },
      minWidth: savedColumnSizes.state || 80,
    };
    if (windowWidth < 1200) {
      stateCol.maxWidth = 80;
    }
    columnDefs.push(stateCol);

    if (windowWidth > 1200) {
      if (isEnterprise || isPlus) {
        columnDefs.push({
          Header: <Text tid="addedOn" />,
          id: 'addedOn',
          accessor: 'addedOn',
          sortMethod: (
            a: string | number | Date,
            b: string | number | Date,
            desc: boolean
          ) => {
            const externalAStr = new Date(a);
            const externalBStr = new Date(b);
            if (desc) {
              if (externalAStr > externalBStr) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (externalAStr < externalBStr) {
                return -1;
              } else {
                return 1;
              }
            }
          },
          Cell: (row) => {
            const dateObj = formatDateTime2Lines(row.original.addedOn);
            return (
              <div>
                {dateObj.date}
                <br />
                {dateObj.time}
              </div>
            );
          },
          minWidth: savedColumnSizes.addedOn || 200,
        });
        columnDefs.push({
          Header: <Text tid="addedBy" />,
          id: 'addedBy',
          accessor: (u: User) => {
            return this.userDisplayName(u.addedBy);
          },
          minWidth: savedColumnSizes.addedBy || 150,
        });
        if (isEnterprise) {
          columnDefs.push({
            Header: <Text tid="additionalFields" />,
            id: 'additionalIDFields',
            accessor: (u: User) => {
              if (u.additionalIDFields) {
                let keys = Object.keys(u.additionalIDFields);
                return (
                  <div>
                    {keys.map((key) => {
                      return (
                        <div key={key}>
                          <span>{key}:</span> {u.additionalIDFields?.[key]}
                        </div>
                      );
                    })}
                  </div>
                );
              } else {
                return '';
              }
            },
            minWidth: savedColumnSizes.additionalIDFields || 150,
          });
        }
      }
    }

    let actionsCol: Column = {
      Header: <Text tid="actions" />,
      accessor: 'userID',
      minWidth: savedColumnSizes.userID || 140,
      Cell: (row: { original: User }) => {
        let dropdownItems = [];
        if (isEnterprise) {
          if (row.original.role !== 'ADMIN') {
            dropdownItems.push({
              key: TextOnly('changeRoleToAdmin'),
              text: TextOnly('changeToAdmin'),
              value: TextOnly('ADMIN'),
            });
          }
          if (row.original.role !== SHOP_ROLES.TECH) {
            dropdownItems.push({
              key: TextOnly('changeRoleToUser'),
              text: TextOnly('changeToUser'),
              value: TextOnly('USER'),
            });
          }
        }
        return (
          <div className="l-inline-flex">
            {currentUser !== row.original.userID &&
            row.original.role !== SHOP_ROLES.OWNER ? ( // Not current user
              <button
                className="c-btn-icon"
                onClick={this.handleDeleteButton.bind(this, row.original)}
              >
                <div className="c-btn__inner">
                  <span
                    className="c-btn__icon fal fa-trash-alt"
                    title={TextOnly('removeFromShop')}
                  />
                </div>
              </button>
            ) : null}
            {currentUser !== row.original.userID &&
            row.original.role !== SHOP_ROLES.OWNER ? (
              row.original.state === SHOP_STATES.ACTIVE ? ( // User Active
                <button
                  className="c-btn-icon"
                  onClick={() =>
                    this.handleChangeState(
                      row.original.userID,
                      SHOP_STATES.INACTIVE as ShopState,
                      row.original.role as ShopRole
                    )
                  }
                >
                  <div className="c-btn__inner">
                    <span
                      className="c-btn__icon fal fa-ban "
                      title={TextOnly('suspendUser')}
                    />
                  </div>
                </button>
              ) : row.original.state === SHOP_STATES.INACTIVE ? ( // User Not Active
                <button
                  className="c-btn-icon"
                  onClick={() =>
                    this.handleChangeState(
                      row.original.userID,
                      SHOP_STATES.ACTIVE as ShopState,
                      row.original.role as ShopRole
                    )
                  }
                >
                  <div className="c-btn__inner">
                    <span
                      className="c-btn__icon fal fa-check-circle "
                      title={TextOnly('reactivateUser')}
                    />
                  </div>
                </button>
              ) : null
            ) : null}
            {isEnterprise &&
            currentUser !== row.original.userID &&
            row.original.role !== SHOP_ROLES.OWNER &&
            row.original.state === SHOP_STATES.ACTIVE ? (
              row.original.role === SHOP_ROLES.TECH ? (
                <button
                  className="c-btn-icon"
                  onClick={() =>
                    this.handleChangeState(
                      row.original.userID,
                      row.original.state as ShopState,
                      SHOP_ROLES.ADMIN as ShopRole
                    )
                  }
                >
                  <div className="c-btn__inner">
                    <span
                      className="c-btn__icon fal fa-user-cog "
                      title={TextOnly('changeRoleToAdmin')}
                    />
                  </div>
                </button>
              ) : (
                <button
                  className="c-btn-icon"
                  onClick={() =>
                    this.handleChangeState(
                      row.original.userID,
                      row.original.state,
                      SHOP_ROLES.TECH as ShopRole
                    )
                  }
                >
                  <div className="c-btn__inner">
                    <span
                      className="c-btn__icon fal fa-user-cog "
                      title={TextOnly('changeRoleToUser')}
                    />
                  </div>
                </button>
              )
            ) : null}
            {(isEnterprise || isPlus) &&
            row.original.state === SHOP_STATES.ACTIVE ? (
              this.state.loadingActions !== row.original.userID ? (
                <button
                  className="c-btn-icon"
                  onClick={this.handleInfoButton.bind(this, row.original)}
                >
                  <div className="c-btn__inner">
                    <span
                      className="c-btn__icon fal fa-info-circle "
                      title={TextOnly('viewUserActions')}
                    />
                  </div>
                </button>
              ) : (
                <button className="c-btn-icon">
                  <div className="c-btn__inner">
                    <i
                      className="c-btn__icon fal fa-spinner-third spinning"
                      title={TextOnly('loading')}
                    />
                  </div>
                </button>
              )
            ) : null}
          </div>
        );
      },
      width: 180,
      // @ts-ignore
      suppressSorting: true,
      suppressFilter: true,
      cellRendererParams: {
        shopID: this.state.shopID,
        currentUser: this.props.user.userID,
        handleRemoveUser: (userID: string) => this.handleRemoveUser(userID),
        handleChangeShopUserState: (userID: string, newState: ShopState) =>
          // missing argument
          // @ts-ignore
          this.handleChangeShopUserState(userID, newState),
      },
    };
    if (windowWidth < 1200) {
      actionsCol.maxWidth = 300;
    }
    columnDefs.push(actionsCol);

    let userActionsColumnDefs = [];

    userActionsColumnDefs.push({
      Header: <Text tid="actionCode" />,
      accessor: 'actionCode',
      minWidth: savedUserInfoColumnSizes.actionCode || 200,
    });
    userActionsColumnDefs.push({
      Header: <Text tid="description" />,
      accessor: 'actionMessage',
      minWidth: savedUserInfoColumnSizes.actionMessage || 200,
    });
    userActionsColumnDefs.push({
      Header: <Text tid="date" />,
      id: 'actionDate',
      accessor: 'actionDate',
      headerClassName: 'hide-second',
      className: 'hide-second',
      sortMethod: (
        a: string | number | Date,
        b: string | number | Date,
        desc: boolean
      ) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row: any) => {
        return formatDateTime(row.original.actionDate)
      },
      minWidth: savedUserInfoColumnSizes.actionDate || 200,
    });

    let shopUsers = this.state.shopUsersFiltered
      ? this.state.shopUsersFiltered.map((user: User) => {
          return {
            userID: user.userID,
            username: user.userName,
            name: user.firstName + ' ' + user.lastName,
            role: user.shopUserRole,
            state: user.shopUserState,
            addedOn: user.addedOn,
            addedBy: user.addedBy,
            email: user.email,
            additionalIDFields: user.additionalIDFields,
            avatarColor: user.avatarColor,
          };
        })
      : [];

    let userActions = this.state.userActionsFiltered
      ? this.state.userActionsFiltered
      : this.state.userActions;

    let numOfUsersStr = this.state.shopUsers
      ? TextOnly('usersOfMaxShopUsers', {
          num: (this.state.shopUsers.length).toString(),
          max: (this.props.currentShop.shopMaxUsers).toString()
      })
      : '';

    const expandRows = [
      'UPDATE_SHOP_INFO',
      'UPDATE_SHOP_MAX_USERS',
      'ADD_USER_TO_SHOP',
      'RESPOND_TO_SHOP_INVITE',
      'SET_USER_STATE_AT_SHOP',
      'REGISTER_TOOL_FOR_SHOP',
      'REMOVE_TOOL_FROM_SHOP',
      'REINSTATE_SHOP_OEM_REGIONS',
      'UPDATE_SHOP_MAX_TECH_CERTS',
      'EDIT_TOOL_FOR_SHOP',
      'INITIATE_TOOL_RECOVERY',
      'REMOVE_SHOP_OEM_REGIONS'
    ];

    const containsExpandFields = (row: any) => {
      const nonExpandFields = [
        'shopID',
        'userID',
        'actionDate',
        'actionCode',
        'actionMessage',
        'shopRole',
        'apiErrorMessage',
        'isError',
        'name',
      ];
      for (const field in row) {
        if (!nonExpandFields.includes(field) && row[field]) return true;
      }
      return false;
    }

    if (this.state.isLoadingUsers) return <Loading />;

    return (
      <>
        { this.props.currentShop.shopType === 'STANDARD' &&
         (this.state.shopUsers.length >= this.props.currentShop.shopUserCap) &&
          <div className='temp-banner awsWarning'>
            <Text tid='maximumShopUsersReached'/>{' '}
            <Text
              tid="upgradeToPlusToIncreaseCapacity"
              sub={{
                upgradePlus: (
                  <a
                    href="/upgradeToPlus/comparePlans"
                    rel="noopener noreferrer"
                  >
                    {TextOnly('upgradeToStandardPlus')}
                  </a>
                ),
              }}
            />
          </div>
        }
        <Header
          context={TextOnly('manageUsers')}
          title={TextOnly('currentUsers')}
        />

        <div className="l-container">
          {this.state.shopUsers ? (
            <>
              <div className="l-flex-wrap flex-flow-column">
                <div className="l-flex-wrap flex-wrap-wrap-reverse">
                  <div className='display-flex'>
                    <div className="u-margin-right u-margin-top-small">
                      <button className="c-btn" onClick={this.handleShowAddUser}>
                        <div className="c-btn__inner">
                          <i className="c-btn__icon fal fa-plus" />
                          <Text tid="addUsers" />
                        </div>
                      </button>
                    </div>
                    {isEnterprise ? (
                      <>
                        <div className="u-margin-right">
                          <button
                            className="c-btn u-margin-top-small"
                            onClick={this.handleShowSignupLinks}
                          >
                            <div className="c-btn__inner">
                              <i className="c-btn__icon fal fa-link" />
                              <Text tid="signupLinks" />
                            </div>
                          </button>
                        </div>
                        <div className="u-margin-right">
                          <button
                            className="c-btn u-margin-top-small"
                            onClick={this.handleShowDirectoryServices}
                          >
                            <div className="c-btn__inner">
                              <i className="c-btn__icon fal fa-address-book" />
                              <Text tid="directoryServices" />
                            </div>
                          </button>
                        </div>
                      </>
                    ) : null}
                    {(isEnterprise || isPlus) &&
                      <div>
                        <button
                          className="c-btn u-margin-top-small"
                          onClick={this.downloadCSV}
                        >
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fal fa-user" />
                            <Text tid="shopUsersCSV" />
                          </div>
                        </button>
                      </div>
                    }
                  </div>
                  <div className="u-text-center l-flex-align-center flex-wrap-wrap-reverse u-margin-top-small white-space-nowrap">
                    <div className='u-margin-right-large'>
                      {numOfUsersStr}
                    </div>
                    {(this.props.currentShop.shopType !== 'ENTERPRISE') && this.props.currentShop.shopMaxUsers < this.props.currentShop.shopUserCap ? (
                      <Link
                        to={{
                          pathname: '/shopProfile',
                          state: {
                            tab: 'shopInfo',
                          },
                        }}
                      >
                        <button className='c-btn white-space-nowrap'>
                          {TextOnly('upgradeCapacity')}
                        </button>
                      </Link>
                    ) : <>{/*<h3>{TextOnly('maximumShopUsersReached')}</h3>*/}</>}
                  </div>
                </div>
                {(isEnterprise || isPlus) &&
                  <div className="c-field u-margin-top-small margin-bottom-0 u-margin-right-small max-width-fit-content">
                    <label
                      htmlFor="filter"
                      className="c-field__label u-is-vishidden"
                    >
                      <Text tid="filter" />
                    </label>
                    <input
                      type="text"
                      id="filter"
                      maxLength={50}
                      className="c-input"
                      placeholder={TextOnly('filter')}
                      value={this.state.filter}
                      onChange={this.handleChange}
                    />
                    <i className="c-field__input-icon fal fa-search" />
                  </div>
                }
              </div>

              <div className="u-margin-top-xlarge">
                <ReactTable
                  columns={columnDefs}
                  data={shopUsers}
                  className="-highlight"
                  showPaginationTop={true}
                  previousText={TextOnly('previousPage')}
                  nextText={TextOnly('nextPage')}
                  pageText={TextOnly('page')}
                  ofText={TextOnly('of')}
                  rowsText={TextOnly('rows')}
                  noDataText={TextOnly('noUsersAssociated')}
                  defaultPageSize={10}
                  // @ts-ignore
                  SubComponent={
                    windowWidth < 1200
                      ? (row) => {
                          return (
                            <div>
                              <div>
                                <span>
                                  <Text tid="name" />:
                                </span>{' '}
                                {row.original.name}
                              </div>
                              <div>
                                <span>
                                  <Text tid="username" />:
                                </span>{' '}
                                {row.original.username}
                              </div>
                              <div>
                                <span>
                                  <Text tid="role" />:
                                </span>{' '}
                                {<Text tid={row.original.role} />}
                              </div>
                              {isEnterprise || isPlus ? (
                                <div>
                                  <span>
                                    <Text tid="addedOn" />:
                                  </span>{' '}
                                  {formatDateTime(row.original.addedOn, {
                                    windowWidth: windowWidth,
                                  })}
                                </div>
                              ) : null}
                              {isEnterprise || isPlus ? (
                                <div>
                                  <span>
                                    <Text tid="addedBy" />:
                                  </span>{' '}
                                  {this.userDisplayName(row.original.addedBy)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
                      : null
                  }
                  defaultSorted={[
                    {
                      id: 'username',
                      desc: false,
                    },
                  ]}
                  onResizedChange={(a) =>
                    localStorage.setItem(
                      'manageUsersColumns',
                      JSON.stringify(a)
                    )
                  }
                />
              </div>
            </>
          ) : (
            <i
              className="fal fa-spinner-third spinning c-btn-icon"
              title={TextOnly('loading')}
            />
          )}
          <Dialog
            isOpen={this.state.showInfoModal}
            onDismiss={this.handleCancelModal}
            className="c-modal-slider"
            aria-label={TextOnly('userInformation')}
          >
            <button
              className="c-btn-icon c-modal-slider__close"
              onClick={this.handleCancelModal}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <h1 className="c-modal__heading">
              <Text tid="userInformation" />
            </h1>
            <div className="c-modal__body">
              {(isEnterprise || isPlus) &&
              (this.state.activeKey === 5 ||
                this.state.userActions.length > 0) ? (
                <div className="u-margin-top-small">
                  <div className="c-field">
                    <label className="c-field__label u-margin-bottom-small">
                      <Text tid="actionsPerformedBy" />{' '}
                      {this.state.actionUser?.name} <Text tid="from" />:
                    </label>
                    <CustomDatePicker
                      value={{
                        start: this.state.startDate,
                        end: this.state.endDate,
                        name: 'Last 30 Days',
                      }}
                      onChange={this.handleRangeChange.bind(this)}
                    />
                  </div>
                  <div className="c-field">
                    <label htmlFor="filterActions" className="c-field__label">
                      <Text tid="filter" />
                    </label>
                    <input
                      maxLength={50}
                      placeholder={TextOnly('filter')}
                      id="filterActions"
                      type="text"
                      value={this.state.filterActions}
                      onChange={this.handleChange}
                    />
                    <i className="c-field__input-icon fal fa-search" />
                  </div>
                  <ReactTable
                    columns={[
                      {
                        expander: true,
                        Header: '',
                        accessor: 'expander',
                        Expander: ({ isExpanded, ...row }) => {
                          if (expandRows.includes(row.original.actionCode)
                              && containsExpandFields(row.original)) {
                            return (
                              <div>
                                <i
                                  className={`fa fa-chevron-right ${
                                    isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </div>
                            );
                          } else {
                            return (
                              <div className="u-cursor-none"></div>
                            );
                          }
                        },
                      },
                      ...userActionsColumnDefs
                    ]}
                    data={userActions}
                    className="-highlight"
                    showPaginationTop={true}
                    previousText={TextOnly('previousPage')}
                    nextText={TextOnly('nextPage')}
                    pageText={TextOnly('page')}
                    ofText={TextOnly('of')}
                    rowsText={TextOnly('rows')}
                    noDataText={TextOnly('noUserActions')}
                    defaultPageSize={10}
                    defaultSorted={[
                      {
                        id: 'actionDate',
                        desc: true,
                      },
                    ]}
                    onResizedChange={(a) =>
                      localStorage.setItem(
                        'manageUsersUserInfoColumns',
                        JSON.stringify(a)
                      )
                    }
                    SubComponent={row => {
                      if (expandRows.includes(row.original.actionCode)
                          && containsExpandFields(row.original)) {
                        return (
                          <LogSubComponent
                            oemRegions={this.state.oemRegions}
                            row={row}
                            shop={this.props.currentShop}
                            currentUser={this.props.user}
                          />
                        );
                      } else {
                        return null;
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Dialog>
        </div>

        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          handleCancel={this.handleCancel}
        />

        {this.state.showAddUserModal && (
          <AddUser
            user={this.props.user}
            currentShop={this.props.currentShop}
            shopUsers={this.state.shopUsers}
            windowWidth={this.state.windowWidth}
            baseURL={this.state.baseURL}
            isEnterprise={isEnterprise}
            onDismiss={this.handleCancelModal}
            handleShowAlert={this.handleShowAlert}
            handleAddUser={this.handleAddUser}
            handleBulkAddUsers={this.handleBulkAddUsers}
          />
        )}

        {this.state.showSignupLinksModal && (
          <SignupLinks
            {...this.props}
            onDismiss={this.handleCancelModal}
            handleShowAlert={this.handleShowAlert}
          />
        )}

        {this.state.showDirectoryServicesModal && (
          <DirectoryServices
            {...this.props}
            onDismiss={this.handleCancelModal}
            handleShowAlert={this.handleShowAlert}
          />
        )}

        <Dialog
          isOpen={this.state.showConfirmModal}
          onDismiss={this.handleCancelModal}
          className="c-modal-slider"
          aria-label={TextOnly('confirmRemoveUserShop')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmRemoveUserShop" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureRemove" />
            </p>
            <LoaderButton
              isLoading={this.state.isRemoveUserLoading}
              text={TextOnly('confirm')}
              loadingText={TextOnly('removing')}
              onClick={this.handleConfirmModal.bind(this)}
            />{' '}
            <button className="c-btn-outline" onClick={this.handleCancelModal}>
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>
      </>
    );
  }
}
