import React, { ButtonHTMLAttributes } from 'react';

type LoaderButtonProps = {
  text: string | React.JSX.Element;
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
  disabled?: boolean;
  icon?: JSX.Element;
};

const LoaderButton: React.FC<
  LoaderButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  text,
  isLoading = false,
  loadingText = '',
  className = '',
  disabled = false,
  icon = null,
  ...props
}) => (
  <button
    className={`c-btn ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    <div className="c-btn__inner">
      {isLoading && (
        <i
          className="fal fa-spinner-third spinning u-margin-right-small"
          title={loadingText}
        />
      )}
      {!isLoading && icon}
      {!isLoading ? text : loadingText}
    </div>
  </button>
);

export default LoaderButton;
