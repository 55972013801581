// This file has all constants related to CRM features

import { TextOnly } from "../../components/Text";

export const ORDER_STATUS_OPTIONS = [
  {
    value: 'initiated',
    label: TextOnly('initiated'),
  },
  {
    value: 'droppedOff',
    label: TextOnly('droppedOff'),
  },
  {
    value: 'inProgress',
    label: TextOnly('inProgress'),
  },
  {
    value: 'invoiced',
    label: TextOnly('invoiced'),
  },
  {
    value: 'readyForPickup',
    label: TextOnly('readyForPickup'),
  },
  {
    value: 'paid',
    label: TextOnly('paid'),
  },
  {
    value: 'canceled',
    label: TextOnly('canceled'),
  },
];

export const ORDER_STATUS_OPTIONS_OBJ: {[key: string]: string} = {
  initiated: TextOnly('initiated'),
  droppedOff: TextOnly('droppedOff'),
  inProgress: TextOnly('inProgress'),
  invoiced: TextOnly('invoiced'),
  readyForPickup: TextOnly('readyForPickup'),
  paid: TextOnly('paid'),
  canceled: TextOnly('canceled'),
};

export const CONTACT_PREFERENCE: {[key: string]: string} = {
  NONE: TextOnly('None'),
  PHONE: TextOnly('phoneNumber'),
  EMAIL: TextOnly('email'),
  BOTH: TextOnly('both'),
};


export const EVENT_TYPE = {
  APPOINTMENT: 'APPOINTMENT',
  VACATION: 'VACATION',
  SICK: 'SICK',
  HOLIDAY: 'HOLIDAY',
  OTHER: 'OTHER',
}

// Time off choices
export const EVENT_TIMEOFF_TYPE_OBJ = [
  {
    value: 'VACATION',
    label: TextOnly('vacation'),
  },
  {
    value: 'SICK',
    label: TextOnly('sick'),
  },
  {
    value: 'HOLIDAY',
    label: TextOnly('holiday'),
  },
  {
    value: 'OTHER',
    label: TextOnly('other'),
  },
];

export const HIGHLIGHTER_OPTIONS = [
  {
    value: 'BLUE',
    label: TextOnly('blue'),
  },
  {
    value: "GREEN",
    label: TextOnly('green'),
  },
  {
    value: "PURPLE",
    label: TextOnly('purple'),
  },
  {
    value: "ORANGE",
    label: TextOnly('orange'),
  },
  {
    value: "RED",
    label: TextOnly('red'),
  },
  {
    value: "GRAY",
    label: TextOnly('gray'),
  },
  {
    value: "TEAL",
    label: TextOnly('teal'),
  },
  {
    value: "SALMON",
    label: TextOnly('salmon'),
  },
  {
    value: "GOLD",
    label: TextOnly('gold'),
  },
];

export const HIGHLIGHTER_COLORS_OBJ: any = {
  BLUE: 'highlighter-color-BLUE',
  GREEN: 'highlighter-color-GREEN',
  PURPLE: 'highlighter-color-PURPLE',
  ORANGE: 'highlighter-color-ORANGE',
  RED: 'highlighter-color-RED',
  GRAY: 'highlighter-color-GRAY',
  SALMON: 'highlighter-color-SALMON',
  TEAL: 'highlighter-color-TEAL',
  GOLD: 'highlighter-color-GOLD',
}

//TODO Delete this once order is completed
export const EVENT_TYPES = {
  CUSTOMER_EVENT: 'CUSTOMER_EVENT',
  TECHNICIAN_EVENT: 'TECHNICIAN_EVENT',
}