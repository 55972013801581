import React, { useEffect, useRef, useState } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { validateVIN } from '../../../libs/utils';
import { checkAutoVin, crmCreateShopVehicle, crmUpdateShopVehicle } from '../../../libs/db-lib';
import { Loading } from '../../../components/Loading';
import { useScreenSize } from '../../../context/ScreenSize';
import { Typeahead } from 'react-bootstrap-typeahead';
import AddEditCustomerForm from '../ManageCustomers/AddEditCustomerForm';


const AddEditVehicleForm: React.FC<any> = (props) => {

  const {
    currentShop,
    handleCloseModal,
    isLoading,
    setIsLoading,
    vehicle,
    customer,
    updateSingleCustomer,
    setNewVehicleInfo,
    onSubmit,
    shopCustomers,
    triggerClearVehicleForm,
    embeddedForm,
  } = props;

  const customerTypeaheadRef = useRef<any>();

  const customersList = shopCustomers?.map((cust: any) => {
    const currentCust = {
      id: cust.customerID,
      label: `${cust.firstName} ${cust.lastName}`,
      vehicles: cust?.vehicles || []
    }
    return currentCust;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    // sorting alphabetical order by first name
    return (
      labelA < labelB ? -1
      : labelA > labelB ? 1
      : 0
    );
  });
  customersList?.unshift({
    id: 'newCustomer',
    label: `+ ${TextOnly('createNewCustomer')}`
  });

  const { windowWidth, isMobile } = useScreenSize();
  const isSmallScreen = isMobile || (windowWidth <= 660);

  const [vehicleVin, setVehicleVin] = useState<string>(vehicle?.vin ?? '');
  const [vehicleOdometer, setVehicleOdometer] = useState<string>(vehicle?.odometer ??'');
  const [showVinInput, setShowVinInput] = useState<boolean>(false);
  const [vehicleVinVerified, setVehicleVinVerified] = useState<boolean>(false);
  const [vinVerificationFailed, setVinVerificationFailed] = useState<boolean>(false);
  const [vehicleMake, setVehicleMake] = useState<string>(vehicle?.make ?? '');
  const [vehicleModel, setVehicleModel] = useState<string>(vehicle?.model ?? '');
  const [vehicleYear, setVehicleYear] = useState<string>(vehicle?.year ?? '');
  const [loadingInfoVin, setLoadingInfoVin] = useState<boolean>(false);
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState<string>(vehicle?.licensePlate ?? '');
  const [vehicleColor, setVehicleColor] = useState<string>(vehicle?.color ?? '');

  const [isCustTypeaheadOpen, setIsCustTypeaheadOpen] = useState<boolean>(false);
  const [customerInputValue, setCustomerInputValue] = useState<string>('');
  const [selectedCustomer, setSelectedCustomer] = useState<any[]>(
    customer ? [{
      id: customer.customerID,
      label: `${customer.firstName} ${customer.lastName}`,
      vehicles: customer.vehicles || []
    }]
    : vehicle ? [customersList?.find((cust: any) => cust.id === vehicle.customerID)]
    : []
  );
  const [newCustomerInfo, setNewCustomerInfo] = useState<any>(null);

  useEffect(() => {
    if (setNewVehicleInfo) {
      setNewVehicleInfo({
        vin: vehicleVin,
        odometer: vehicleOdometer,
        make: vehicleMake,
        model: vehicleModel,
        year: vehicleYear,
        licensePlate: vehicleLicensePlate,
        color: vehicleColor,
        isValidated: validateSubmit()
      })
    }
  }, [
    vehicleVin,
    vehicleOdometer,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleLicensePlate,
    vehicleColor
  ]);

  const clearForm = () => {
    setVehicleVin('')
    setVehicleOdometer('')
    setShowVinInput(false)
    setVehicleVinVerified(false)
    setVinVerificationFailed(false)
    setVehicleMake('')
    setVehicleModel('')
    setVehicleYear('')
    setLoadingInfoVin(false)
    setVehicleLicensePlate('')
    setVehicleColor('')
  };

  useEffect(()=>{
    if (triggerClearVehicleForm){
      clearForm();
    }
  }, [triggerClearVehicleForm]);

  const validateSubmit = () => {
    let hasRequiredCustomerFields;
    // We only need customer info if we're creating a new vehicle from the Manage Vehicles page.
    if (!vehicle && shopCustomers) {
      hasRequiredCustomerFields = selectedCustomer[0]?.id === 'newCustomer' ? newCustomerInfo?.isValidated
      : !!selectedCustomer[0]?.id;
    } else {
      hasRequiredCustomerFields = true;
    }

    // Making sure all values are valid
    if (
      hasRequiredCustomerFields &&
      !!vehicleMake &&
      !!vehicleModel &&
      !!vehicleYear &&
      validateVehicleYear()
    ) {
      //It's create view
      if (!vehicle) return true;
      //It's update view
      else {
        // Checking if at least one value changed
        if (
          vehicleVin === vehicle.vin &&
          vehicleOdometer === vehicle.odometer &&
          vehicleMake === vehicle.make &&
          vehicleModel === vehicle.model &&
          vehicleYear === vehicle.year &&
          vehicleLicensePlate === vehicle.licensePlate &&
          vehicleColor === vehicle.color
        ) return false;
        else return true;
      }
    } else {
      //At least one value is not valid
      return false;
    }
  };

  const validateVehicleYear = () => {
    const numberVehicleYear = Number(vehicleYear)
    return (
      !vehicleYear.includes('.') &&
      !isNaN(numberVehicleYear) &&
      (numberVehicleYear > 1886) &&
      (numberVehicleYear < 2100)
    )
  }

  const handleChangeVinInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleVinVerified(false);
    setVinVerificationFailed(false);
    setVehicleMake('');
    setVehicleModel('');
    setVehicleYear('');
    setVehicleVin(event.target.value.toUpperCase());
  }

  const handleGetVinInfo = async () => {
    setLoadingInfoVin(true);
    const response = await checkAutoVin(vehicleVin);
    if(!response.error) {
      setVehicleMake(response.make);
      setVehicleModel(response.model);
      setVehicleYear(response.year);
      setVehicleVinVerified(true);
      setVinVerificationFailed(false);
    } else {
      setVehicleMake('');
      setVehicleModel('');
      setVehicleYear('');
      setVehicleVinVerified(false);
      setVinVerificationFailed(true);

      if (response.error.includes('Invalid VIN')) {
        toast.error(`${TextOnly('invalidVin', {vin: vehicleVin})}`, {
          containerId: 'standard',
        });
      } else {
        toast.error(response.error, {
          containerId: 'standard',
        });
      }
    }
    setLoadingInfoVin(false);
  }

  const handleSubmitVehicle = async () => {
    setIsLoading(true);
    const response =  !vehicle ?
      await crmCreateShopVehicle(
        currentShop.shopID,
        customer ? { customerID: customer.customerID }
          : newCustomerInfo ?? { customerID: selectedCustomer[0].id },
        vehicleVin,
        vehicleMake,
        vehicleModel,
        vehicleYear,
        vehicleColor,
        vehicleOdometer,
        vehicleLicensePlate
      ) :
      await crmUpdateShopVehicle(
        currentShop.shopID,
        vehicle?.vehicleID,
        vehicleVin,
        vehicleMake,
        vehicleModel,
        vehicleYear,
        vehicleColor,
        vehicleOdometer,
        vehicleLicensePlate
      );
    if (!response.error) {
      toast.success(TextOnly(!vehicle ? 'newVehicleCreated' : 'vehicleUpdated'), { containerId: 'standard' });
      handleCloseModal();
      if (updateSingleCustomer) {
        updateSingleCustomer(customer, response, (!vehicle ? 'ADD' : 'UPDATE'));
      }
      if (onSubmit) {
        onSubmit();
      }
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
    setIsLoading(false);
  }

  return (
    <div className={embeddedForm ? 'u-width-100-percent' : ''}>
      {!embeddedForm &&
        <div className={setNewVehicleInfo ? 'hide-element' : 'h1 c-modal__heading'}>
          {vehicle ? TextOnly('editVehicle') : TextOnly('addVehicle')}{customer ? ` - ${customer.firstName} ${customer.lastName}` : ''}
        </div>
      }
      <div className={!isSmallScreen && shopCustomers ? `${!vehicle && 'flex-row flex-space-between l-flex-gap'} l-container-med` : ''}>
        {/* CUSTOMER INFO */}
        {shopCustomers && (
          <div className={!isSmallScreen ? `u-width-50-percent` : 'l-container-sm'}>
            {!vehicle && (
              <div className='h2'>
                <Text tid="customerInfo" />
              </div>
            )}
            <div className={`c-field`}>
              <label className={`c-field__label`} htmlFor="customer">
                <span className="u-text-error">*</span> <Text tid="customer" />:
              </label>
              <Typeahead
                ref={customerTypeaheadRef}
                labelKey={'label'}
                onInputChange={(newInput)=>{
                  setCustomerInputValue(newInput);
                }}
                id={'Shop-Customer'}
                options={customersList ?? []}
                onChange={(shopCustomer:any) => {
                  setSelectedCustomer(shopCustomer);
                  if (shopCustomer[0]) {
                    setIsCustTypeaheadOpen(false);
                    setCustomerInputValue('');
                    if (shopCustomer[0]?.id !== 'newCustomer') {
                      setNewCustomerInfo(null);
                    }
                  }
                }}
                disabled={isLoading || (shopCustomers && vehicle)}
                placeholder={TextOnly('customer')}
                selected={selectedCustomer}
                filterBy={(option: any) => {
                  if (option?.label?.toLowerCase().includes(customerInputValue?.toLowerCase())) {
                    return true;
                  }
                  return option?.label?.includes(TextOnly('createNewCustomer'));
                }}
                renderMenuItemChildren={(option: any, props: any) => {
                  return (
                    <div className="typeahead-menu-item-children">
                      {option.label}
                    </div>
                  )
                }}
                open={isCustTypeaheadOpen}
                onBlur={()=>{
                  setIsCustTypeaheadOpen(false);
                  if (!selectedCustomer[0]) {
                    setCustomerInputValue('');
                    customerTypeaheadRef.current.clear();
                  }
                }}
                onFocus={()=>setIsCustTypeaheadOpen(true)}
              />
              {selectedCustomer[0]?.id === 'newCustomer' && (
                <AddEditCustomerForm
                  currentShop={currentShop}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  setNewCustomerInfo={setNewCustomerInfo}
                />
              )}
            </div>
          </div>
        )}

        {/* VEHICLE INFO */}
        <div className={isSmallScreen ? 'l-container-sm' : `${customer && 'l-container-med'} ${shopCustomers && (vehicle ? 'l-container-med' : 'u-width-50-percent')}`}>
          {shopCustomers && (
            <div className="h2">{TextOnly('vehicleInformation')}</div>
          )}
          <div className={!isSmallScreen && (vehicle || customer) ? 'flex-row flex-space-between l-flex-gap' : ''}>
            <div className={`c-box column-flow-centered ${setNewVehicleInfo && 'u-margin-top'} ${!isSmallScreen && (vehicle || customer) && 'u-width-50-percent'}`}>
              <div className='u-width-100-percent'>
                <div className={`c-field`}>
                  <div className={"l-inline-flex"}>
                    <input
                      id="vin"
                      type="radio"
                      name="vehicleFields"
                      value="vin"
                      checked={showVinInput}
                      onChange={()=>setShowVinInput(true)}
                      className="u-margin-right-large"
                      disabled={isLoading}
                    />
                    <label
                      htmlFor='vin'
                      className="c-field__label left-padding u-padding-bottom-none u-margin-right-large"
                    >
                      <Text tid="vin" />
                    </label>
                  </div>
                  <div className={"l-inline-flex"}>
                    <input
                      id="yearMakeModel"
                      type="radio"
                      name="vehicleFields"
                      value="yearMakeModel"
                      checked={!showVinInput}
                      onChange={()=>setShowVinInput(false)}
                      className="u-margin-right-large"
                      disabled={isLoading}
                    />
                    <label
                      htmlFor='yearMakeModel'
                      className="c-field__label left-padding u-padding-bottom-none"
                    >
                      <Text tid="yearMakeModel" />
                    </label>
                  </div>
                </div>

                {showVinInput ? (
                  <div className={`c-field`}>
                    <div>
                      <label className={`c-field__label`} htmlFor='vin'>
                        <span className="u-text-error">*</span> <Text tid="vin" />:
                      </label>
                      { vinVerificationFailed &&
                        <label className={`c-field__label u-text-error`} htmlFor='vin'>
                          <Text tid="invalidVin" sub={{vin: vehicleVin as any}} />
                        </label>
                      }
                      <div className='display-flex'>
                        <input
                          type="text"
                          id="vin"
                          className={`c-input${ (vehicleVin.length > 0 && !validateVIN(vehicleVin) || vinVerificationFailed)  ? '__error' : ''}`}
                          placeholder={TextOnly('vin')+'...'}
                          value={vehicleVin}
                          onChange={handleChangeVinInput}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    { !vehicleVinVerified &&
                      <div className="c-field get-vin-info u-margin-top-small margin-bottom-0">
                        <LoaderButton
                          disabled={vehicleVin.length <= 0 || loadingInfoVin || !validateVIN(vehicleVin)}
                          text={TextOnly("lookUp")}
                          isLoading={isLoading || loadingInfoVin}
                          loadingText={TextOnly('loading')}
                          onClick={()=>handleGetVinInfo()}
                        />
                      </div>
                    }
                    <div className={`auto-info-table-wrapper ${(
                        vehicleVinVerified
                        )
                        ? 'show' : 'hide'}`}>
                      <table className='u-margin-top-large auto-info-table show'>
                        <tbody>
                          <tr>
                            <td><strong>Make:</strong></td>
                            <td>{vehicleMake}</td>
                          </tr>
                          <tr>
                            <td><strong>Model:</strong></td>
                            <td>{vehicleModel}</td>
                          </tr>
                          <tr>
                            <td><strong>Year:</strong></td>
                            <td>{vehicleYear}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <div className={`c-field`}>
                        <label className={`c-field__label`} htmlFor='year'>
                          { !!vehicleVin ? '' :
                            <span className="u-text-error">*</span>
                          } <Text tid="yearCapitalized" />:
                        </label>
                        <input
                          type="text"
                          id="year"
                          className={`c-input ${(vehicleYear.length && !validateVehicleYear()) ? 'c-input__error' :''}`}
                          placeholder={TextOnly("yearCapitalized")+'...'}
                          value={vehicleYear}
                          onChange={e => setVehicleYear(e.target.value)}
                          disabled={isLoading}
                        />
                        {(vehicleYear.length > 0 && !validateVehicleYear()) &&
                          <div className="c-field__error">
                            <Text tid="enterValidVehicleYear" />
                          </div>
                        }
                      </div>
                      <div className={`c-field`}>
                        <label className={`c-field__label`} htmlFor='make'>
                          { !!vehicleVin ? '' :
                            <span className="u-text-error">*</span>
                          } <Text tid="make" />:
                        </label>
                        <input
                          type="text"
                          id="make"
                          className={`c-input`}
                          placeholder={TextOnly("make")+'...'}
                          value={vehicleMake}
                          onChange={e => setVehicleMake(e.target.value)}
                          disabled={isLoading}
                        />
                      </div>
                      <div className={`c-field`}>
                        <label className={`c-field__label`} htmlFor='model'>
                          { !!vehicleVin ? '' :
                            <span className="u-text-error">*</span>
                          } <Text tid="model" />:
                        </label>
                        <input
                          type="text"
                          id="model"
                          className={`c-input`}
                          placeholder={TextOnly("model")+'...'}
                          value={vehicleModel}
                          onChange={e => setVehicleModel(e.target.value)}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={!isSmallScreen && (vehicle || customer) ? 'u-width-50-percent' : ''}>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='vehicleLPN'>
                  <Text tid="licensePlate" />:
                </label>
                <input
                  type="text"
                  id="vehicleLPN"
                  className={`c-input`}
                  placeholder={TextOnly('licensePlate')+'...'}
                  value={vehicleLicensePlate.toUpperCase()}
                  onChange={(event)=>setVehicleLicensePlate(event.target.value.toUpperCase())}
                  disabled={isLoading}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='vehicleColor'>
                  <Text tid="vehicleColor" />:
                </label>
                <input
                  type="text"
                  id="vehicleColor"
                  className={`c-input`}
                  placeholder={TextOnly('vehicleColor')+'...'}
                  value={vehicleColor}
                  onChange={(event)=>setVehicleColor(event.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='odometer'>
                  <Text tid="odometer" />:
                </label>
                <input
                  type="text"
                  id="odometer"
                  className={`c-input`}
                  placeholder={TextOnly('odometer')+'...'}
                  value={vehicleOdometer}
                  onChange={(event)=>setVehicleOdometer(event.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!setNewVehicleInfo && (
        <div className="c-field display-flex justifycontent-center">
          <LoaderButton
            text={vehicle ? TextOnly('updateVehicle') : TextOnly('createVehicle')}
            onClick={(event) => {
              event.preventDefault();
              handleSubmitVehicle();
            }}
            isLoading={isLoading}
            loadingText={TextOnly('loading')}
            disabled={isLoading || (!validateSubmit())}
          />
        </div>
      )}
    </div>
  );
}


export default AddEditVehicleForm;
