import React, { Component } from "react";
import { Dialog } from "@reach/dialog";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { CreateMenu } from "../components/CreateMenu";
import braintree from "braintree-web-drop-in";
import LoaderButton from "../components/LoaderButton";
import { toast } from "react-toastify";
import {
  createShop,
  getSystemConfig,
  getOEMRegionList,
  logSmartyStreets,
  getRegionOemToolCompatibility,
} from "../libs/db-lib";
import {
  nameValidate,
} from "../libs/utils";
import { getBraintreeConfig } from "../libs/utils-ts";
import AlertModal from "../components/AlertModal";
import { Text, TextOnly } from "../components/Text";
import CountryPicker from "../components/CountryPicker";
import StatePicker from "../components/StatePicker";
import "./Signup.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'

import countries from "i18n-iso-countries";
import { SHOP_TYPES } from "../CONSTANTS";
import StatsAndTrendsPromo from "./StatsAndTrendsPromo";
import UpgradeToStandardPlus from "./UpgradeToStandardPlus"
import {
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox';
import _ from 'underscore';
import CompatibleTools from "../components/CompatibleTools/CompatibleTools";
import CompatibleToolsModal from "../components/CompatibleTools/CompatibleToolsModal";
import PriceBreakdown from '../components/PriceBreakdown';

export default class ServiceCenterSignup extends Component {
  typeaheadRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      shareInfo: false,
      isLoading: false,
      shop: "",
      userName2: "",
      password2: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "AL",
      country: "US",
      zip: "",
      phoneNumber: '',
      phoneVerify: false,
      confirmationCode: "",
      disclaimerAccepted: false,
      numberBraintree: false,
      expirationDateBraintree: false,
      cvvBraintree: false,
      postalCodeBraintree: false,
      config: {},
      selectedShopType: undefined,
      showModal: false,
      showChartModal: false,
      showVerifyAddressModal: false,
      addressesToVerify: [],
      internationalAddressesToVerify: [],
      addressToLog: {},
      smartyStreetsResponse: {},
      selectedAddress: {},
      addressSuggestions: [],
      typeaheadOpen: undefined,
      showLearnMoreModal: false,
      windowWidth: 0,
      oemIDs: [],
      oemIDsSelected: new Set(),
      regPrice: 0,
      regionSelected: 'default',
      confirmOemRegionModal: false,
      oemregionName: {},
      chartData: {},
      showPriceBreakdown: false,
      streetAddress1Verify: false, // Update by activateFieldVerify
      streetAddress2Verify: false, // Update by activateFieldVerify
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lang !== prevProps.lang) {
      this.setState({
        hl: TextOnly("hl"),
      });
      let self = this;
      this.state.braintreeDropin.teardown(() => {
        braintree.create(getBraintreeConfig(self.state.config.braintreeMode),
          function (createErr, instance) {
            if (createErr) {
              console.log("braintree.create returned error: ", createErr);
            } else {
              self.setState({braintreeDropin: instance});
              let button = document.getElementById("submit-button");
              button.addEventListener("click", function () {
                let instance = this.state.braintreeDropin;
                instance.requestPaymentMethod(function (
                  requestPaymentMethodErr,
                  payload
                ) {
                  if (payload) {
                    self.setState({paymentInfo: payload}, self.handleSubmit);
                  } else {
                    toast.error(TextOnly("enterPaymentMethod"), {
                      containerId: "standard",
                    });
                  }
                });
              });
            }
          }
        );
      });

    }

    if(Object.keys(this.state.config).length && (prevState.selectedShopType !== this.state.selectedShopType || prevState.oemIDsSelected.size !== this.state.oemIDsSelected.size)){
      let newPrice = this.state.selectedShopType.includes(SHOP_TYPES.PLUS) ? this.state.config.prices.plusShop : this.state.config.prices.standardShop;
      /*
        The pricing for plusShop and standardShop is based on the assumption that
        the user has selected at least one OEM. Consequently, for each additional
        OEM selected beyond the first one, an oemRegionAddon charge will be added
        to the newPrice.
      */
      newPrice += (this.state.oemIDsSelected.size > 1 ? this.state.config.prices.oemRegionAddon * (this.state.oemIDsSelected.size - 1) : 0);
      this.setState({
        regPrice: newPrice
      })
    }
  }

  async componentDidMount() {
    let self = this;

    this.setState({
      hl: TextOnly("hl"),
    });

    let config = await getSystemConfig();

    let oemRegions = await getOEMRegionList();

    let oemregionName = {}
    oemRegions.forEach(oemInterface=>{
      if(!oemregionName[oemInterface.oemID]) oemregionName[oemInterface.oemID] = oemInterface.oemName;
      if(!oemregionName[oemInterface.regionCode]) oemregionName[oemInterface.regionCode] = oemInterface.regionName;
    })

    this.setState({
      config: config,
      oemRegions: oemRegions,
      oemregionName: oemregionName,
    });

    braintree.create(getBraintreeConfig(config.braintreeMode),
      function (createErr, instance) {
        if (createErr) {
          console.error("braintree.create error: ", createErr);
          self.setState({
            alertTitle: "componentDidMount Braintree Error",
            alertMessage: "Braintree Error: " + JSON.stringify(createErr),
            showModal: true,
          });
        } else {
          self.setState({ braintreeDropin: instance });

          instance.on("card:validityChange", (event) => {
            let field = event.fields[event.emittedBy];
            let valid = field.isValid && !field.isEmpty;
            self.setState({
              [event.emittedBy + "Braintree"]: valid,
            });
          });

          let button = document.getElementById("submit-button");
          button.addEventListener("click", function () {
            self.setState({isLoading: true});
            instance.requestPaymentMethod(function (
              requestPaymentMethodErr,
              payload
            ) {
              if (payload) {
                self.setState({ paymentInfo: payload }, self.handleSubmit);
              } else {
                toast.error(TextOnly("enterPaymentMethod"), {
                  containerId: "standard",
                });
              }
            });
          });
        }
      }
    );

    // Set timeout to get the compatibility data in the background
    setTimeout(async () => {
      const response = await getRegionOemToolCompatibility();
      if (!response.error)
      self.setState({
        chartData: response.data
      });
    }, 350);

    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener("resize", this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    let shopValid = nameValidate(this.state.shop)
    let creditCardValid = this.state.numberBraintree && this.state.expirationDateBraintree && this.state.cvvBraintree && this.state.postalCodeBraintree;

    return (
      shopValid &&
      this.state.streetAddress1?.length > 0 &&
      this.state.city?.length > 0 &&
      this.state.state?.length > 0 &&
      this.state.zip?.length > 0 &&
      this.state.country?.length > 0 &&
      this.state.phoneNumber.length > 0 &&
      creditCardValid &&
      this.state.disclaimerAccepted === true &&
      this.state.regionSelected !== 'default' &&
      this.state.oemIDsSelected.size > 0
    );
  }

  validateFreeformAddress = async (query) => {
    const smartyStreetKey = this.state.config.smartyStreets.key;
    if(this.state.country !== 'US') {
      let url = `https://international-autocomplete.api.smarty.com/v2/`;
      url += `lookup?key=${smartyStreetKey}`;
      url += `&search=${query}`;
      url += `&country=${countries.alpha2ToAlpha3(this.state.country)}`;
      url += '&license=international-autocomplete-v2-cloud';
      try {
        const result = await fetch(url);
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        } else {
          const response = await result.json();
          this.setState({
            addressSuggestions: response.candidates || [],
          });
          return response;
        }
      } catch (error) {
        console.error('Error:', error.message);
        return false;
      }
    } else {
      let url = `https://us-autocomplete-pro.api.smartystreets.com/`
      url += `lookup?key=${smartyStreetKey}`;
      url += `&search=${query}`;
      url += '&license=us-autocomplete-pro-cloud';
      try {
        const result = await fetch(url);
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        } else {
          const response = await result.json();
          this.setState({
            addressSuggestions: response.suggestions || [],
          });
          return response;
        }
      } catch (error) {
        console.error('Error:', error.message);
        return false;
      }
    }
  };

  getAddressInfo = async (option) => {
    const smartyStreetKey = this.state.config.smartyStreets.key;
    let url = `https://international-autocomplete.api.smarty.com/v2/`;
    url += `lookup/${option.address_id}`;
    url += `?key=${smartyStreetKey}`;
    url += `&country=${countries.alpha2ToAlpha3(this.state.country)}`;
    url += `&license=international-autocomplete-v2-cloud`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        const jsonResponse = await response.json();
        const result = jsonResponse.candidates;
        return result;
      }
    } catch (error) {
      console.error('Error:', error.message);
      return false;
    }
  };

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  activateShopNameVerify() {
    this.setState({ verifyShopName: true });
  }

  activateFirstNameVerify() {
    this.setState({ verifyFirstName: true });
  }

  activateLastNameVerify() {
    this.setState({ verifyLastName: true });
  }

  activateEmailVerify() {
    this.setState({ verifyEmail: true });
  }

  activateFieldVerify(field) {
    this.setState({ [field + "Verify"]: true });
  }

  onloadCallback(response) {
    return;
  }

  async createServiceCenter(self, paymentInfo) {
    // Create Service Center Record
    let shopInfo = {
      shareInfo: self.state.shareInfo,
      streetAddress1: self.state.streetAddress1,
      streetAddress2: self.state.streetAddress2,
      city: self.state.city,
      state: self.state.state,
      zip: self.state.zip,
      country: self.state.country,
      phone: self.state.phoneNumber,
      oemIDs: [...self.state.oemIDsSelected],
      regionCode: self.state.regionSelected,
    };

    let selectedAddress = Object.assign({}, shopInfo);
    delete selectedAddress.phone;

    self.setState({
      selectedAddress: selectedAddress,
    });

    let result = await createShop(
      self.state.shop,
      self.state.selectedShopType,
      paymentInfo,
      shopInfo
    );
    if (result?.error) {
      toast.error(TextOnly(result.error), {
        containerId: "wide",
        autoClose: false,
      });
      const modalSlider = document.querySelector(".c-modal-slider");
      if (modalSlider) {
        modalSlider.classList.add("closed");
      }
      self.setState({
        isLoading: false,
        showVerifyAddressModal: false,
      });
      self.state.braintreeDropin.clearSelectedPaymentMethod();
    } else {
      await self.props.fetchUser();
      self.props.history.push("/myActivity");
    }

    await logSmartyStreets(
      { success: true, component: self.constructor.name },
      self.state.addressToLog,
      self.state.smartyStreetsResponse,
      self.state.selectedAddress
    );
  }

  handleChange = (event) => {
    if (event.target.id === "country") {
      this.setState({
        addressSuggestions: [],
        country: event.target.value,
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
      });
      this.typeaheadRef &&
        this.typeaheadRef.current &&
        this.typeaheadRef.current._instance.clear();
      if (event.target.value === "US") {
        let state = this.state.state?.toUpperCase();
        this.setState({
          state: state,
        });
      }
    } else if (event.target.id === 'phone') {
      this.setState({
        phoneNumer: event.target.value,
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }

    this.activateFieldVerify(event.target.id);
  };

  handleChangePhoneNumber = (number) => {
    let newNumber = number ? number : '';
    this.setState({
      phoneNumber: newNumber,
      phoneVerify: true
    })
  }

  handleOEMChange = (event, oem) => {
    let oemIDsSelected = new Set(this.state.oemIDsSelected);
    if (event.target.checked === true) {
      if (!oemIDsSelected.has(event.target.id)) {
        oemIDsSelected.add(event.target.id);
      }
    } else {
      if (oemIDsSelected.has(event.target.id)) {
        oemIDsSelected.delete(event.target.id);
      }
    }
    this.setState({
      oemIDsSelected: oemIDsSelected,
    });

    this.activateFieldVerify(event.target.id);
  };

  handleRegionChange = (region) => {
    this.setState({
      regionSelected: region,
      oemIDsSelected: new Set(),
      showModal: true,
    });
  }

  debounceAddressSearch = _.debounce((query) => {
    this.validateFreeformAddress(query);
  }, 500);

  handleSearchAddress = (query) => {
    this.debounceAddressSearch(query);
  };

  handleShowVerifyAddressModal() {
    this.setState({
      showVerifyAddressModal: true,
    });
  }

  handleShowLearnMoreModal = () => {
    this.setState({
      showLearnMoreModal: true,
    });
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + "FieldActivate"]: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id + "FieldActivate"]: false,
      });
    }
  };

  handleCancel = () => {
    document.querySelector(".c-modal-slider").classList.add("closed");
    setTimeout(() => {
      this.setState({
        showModal: false,
        showVerifyAddressModal: false,
      });
    }, 350);
  };

  handleCancelLearnMoreModal = () => {
    let selector =
      this.state.windowWidth < 753 ? ".c-modal-slider-90" : ".c-modal-slider-75";
    document.querySelector(selector).classList.add("closed");
    setTimeout(() => {
      this.setState({
        showLearnMoreModal: false,
      });
    }, 350);
  };

  handleAcceptDisclaimer = (event) => {
    if (this.state.disclaimerAccepted) {
      this.setState({ disclaimerAccepted: false });
    } else {
      this.setState({ disclaimerAccepted: true });
    }
  };

  handleConfirmSubmit = ()=>  {
    this.setState({ showPriceBreakdown: true });
  }

  handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    let instance = this.state.braintreeDropin;
    const self = this;
    instance.requestPaymentMethod((err, payload) => {
      if (!payload) {
        toast.error(TextOnly("enterPaymentMethod"), {
          containerId: "standard",
        });
      } else {
        self.setState({
          isLoading: true,
          showPriceBreakdown: false,
        });
        self.createServiceCenter(self, payload);
      }
    });
  };

  handleChangePlan = (plan) => {
    this.setState({
      selectedShopType: plan,
    });
  };

  handleCancelModal = () => {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      this.setState({showModal: false})
    }, 250);
  };
  handleCloseChartModal = () => this.setState({showChartModal: false})

  render() {
    const self = this;
    const intAddrToVerify = this.state.internationalAddressesToVerify;
    const isInternational = this.state.country !== "US";
    const prices = this.state.config.prices;

    // US Street Addresses can be validated to mutiple possibilities
    const addressOptions =
      this.state.country === "US" &&
      this.state.addressesToVerify.map((address) => {
        // If address contains a secondary number (apt, suite, unit)
        // Separate it, since we divide them into two components
        let primaryAddress = "";
        let secondaryAddress = "";
        if (address.components.secondary_designator) {
          let index = address.delivery_line_1.indexOf(
            address.components.secondary_designator
          );
          primaryAddress = address.delivery_line_1.substring(0, index).trim();
          secondaryAddress = address.delivery_line_1.substring(index).trim();
        }
        return (
          <div key={address.delivery_line_1}>
            <div className="c-verify-address u-margin-top">
              <div className="c-verify-address__fields">
                <dl>
                  <dt>
                    <Text tid="streetAddress1" />
                  </dt>
                  <dd>{primaryAddress || address.delivery_line_1 || ""}</dd>
                </dl>
                {address.components.secondary_designator ? (
                  <dl>
                    <dt>
                      <Text tid="streetAddress2" />
                    </dt>
                    <dd>{secondaryAddress}</dd>
                  </dl>
                ) : null}

                <dl>
                  <dt>
                    <Text tid="city" />
                  </dt>
                  <dd>{address.components.city_name}</dd>
                </dl>
                <dl>
                  <dt>
                    <Text tid="state" />
                  </dt>
                  <dd>{address.components.state_abbreviation || ""}</dd>
                </dl>
                <dl>
                  <dt>
                    <Text tid="zipCode" />
                  </dt>
                  <dd>{address.components.zipcode || ""}</dd>
                </dl>
                <dl>
                  <dt>
                    <Text tid="country" />
                  </dt>
                  <dd>{this.state.country}</dd>
                </dl>
              </div>
              <div className="c-verify-address__footer">
                <div />
                <div className="c-verify-address__footer-right">
                  <LoaderButton
                    className="c-btn u-margin-top"
                    isLoading={this.state.isLoading}
                    loadingText={TextOnly("signingUp")}
                    text={<Text tid="confirm" />}
                    onClick={() => {
                      self.setState(
                        {
                          streetAddress1: address.components
                            .secondary_designator
                            ? primaryAddress
                            : address.delivery_line_1,
                          streetAddress2: address.components
                            .secondary_designator
                            ? secondaryAddress
                            : "",
                          city: address.components.city_name,
                          state: address.components.state_abbreviation,
                          zip: address.components.zipcode,
                          country: self.state.country,
                          phone: self.state.phone,
                          shareInfo: self.state.shareInfo
                        },
                        () => {
                          self.handleSubmit();
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });

    // International addresses
    const intAddressOptions = intAddrToVerify.map((address, index) => {
      return (
        <div className="c-verify-address u-margin-top" key={index}>
          <div className="c-verify-address__fields">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => {
              if (address[`address${num}`]) {
                return (
                  <dl key={num}>
                    <dt />
                    <dd>{address[`address${num}`]}</dd>
                  </dl>
                );
              }
              return null;
            })}
            <dl>
              <dt />
              <dd>{address.components.country_iso_3}</dd>
            </dl>
          </div>
          <div className="c-verify-address__footer">
            <div />
            <div className="c-verify-address__footer-right">
              <button
                className="c-btn u-margin-top"
                onClick={() => {
                  let streetAddr = address.address1;
                  if (
                    Object.keys(address.metadata).length === 0 ||
                    address.metadata.address_format
                      .split("|")[0]
                      .indexOf("premise") === -1
                  ) {
                    streetAddr =
                      address.components.premise +
                      " " +
                      address.components.thoroughfare;
                  }
                  let postalCode = address.components.postal_code;
                  if (
                    address.components.postal_code_short &&
                    address.components.postal_code_extra
                  ) {
                    postalCode = `${address.components.postal_code_short}-${address.components.postal_code_extra}`;
                  }
                  self.setState(
                    {
                      streetAddress1: streetAddr,
                      city: address.components.dependent_locality
                        ? address.components.dependent_locality +
                          ", " +
                          address.components.locality
                        : address.components.locality,
                      state: address.components.administrative_area,
                      zip: postalCode,
                      country: self.state.country,
                      phone: self.state.phone,
                    },
                    () => {
                      self.handleSubmit();
                    }
                  );
                }}
              >
                {TextOnly("confirm")}
              </button>
            </div>
          </div>
        </div>
      );
    });

    let regions = {};
    const oemByRegion = {};
    if (this.state?.oemRegions?.length > 0) {
      this.state.oemRegions.forEach(oem=>{
        if(oemByRegion[oem.regionName]){
          oemByRegion[oem.regionName].push(oem);
          regions[oem.regionName] = oem.regionCode;
        } else {
          oemByRegion[oem.regionName] = [oem]
          regions[oem.regionName] = oem.regionCode;
        }
      })
    }
    const oemByRegionArraySorted = Object.keys(oemByRegion).sort();


    const oemByRegionList = {};
    if (oemByRegionArraySorted.length) {
      oemByRegionArraySorted.forEach((regionKey) => {

        oemByRegionList[regions[regionKey]] = (
          <div key={regionKey} className="marginbottom-10">
            <span className="color-aa-blue">{regionKey}</span>
            {oemByRegion[regionKey].sort((a,b)=>a.oemName>b.oemName?1:-1).map(oem=>(
              <div
                key={oem.oemID}
                className={`u-margin-top-large l-container-sm-med ${this.state.windowWidth <= 453 ? "u-margin-auto u-width-50-percent" : ""}`}>
                <div className="l-flex-wrap u-align-center">
                  <div className={"l-inline-flex"}>
                    <input
                      id={oem.oemID}
                      type="checkbox"
                      onClick={event => this.handleOEMChange(event, oem.oemID)}
                      className="u-margin-right-large"
                    />
                    <label className="c-field__label left-padding u-padding-bottom-none" htmlFor={oem.oemID}>
                      {oem.oemName}{' '}
                      {oem.countries ? <span className="text-bold u-text-transform-none">{'(' + oem.countries.join(', ') + ' only) '}</span> : ''}
                      {oem.hint ?
                        (typeof oem.hint === 'string') ?
                          <span className="text-bold u-text-transform-none">{oem.hint}</span>
                          :
                          <span className="text-bold u-text-transform-none">
                            <a target="_blank" href={oem.hint.link}>{oem.hint.text}</a>
                          </span>
                        : ''}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      });
    }

    let oemRegionListboxList = [];
    if (Object.keys(oemByRegion)) {
      Object.keys(oemByRegion).forEach((region) => {
        oemRegionListboxList.push(
          <ListboxOption
            key={regions[region]}
            value={regions[region]}
          >
            { region }
          </ListboxOption>
        );
      })
    }

    const missingFieldsMap = [
      { serviceCenterName: !!this.state.shop },
      { streetAddress1: !!this.state.streetAddress1 },
      { city: !!this.state.city },
      { state: !!this.state.state },
      { zipCode: !!this.state.zip },
      { country: !!this.state.country },
      {
        phoneNumber:
          this.state.phoneNumber.length > 0,
      },
      { numberBraintree: this.state.numberBraintree },
      { expirationDateBraintree: this.state.expirationDateBraintree },
      { cvvBraintree: this.state.cvvBraintree },
      { postalCodeBraintree: this.state.postalCodeBraintree },
      { acceptTermsConditions: this.state.disclaimerAccepted },
      { oems: this.state.oemIDsSelected.size > 0 },
    ];

    const missingFieldsHint = this.validateForm() || this.state.isLoading ? null : (
      <div
        className={`u-margin-top-large ${
          this.state.windowWidth <= 453
            ? "u-margin-auto u-width-70-percent"
            : ""
        }`}
      >
        <div
          className={
            (this.state.windowWidth > 453
              ? "l-flex-wrap-center u-gap-small"
              : "") +
            (this.state.windowWidth > 532 ? " u-padding-left-10rem" : "")
          }
        >
          {[
            [0, 7],
            [7, 14],
          ].map((slices, idx) => {
            return (
              <div key={idx}>
                {missingFieldsMap.slice(slices[0], slices[1]).map((field) => {
                  const [key, value] = Object.entries(field)[0];
                  return (
                    <div className="l-flex-start" key={key}>
                      <span
                        className={`c-btn__icon fal fa-${
                          value ? "check" : "times"
                        }-circle u-text-${value ? "success" : "error"}`}
                      />
                      <label
                        htmlFor={key}
                        className={`c-field__label u-text-${
                          value ? "success" : "error"
                        }`}
                      >
                        <Text tid={key} />
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );

    const freeformProps = {};
    freeformProps.renderMenu = (results, menuProps) => {
      return CreateMenu(
        results,
        menuProps,
        "label",
        ["", "label"],
        true,
        "",
        true
      );
    };

    let secondCol = (
      <>
        <div className="c-field">
          <label htmlFor="shop" className="c-field__label">
            <span className="u-text-error">*</span>{" "}
            <Text tid="serviceCenterName" />
          </label>
          <input
            id="shop"
            maxLength="50"
            type="text"
            className={`c-input${
              this.state.shopVerify && !nameValidate(this.state.shop)
                ? "__error"
                : ""
            }`}
            placeholder={TextOnly("serviceCenterName")}
            autoCapitalize="off"
            value={this.state.shop}
            onChange={this.handleChange}
            onFocus={this.activateField.bind(this)}
            onBlur={this.disableField.bind(this)}
          />
          {this.state.shopVerify && !nameValidate(this.state.shop) ? (
            <p className="u-text-error">
              <Text tid="shopNameHint" />
            </p>
          ) : null}
        </div>

        <>
          <>
            <CountryPicker
              country={this.state.country}
              handleChange={this.handleChange}
              countryVerify={this.state.countryVerify}
            />
            <div className={`c-field`}>
              <label htmlFor="streetAddress1" className="c-field__label">
                <span className="u-text-error">*</span>{" "}
                <Text tid="streetAddress1" />
              </label>
              <AsyncTypeahead
                filterBy={() => true}
                id="streetAddress1"
                inputProps={{
                  id: "streetAddress1",
                  className: `c-input${
                    this.state.streetAddress1Verify &&
                    this.state.streetAddress1.trim().length === 0
                      ? "__error"
                      : ""
                  }`,
                }}
                isLoading={this.state.isLoading}
                clearButton
                flip={false}
                minLength={1}
                labelKey={(option) =>
                  isInternational
                    ? `${this.state.streetAddress1 || option.address_text}`
                    : `${option.street_line}`
                }
                maxResults={100}
                onSearch={this.handleSearchAddress}
                onInputChange={(text) => {
                  this.activateFieldVerify('streetAddress1');
                  if (text.trim().length === 0) {
                    this.setState({
                      addressSuggestions: [],
                    });
                  }
                  this.setState({
                    streetAddress1: text,
                  });
                }}
                onChange={async (option) => {
                  option = option[0];
                  //User is deleting/backspacing or clicked clearButton
                  if (!option || option.length === 0) {
                    this.setState({
                      addressSuggestions: [],
                      streetAddress1: '',
                      streetAddress2: '',
                      city: '',
                      state: '',
                      zip: '',
                    });
                    return;
                  }

                  if (isInternational) {
                    const result = await this.getAddressInfo(option);
                    if(result) {
                      if(result.length > 1) {
                        this.setState({
                          typeaheadOpen: true,
                          addressSuggestions: result || [],
                          streetAddress2: '',
                          city: '',
                          state: '',
                          zip: '',
                        });
                      } else {
                        this.setState({
                          typeaheadOpen: false,
                          streetAddress1: result[0].street,
                          streetAddress2: '',
                          city: result[0].locality,
                          state: result[0].administrative_area || result[0].locality,
                          zip: result[0].postal_code,
                        });
                      }
                    }
                  } else {
                    let needsExpansion =
                      option.secondary && option.entries > 1;
                    if (needsExpansion) {
                      this.setState({
                        typeaheadOpen: true,
                        streetAddress1: option.street_line,
                        streetAddress2: "",
                        city: option.city,
                        state: option.state,
                        zip: option.zipcode,
                      });
                      let selectedQuery = `${encodeURIComponent(this.state.streetAddress1)}&selected=${encodeURIComponent(`${option.street_line} ${option.secondary} (${option.entries}) ${option.city} ${option.state} ${option.zipcode}`)}`;
                      this.handleSearchAddress(selectedQuery);
                    } else {
                      this.setState({
                        typeaheadOpen: undefined,
                        streetAddress1: option.street_line,
                        streetAddress2: option.secondary || "",
                        city: option.city,
                        state: option.state,
                        zip: option.zipcode,
                      });
                    }
                  }
                }}
                options={this.state.addressSuggestions}
                paginate={false}
                open={
                  this.state.addressSuggestions.length === 0
                    ? false
                    : this.state.typeaheadOpen
                }
                placeholder={TextOnly("streetAddress1")}
                renderMenuItemChildren={(option, props) => {
                  if (isInternational) {
                    return (
                      <div>
                        {option.address_text}
                      </div>
                    );
                  }

                  let secondaryStr =
                    option.secondary.length !== 0
                      ? `, ${option.secondary}`
                      : "";
                  let needsExpansion = option.secondary && option.entries > 1;
                  if (needsExpansion) {
                    secondaryStr = (
                      <span>
                        , {option.secondary} #
                        <b>
                          {"("}
                          {option.entries} results{")"}
                        </b>
                      </span>
                    );
                  }
                  return (
                    <div>
                      {option.street_line}
                      {secondaryStr}, {option.city} {option.state},{" "}
                      {option.zipcode}
                    </div>
                  );
                }}
              /> {/* AsyncTypeahead */}
              {this.state.streetAddress1Verify &&
              this.state.streetAddress1.trim().length === 0 ? (
                <p className="u-text-error">
                  <Text tid="fieldCannotBeBlank" />
                </p>
              ) : null}
            </div>
          </>
          <div className={`c-field`}>
            <label htmlFor="streetAddress2" className="c-field__label">
              <Text tid="streetAddress2" />:
            </label>
            <input
              id="streetAddress2"
              type="text"
              maxLength="50"
              className="c-input"
              placeholder={TextOnly("streetAddress2")}
              value={this.state.streetAddress2}
              onChange={this.handleChange}
            />
          </div>
          <div className={`c-field`}>
            <label htmlFor="city" className="c-field__label">
              <span className="u-text-error">*</span> <Text tid="city" />
            </label>
            <input
              id="city"
              type="text"
              maxLength="50"
              className={`c-input${
                this.state.cityVerify && this.state.city.trim().length === 0
                  ? "__error"
                  : ""
              }`}
              placeholder={TextOnly("city")}
              value={this.state.city}
              onChange={this.handleChange}
            />
            {this.state.cityVerify && this.state.city.trim().length === 0 ? (
              <p className="u-text-error">
                <Text tid="fieldCannotBeBlank" />
              </p>
            ) : null}
          </div>
          <div className="l-flex-between">
            {this.state.country === "US" ? (
              <StatePicker
                state={this.state.state}
                handleChange={this.handleChange}
                stateVerify={this.state.stateVerify}
              />
            ) : (
              <div className={`c-field l-container-xs`}>
                <label htmlFor="state" className="c-field__label">
                  <span className="u-text-error">*</span> <Text tid="state" />
                </label>
                <input
                  id="state"
                  type="text"
                  maxLength="50"
                  className={`u-input-side-by-side c-input${
                    this.state.stateVerify &&
                    this.state.state.trim().length === 0
                      ? "__error"
                      : ""
                  }`}
                  placeholder={TextOnly("state")}
                  value={this.state.state}
                  onChange={this.handleChange}
                />
                {this.state.stateVerify &&
                this.state.state.trim().length === 0 ? (
                  <p className="u-text-error">
                    <Text tid="fieldCannotBeBlank" />
                  </p>
                ) : null}
              </div>
            )}
            <div className={`c-field l-container-xs`}>
              <label htmlFor="zip" className="c-field__label">
                <span className="u-text-error">*</span> <Text tid="zipCode" />
              </label>
              <input
                id="zip"
                type="text"
                maxLength="50"
                className={`u-input-side-by-side c-input${
                  this.state.zipVerify && this.state.zip.trim().length === 0
                    ? "__error"
                    : ""
                }`}
                placeholder={TextOnly("zipCode")}
                value={this.state.zip}
                onChange={this.handleChange}
              />
              {this.state.zipVerify && this.state.zip.trim().length === 0 ? (
                <p className="u-text-error">
                  <Text tid="fieldCannotBeBlank" />
                </p>
              ) : null}
            </div>
          </div>

          <div className={"c-field"}>
            <label htmlFor="phone" className="c-field__label">
              <span className="u-text-error">*</span> <Text tid="phoneNumber" />
            </label>
            <PhoneInput
              id="phone"
              type="tel"
              maxLength="50"
              className={`c-input ${
                this.state.phoneVerify && !isPossiblePhoneNumber(this.state.phoneNumber)
                  ? "phoneError"
                  : ""
              }`}
              value={this.state.phoneNumber}
              defaultCountry={this.state.country}
              onChange={this.handleChangePhoneNumber}
            />

          </div>

          { oemRegionListboxList ?

            <>
              <label className="c-field__label">
                <span className="u-text-error">*</span> <Text tid="region" />
              </label>

              <div className={`c-field`}>
                <ListboxInput
                  className="c-listbox"
                  value={this.state.regionSelected}
                  onChange={this.handleRegionChange}
                >
                  <ListboxButton />
                  <ListboxPopover>
                    <ListboxList>
                      <ListboxOption value={'default'} disabled={true}>
                        <Text tid="pickShopRegion" />
                      </ListboxOption>
                      { oemRegionListboxList }
                    </ListboxList>
                  </ListboxPopover>
                </ListboxInput>
                <label className="c-field__label u-padding-xs">
                  <span className="color-aa-red"><Text tid={'regionCantBeChanged'} /></span>
                </label>
                <div className="display-flex-justify-content-center">
                  <CompatibleToolsModal
                    openButtonText={TextOnly('checkRegionManufacturerCompatibility')}
                    closeButtonText={TextOnly('confirm')}
                    noCloseButton={true}
                    chartData={this.state.chartData}
                  />
                </div>
             </div>
            </>
           : ''}

          { oemByRegionList[this.state.regionSelected] ?
            <div className={`c-field`}>
              <label className="c-field__label">
                <span className="u-text-error">*</span> <Text tid={'oems'} /> <br/>
              </label>
              <label className="">
                <span className="u-text-warning"><Text tid={'addlManPricing'} sub={{ num: 1, price: prices.oemRegionAddon }}/></span> <br/>
              </label>
              <div>
                <ul>
                  {oemByRegionList[this.state.regionSelected] ?? ''}
                </ul>
              </div>
            </div>
            : '' }
        </>
      </>
      // <div className="l-container-sm u-padding-right-small u-padding-top-small">

      // </div>
    );

    let thirdCol = (
      <>
        <div className="c-notice-label-alt">
          <section className="c-section c-section">
            <label className="c-section__label">
              <Text tid="currentPlan" />
            </label>
            <div className="c-section__content">
              <span className="c-section__key c-section__key--bold">
                {this.state.selectedShopType === SHOP_TYPES.STANDARD
                  ? "AutoAuth Standard"
                  : null}
                {this.state.selectedShopType === SHOP_TYPES.PLUS
                  ? "AutoAuth PLUS"
                  : null}
                {this.state.selectedShopType === 'PLUSCRM'
                  ? "AutoAuth PLUS CRM"
                  : null}
              </span>
            </div>
          </section>
          <section className="c-section c-section">
            <label className="c-section__label">
              <Text tid="registrationFee" />
            </label>
            <div className="c-section__content">
              <span className="c-section__key">
                {this.state.config &&
                  `$${this.state.regPrice}.00`}
                /
                <Text tid="year" />
              </span>
            </div>
          </section>
          { this.state.selectedShopType?.includes('CRM') ?
            <section className="c-section c-section u-padding-top-small">
              <label className="c-section__label">
                <Text tid="crmFee" />
              </label>
              <div className="c-section__content">
                <span className="c-section__key">
                  {this.state.config &&
                    `$${this.state.config.prices?.crmBasicMonthly.toFixed(2)}`}
                  /
                  <Text tid="month" /> (<Text tid='afterFreeTrial' />)
                </span>
              </div>
            </section>
          : '' }

        </div>

        <div id="braintree-container" />
      </>
    );

    let columnLayout = (
      <>
        <div className="l-container-med u-padding-right-small u-padding-top-small">
          {this.state.windowWidth < 1328}
          {secondCol}
        </div>
        <div className="l-container-sm u-padding-left-small u-padding-top-small">
          {thirdCol}
        </div>
      </>
    );

    return (
      <>
        <div
          className={`${
            !this.state.selectedShopType ? "" : "l-container-lg"
          } l-full-height`}
        >
          {!this.state.selectedShopType &&
            <UpgradeToStandardPlus
              {...this.props}
              handleChangePlan={this.handleChangePlan}
              handleLearnMore={this.handleShowLearnMoreModal}
            />
          }

          <form
            className={`${this.state.selectedShopType ? "" : "u-is-vishidden"} max-width-1200 u-width-100-percent align-self-center margin-bottom-45px `}
          >
            <h2 className="u-text-center h1 u-padding-top-large u-margin-bottom-large">
              <Text tid="serviceCenterIndependentTechSignup" />
            </h2>

            <div
              className={`l-container-${
                this.state.windowWidth >= 1328
                  ? "lg"
                  : this.state.windowWidth >= 1024 ||
                    (this.state.windowWidth >= 704 &&
                      this.state.windowWidth < 960)
                  ? "med"
                  : "sm"
              }`}
            >
              <label htmlFor="shop" className="c-field__label">
                {"("}
                <span className="u-text-error">*</span>
                {")"}
                <em>
                  {" "}
                  <Text tid="fieldCannotBeBlank" />
                </em>
              </label>
            </div>
            <div
              className={`l-flex-wrap l-container-${
                this.state.windowWidth >= 1328 ? "lg" : "med"
              } u-align-start`}
            >
              {columnLayout}
            </div>

            <div className={`u-margin-top-large l-container-med u-margin-bottom-large ${this.state.windowWidth <= 453 ? "u-margin-auto u-width-50-percent" : ""}`}>
              <div className="l-flex-wrap u-align-center">
                <label><Text tid='shareInfo'/>:</label>
                <div className={"l-inline-flex"}>
                  <input
                    id="shareInfoYes"
                    type="radio"
                    name="shareInfo"
                    value={true}
                    checked={this.state.shareInfo}
                    onChange={()=>this.setState({ shareInfo: !this.state.shareInfo })}
                    className="u-margin-right-large"
                  />
                  <label
                    className="c-field__label left-padding u-padding-bottom-none u-margin-right-large"
                  >
                    <Text tid="yes" />
                  </label>
                  <input
                    id="shareInfoNo"
                    type="radio"
                    name="shareInfo"
                    value={false}
                    checked={!this.state.shareInfo}
                    onChange={()=>this.setState({ shareInfo: !this.state.shareInfo })}
                    className="u-margin-right-large"
                  />
                  <label
                    htmlFor="shareInfoNo"
                    className="c-field__label left-padding u-padding-bottom-none"
                  >
                    <Text tid="no" />
                  </label>
                </div>
              </div>
            </div>

            <div className="l-container-sm">

              <div className="l-container-sm l-flex-wrap u-margin-top-large u-padding-top-large u-margin-bottom-large u-justify-space-around u-align-center">
                <a href="/ISS-AutoAuth-Subscriber-Agreement_6_21_2023.pdf" download>
                  <Text tid="termsConditions" />
                </a>

                <div className="l-flex-wrap u-align-center">
                  <input
                    id="acceptDisclaimer"
                    type="checkbox"
                    checked={!!this.state.disclaimerAccepted}
                    onChange={this.handleAcceptDisclaimer}
                    className="u-margin-right-large"
                  />
                  <label
                    htmlFor="acceptDisclaimer"
                    className="c-field__label left-padding u-padding-bottom-none"
                  >
                    <Text tid="accept" />
                  </label>
                </div>
              </div>

              <div>
                <LoaderButton
                  type="button"
                  id="confirm-submit-button"
                  onClick={this.handleConfirmSubmit}
                  className="c-btn-full"
                  disabled={!this.validateForm()}
                  isLoading={this.state.isLoading}
                  text={<><Text tid="signup" /><div className='left-padding-10'>${this.state.regPrice}.00</div></>}
                  loadingText={<Text tid="signingUp" />}
                />
              </div>
            </div>
            {missingFieldsHint}
          </form>
        </div>
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />
        <Dialog
          isOpen={this.state.showPriceBreakdown}
          onDismiss={() => this.setState({showPriceBreakdown: false})}
          className="c-modal"
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancel.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="priceBreakdown" />
          </h1>
          <div className="c-modal__body">
            <PriceBreakdown
              config={this.state.config}
              renewalTier={'AutoAuth '+ (this.state.selectedShopType?.includes('PLUS') ? 'PLUS' : 'STANDARD')}
              subscriptionType={'AutoAuth'}
              signup={true}
              numberOems={this.state.oemIDsSelected.size}
              crmSignup={this.state.selectedShopType?.includes('CRM')}
              crmFreeTrial={true}
            />
          </div>
          <div className="c-verify-address__footer flex-colum-center margin-bottom-2rem">
            <div />
            <div className="c-verify-address__footer">
              <LoaderButton
                type="button"
                id="submit-button"
                className="c-btn-full"
                onClick={this.handleSubmit}
                isLoading={this.state.isLoading}
                text={<><Text tid="signup" /><div className='left-padding-10'>${this.state.regPrice}.00</div></>}
                loadingText={<Text tid="signingUp" />}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.showVerifyAddressModal}
          onDismiss={this.handleCancel.bind(this)}
          className="c-modal-slider"
          aria-label={TextOnly("verifyAddress")}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancel.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>

          {addressOptions.length > 0 || intAddressOptions.length > 0 ? (
            <>
              <h2 className="c-verify-address__title">
                <Text tid="verify" />
              </h2>
              {this.state.country === "US" ? addressOptions : intAddressOptions}
            </>
          ) : (
            <h2 className="c-verify-address__title">
              <Text tid="unableToFindAddress" />
            </h2>
          )}
        </Dialog>
        <Dialog
          isOpen={this.state.showLearnMoreModal}
          onDismiss={this.handleCancelLearnMoreModal}
          className={
            this.state.windowWidth < 753
              ? "c-modal-slider-90"
              : "c-modal-slider-75"
          }
          aria-label={TextOnly("learnMore")}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelLearnMoreModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="standardPlusFeatures" />
          </h1>

          <div className="c-modal__body">
            {<StatsAndTrendsPromo props={this.props} />}
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.showModal}
          className="c-modal"
          aria-label="oemCompatibility"
        >
          <h1 className="c-modal__heading">
            <Text tid="oemCompatibility" />
          </h1>

          <div className="c-modal__body">
            <CompatibleTools
              showOnlyRegion={this.state.oemregionName[this.state.regionSelected]}
              closeFunction={this.handleCancelModal}
              closeButtonText={TextOnly('confirm')}
              moveCloseButtonToTop={true}
              chartData={this.state.chartData}
            />
          </div>
        </Dialog>
      </>
    );
  }
}
