import React, { useContext } from 'react';
import { LanguageContext } from '../libs/Language';
import { dictionaryList } from '../libs/languages';
import { en } from '../lang/en';

// check if language dictionary has any of the matching keys
type VerifyKindaPartial<T, KP> = Partial<T> & {
  [K in keyof KP]-?: K extends keyof T ? T[K] : never;
};

export type LangDictKey = keyof Partial<typeof en>;

export type LangDictValue = VerifyKindaPartial<typeof en, typeof en>;

export type LangDictType = LangDictKey | LangDictValue;

interface TextProps {
  tid: LangDictKey;
  sub?: Record<string, JSX.Element>;
}

type substitutions = {[x: string]: string};

// get text according to id & current language
export function Text({ tid, sub }: TextProps): any {
  const { dictionary }: any = useContext(LanguageContext);
  if (tid === undefined) {
    return null;
  }

  const phrase: any = dictionary[tid] || dictionaryList['en'][tid] || tid;
  // If substitions are necessary
  if (sub) {
    const content = phrase.split(/[<>]+/);

    const newContent = content.map((substring: string) => {
      return <React.Fragment key={JSON.stringify(substring)}>{sub[substring] || substring}</React.Fragment>;
    });

    return newContent;
  }
  return phrase;
}

export function TextOnly(tid: LangDictKey, sub?: substitutions): string {
  const storedDictionary = window.localStorage.getItem('rcml-lang-dict');
  const dictionary = storedDictionary
    ? JSON.parse(storedDictionary)
    : dictionaryList['en'];
  const phrase: any = dictionary[tid] || dictionaryList['en'][tid] || tid;
  if (sub) {
    const content = phrase.split(/[<>]+/);

    const newContent = content.map(
      (substring: any) => sub[substring] || substring
    );

    const reducer = (accumulator: string, currentValue: string) =>
      accumulator + currentValue;
    const results = newContent.reduce(reducer);
    return results;
  }
  return phrase;
}
