import React from 'react';
import { TextOnly } from '../../components/Text';
import { ORDER_STATUS_OPTIONS } from '../Crm/CrmConstants';
import { NavLink } from 'react-router-dom';

const OrderStatus: React.FC<any> = ({shopOrders}) => {

  const orders = {
    initiated: shopOrders.filter((order: any) => order.status === 'initiated').length,
    droppedOff: shopOrders.filter((order: any) => order.status === 'droppedOff').length,
    inProgress: shopOrders.filter((order: any) => order.status === 'inProgress').length,
    invoiced: shopOrders.filter((order: any) => order.status === 'invoiced').length,
    readyForPickUp: shopOrders.filter((order: any) => order.status === 'readyForPickup').length,
  }

  return (
    <div className="c-box--white u-padding-small u-width-100-percent">
      <div className='c-crm-dashboard-box--order-status'>
        <NavLink
          to="/crm/manageOrders"
        >
          {ORDER_STATUS_OPTIONS.map((status: any, index: number) => {
            if (status.value !== 'paid' && status.value !== 'canceled') {
              const orderCount: number = shopOrders.filter((order: any) => order.status === status.value).length;
              return (
                  <div
                    className={`order-status-group u-text-action-blue-700 display-flex
                      ${status.value !== 'readyForPickup' ? 'crm-split-line' : ''}`
                    }
                    key={index}
                  >
                    <div className='header'>
                      <i className={`fa-solid fa-circle status-color-${index} u-padding-right`}></i>
                      {status.label}
                    </div>
                    <div className="u-text-left">
                      {`${orderCount} ${TextOnly('orderOrders')}`}
                    </div>
                  </div>
                );
              }
            })
          }
          </NavLink>
      </div>
    </div>
  )
}

export default OrderStatus;

