import React, { useState } from 'react';
import { Shop, User } from '../../types';
import { Text, TextOnly } from '../../components/Text';
import { Dialog } from '@reach/dialog';
import { toast } from 'react-toastify';
import LoaderButton from '../../components/LoaderButton';
import AlertModal from '../../components/AlertModal';
import ConfirmModal from '../../components/ConfirmModal';
import { useScreenSize } from '../../context/ScreenSize';
import NextPayment from './NextPayment';
import SubEndingNotify from './SubEndingNotify';
import ToolTip from '../../components/ToolTip';
import CrmPromoContent from '../Crm/Promo/CrmPromoContent';

import {
  shopCRMSignup,
  cancelCRMSubscription,
  reinstateCRMSubscription,
} from '../../libs/db-lib';
import { SHOP_TYPES, PAYMENT_STATES } from '../../CONSTANTS';

interface CRMSubscriptionProps {
  currentShop: Shop;
  config: { [key: string]: any };
  fetchUser: (shopId?: string) => void;
  className?: string;
  user: User;
}

const CRMSubscription = (props: CRMSubscriptionProps) => {

  const {
    currentShop,
    config,
    fetchUser,
    className,
    user,
  } = props;

  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState<Partial<React.Component>>();
  const [showModal, setShowModal] = useState(false);
  const [isLoadingConfirmSignupCRM, setIsLoadingConfirmSignupCRM] = useState(false);
  const [isLoadingCRMSignup, setIsLoadingCRMSignup] = useState(false);
  const [showCRMSignupModal, setShowCRMSignupModal] = useState(false);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const [showCancelingMessage, setShowCancelingMessage] = useState(false);
  const [isLoadingCancelSubscription, setIsLoadingCancelSubscription] = useState(false);
  const [isLoadingReinstateShop, setIsLoadingReinstateShop] = useState(false);

  const { windowWidth, isMobile } = useScreenSize();

  const isPlus = currentShop.shopType === SHOP_TYPES.PLUS;
  const isSmallScreen = windowWidth < 690 || isMobile;

  const crmBasePrice = config?.prices?.crmBasicMonthly;
  const crmBasePriceStr = crmBasePrice?.toFixed(2);
  const crmTotalUpchargePrice =
          (currentShop?.shopMaxUsers -
            config?.shopBaseValues?.plusBaseUsers) * config?.prices?.crmUserUpcharge;
  const crmUserUpchargeStr = config?.prices?.crmUserUpcharge?.toFixed(2);
  const crmTotalPrice = crmBasePrice + crmTotalUpchargePrice;
  const crmMonthlyChargeStr = (crmBasePrice + crmTotalUpchargePrice)?.toFixed(2);

  const paidThruDate = currentShop.shopPaymentInfo
  ? new Date(currentShop.shopPaymentInfo.crmPaidThroughDate).getTime()
  : 0;
  const today = new Date().getTime();
  const expired = paidThruDate - today < 0;

  const handleCRMSignupButton = (e: any) => {
    e.preventDefault();

    const hasLastFour = !!currentShop!.shopPaymentInfo?.last4;

    if (!hasLastFour) {
      setAlertTitle(TextOnly('noPaymentMethodAvailable'));
      setAlertMessage(
        <p>
          <Text tid="noPaymentMethodForCRMSignup" />
        </p>
      );
      setShowModal(true);
    } else if (currentShop!.shopSubscriptionState === 'USER_CANCEL') {
        setAlertTitle(TextOnly('activeShopRequired'));
        setAlertMessage(
          <p>
            <Text tid="activeShopRequiredForCRMSignup" />
          </p>
        );
        setShowModal(true);
    } else {
      setShowCRMSignupModal(true);
      setIsLoadingCRMSignup(true);
    }
  }

  const handleConfirmCRMModal = async (e: any) => {
    e.preventDefault();

    setIsLoadingConfirmSignupCRM(true);

    let ret = await shopCRMSignup(
      currentShop.shopID,
      !currentShop?.crmFreeTrialUsed
    );
    if (ret?.error) {
      toast.dismiss();
      toast.error(ret.error, {
        containerId: 'standard',
      });
      document.querySelector('.c-modal')?.classList.add('closed');
      setTimeout(() => {
        setShowCRMSignupModal(false);
        setIsLoadingCRMSignup(false);
        setIsLoadingConfirmSignupCRM(false);
      }, 350);
    } else {
      await fetchUser();

      document.querySelector('.c-modal')?.classList.add('closed');
      setTimeout(() => {
        setShowCRMSignupModal(false);
        setIsLoadingCRMSignup(false);
        setIsLoadingConfirmSignupCRM(false);
      }, 350);

      toast.success(
        `${TextOnly('shopCRMActive', { price: ret?.chargedToday })}`,
        { containerId: 'standard' }
      );
    }
  }

  const handleCancelModal = () => {
    const modalSlider = document.querySelector(
      windowWidth < 753
        ? '.c-modal-slider-90'
        : '.c-modal-slider-75'
    );
    if (modalSlider) {
      modalSlider.classList.add('closed');
    }
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      setIsLoadingConfirmSignupCRM(false);
      setShowCancelSubscriptionModal(false);
      setShowModal(false);
      setShowCRMSignupModal(false);
      setIsLoadingCRMSignup(false);
      setShowLearnMoreModal(false);
    }, 350);
  }

  const handleCancelSubscriptionButton = () => {
    setShowCancelSubscriptionModal(true);
  };

  const handleReinstatePlusCRM = async () => {
    setIsLoadingReinstateShop(true);

    let result = await reinstateCRMSubscription(currentShop.shopID);

    if (result.error) {
      toast.dismiss();
      toast.error(result.error, {
        containerId: 'standard',
      });
      setIsLoadingReinstateShop(false);

    } else {
      toast.success(TextOnly("crmSubsccriptionReinstated"), {
        containerId: 'standard',
      });

      setIsLoadingReinstateShop(false);

      await fetchUser();
    }
  }

  const handleConfirmCancelSubscriptionModal = async () => {
    if (showCancelingMessage) return;

    setShowCancelingMessage(true);

    let result = await cancelCRMSubscription(currentShop.shopID);

    if (result?.error) {
      toast.error(result.error);
      setShowCancelingMessage(false);
      setShowCancelSubscriptionModal(false);
      setIsLoadingCancelSubscription(false);
    } else {
      toast.success(TextOnly('shopSubscriptionCanceled'), {
        containerId: 'standard',
      });

      await fetchUser();

      setShowCancelSubscriptionModal(false);
      setShowCancelingMessage(false);
      setIsLoadingCancelSubscription(false);
    }
  };

  return (
    <div className={`${className ?? ''} l-flex-column`}>
      {!isSmallScreen &&
        <div className="h2 u-margin-bottom u-padding-left">PLUS CRM</div>
      }
      <div className="c-box u-height-min-800 u-height-100-percent">
        { currentShop.crmState === 'ACTIVE' ?
          <div className="l-container u-max-width-560px">
            <section className="c-section">
              <label className="c-section__label">
                <Text tid="currentPlan" />
              </label>
              <div className="c-section__content">
                <span className="c-section__key u-font-weight-bold">
                  PLUS CRM
                </span>
              </div>
            </section>
            <section className="c-section">
            { currentShop!.crmSubscriptionState === PAYMENT_STATES.USER_CANCEL ?
              <SubEndingNotify
                paidThroughDate={currentShop.shopPaymentInfo?.crmPaidThroughDate ?? ''}
                last4={currentShop?.shopPaymentInfo?.last4}
                expired={expired}
              />
              :
              <NextPayment
                currentShop={currentShop}
                user={user}
                subscriptionType={'CRM'}
                config={config}
              />
            }
            </section>
            <div className="u-margin-top-large">
              <div className="l-container-sm">
                {currentShop.crmSubscriptionState !== PAYMENT_STATES.USER_CANCEL ?
                  <LoaderButton
                    type="button"
                    className="c-btn-outline c-btn-full"
                    isLoading={isLoadingCancelSubscription}
                    text={TextOnly('cancelSubscription')}
                    loadingText={TextOnly('canceling')}
                    onClick={handleCancelSubscriptionButton}
                  />
                : currentShop?.shopPaymentInfo?.last4 ?
                    <ToolTip
                      text={TextOnly('reinstateCRMDisableExplain')}
                    >
                      <LoaderButton
                        id="reinstateShop"
                        type="button"
                        className="c-btn-full"
                        isLoading={isLoadingReinstateShop}
                        text={TextOnly('reinstatePlusCRM')}
                        loadingText={TextOnly('reinstatingShop')}
                        disabled={currentShop?.downgradeToStandardPending}
                        onClick={handleReinstatePlusCRM}
                      />
                    </ToolTip>
                : null }
                </div>
              </div>
          </div>
        :
          <div className="l-container">
            <div className="l-full-width overflow-visible">
              <div className="c-box c-box--promo-plus-crm l-flex-column u-align-start">
                <div className="c-box__title">
                  <Text tid="newAACRMServices" />
                </div>
                <p>
                  <Text tid="crmExplain" />
                </p>
                <button
                  className="c-btn-dark-red"
                  onClick={() => setShowLearnMoreModal(true)}
                >
                  <div className="c-btn__inner">
                    <Text tid="learnMore" />
                  </div>
                </button>
              </div>

              <div className={`c-card u-margin-right-none ${windowWidth > 1350 ? 'u-padding-sides u-padding-top-none u-padding-bottom-none' : 'u-padding-none'}`}>
                <h2 className="c-card__title">
                  <Text tid={isPlus ? "signUpToGetShopAndCustomerManagement" : "upgradeAndSignUpForShopAndCustomerManagement"} />
                </h2>
                <div className="c-card__description">
                  <div className="u-padding-bottom-2rem">
                    {TextOnly('gainAccessToCrucialServices')}:
                  </div>
                  <div className="l-flex-row u-padding-bottom-2rem">
                    <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                    {TextOnly("customerDatabaseExplain")}
                  </div>
                  <div className="l-flex-row u-padding-bottom-2rem">
                    <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                    {TextOnly("createCustomerOrdersExplain")}
                  </div>
                  <div className="l-flex-row u-padding-bottom-2rem">
                    <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                    {TextOnly("calendarOfSchedulesAndAppointmentsExplain")}
                  </div>
                  <div className="l-flex-row">
                    <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                    {TextOnly("automatedTextEmailNotificationsExplain")}
                  </div>
                </div>
              </div>
              <div className="l-flex-wrap u-margin-none">
                <p>
                  <Text tid="freeTrial" sub={{price: crmBasePriceStr}} />
                </p>
                <div className="u-margin-bottom-large">
                  <Text
                    tid="addedUserCRMCharge"
                    sub={{
                      price: crmUserUpchargeStr,
                      baseUsers: config?.shopBaseValues?.plusBaseUsers
                    }}
                  />
                </div>
                {!isPlus && (
                  <div className="u-margin-bottom-large">
                    <Text tid="thisOfferIsOnlyAvailableToPlus"/>
                  </div>
                )}
              </div>
              <div className="parent-center l-container-sm">
                {isPlus ? (
                  <LoaderButton
                    type="button"
                    className="c-btn-dark-red c-btn-full"
                    isLoading={isLoadingCRMSignup}
                    disabled={false}
                    text={`${TextOnly('signUpNow')}${currentShop?.crmFreeTrialUsed ? ` (${crmMonthlyChargeStr})` : ''}`}
                    loadingText={TextOnly('signingUp')}
                    onClick={handleCRMSignupButton}
                  />
                ) : (
                  <a
                    className="c-btn-dark-red c-btn-full"
                    href="/upgradeToPlus/comparePlans"
                  >
                    <Text tid="upgradeToAutoAuthPlusCrm" />
                  </a>
                )}
              </div>
            </div>
          </div>
        }
      </div>

      <Dialog
        isOpen={showCRMSignupModal}
        onDismiss={()=> {handleCancelModal}}
        className="c-modal"
        aria-label={TextOnly('confirmCRMSignup')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancelModal}
          disabled={isLoadingConfirmSignupCRM}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="confirmCRMSignup" />
        </h1>

        <div className="c-modal__body">
          <p>
            <Text tid="areYouSureYouWantToSignupCRM" />
          </p>
          <p>
            { currentShop?.crmFreeTrialUsed ?
              <Text tid="youWillBeChargedToday" sub={{price: <>{crmMonthlyChargeStr}</>}} />
              :
              <Text tid="afterFreeTrialYouWillBeCharged" sub={{price: <>{crmMonthlyChargeStr}</>}} />
            }
          </p>

          <p className={crmTotalPrice - crmBasePrice > 0 ? "u-text-error" : 'hide-element'}>
            <Text tid="note" />
            <Text tid={"crmUpchargeExplain"} sub={{base: crmBasePriceStr, upcharge: crmUserUpchargeStr}} />
          </p>
          <button
            className="c-btn-outline"
            onClick={handleCancelModal}
            disabled={isLoadingConfirmSignupCRM}
          >
            <Text tid="cancel" />
          </button>{' '}
          <LoaderButton
            type="button"
            isLoading={isLoadingConfirmSignupCRM}
            text={TextOnly('confirm')}
            loadingText={TextOnly('savingChanges')}
            onClick={handleConfirmCRMModal}
          />
        </div>
      </Dialog>

      <AlertModal
        title={alertTitle}
        message={<>{alertMessage}</>}
        showModal={showModal}
        size="small"
        handleCancel={handleCancelModal}
      />

      <ConfirmModal
        showConfirmModal={showCancelSubscriptionModal}
        closeModal={handleCancelModal}
        onConfirm={handleConfirmCancelSubscriptionModal}
        textModal={currentShop?.shopPaymentInfo?.crmPaidThroughDate != null ?
                      TextOnly('confirmCancelCRMSubscriptionMessage') :
                      TextOnly('confirmCancelFreeTrialCRMSubMessage')
        }
        className="c-modal-slider"
        titleModal={TextOnly('confirmCancelCRMSubscription')}
        textCancelButton={TextOnly('dismiss')}
        textLoadingConfirmButton={TextOnly('canceling')}
      />

      <Dialog
        isOpen={showLearnMoreModal}
        onDismiss={handleCancelModal}
        className={
          windowWidth < 753
            ? 'c-modal-slider-90'
            : 'c-modal-slider-75'
        }
        aria-label={TextOnly('learnMore')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="crmFeatures" />
        </h1>

        <div className="c-modal__body">
          <CrmPromoContent promoType='calendar' />
          <CrmPromoContent promoType='manageOrders' />
          <CrmPromoContent promoType='manageCustomers' />
          <CrmPromoContent promoType='manageVehicles' />
        </div>
      </Dialog>

    </div>
  );
}

export default CRMSubscription;